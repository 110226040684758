export default {
    BaseCrudTable: {
        itemsPerPageText: 'Linhas por página',
        details: '+ Detalhes',
    },
    LevelsTable: {
        loading: 'Buscando níveis...',
        no_data: 'Sem níveis para mostrar',

        delete_success: 'Nível deletado',
        delete_error: 'Não foi possível deletar o nível',
        delete_first_level: 'Não é permitido deletar o primeiro nível',
        network_error: '@:globals.network_error',

        title: 'Níveis registrados',
        confirm_delete: 'Deseja realmente deletar o nível?',

        color: 'Cor',
        name: 'Nome',
        trigger_amount: 'Quantidade de pontos',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes do nível',
            edit: 'Editar nível',
            delete: 'Deletar nível'
        }
    },
    BenefitsTable: {
        loading: 'Buscando benefícios...',
        no_data: 'Sem benefícios para mostrar',

        delete_success: 'Benefício deletado',
        delete_error: 'Não foi possível deletar o benefício',
        network_error: '@:globals.network_error',

        title: 'Benefícios registrados',
        confirm_delete: 'Deseja realmente deletar o benefício?',

        icon: 'Ícone',
        color: 'Cor',
        name: 'Nome',
        description: 'Descrição',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes do benefício',
            edit: 'Editar benefício',
            delete: 'Deletar benefício',
        },
    },
    PrizesTable: {
        no_data: 'Sem produtos para mostrar',
    },
    CustomersTable: {
        loading: 'Buscando clientes...',
        no_data: 'Sem clientes para mostrar',

        title: 'Clientes registrados',
        confirm_delete: 'Deseja realmente deletar o cliente?',

        delete_success: 'Cliente deletado',
        delete_error: 'Não foi possível deletar o cliente',
        network_error: '@:globals.network_error',

        name: 'Nome',
        email: 'Email',
        phone: 'Celular',
        actions: 'Ações',

        tooltips: {
            history: 'Histórico do cliente',
            details: 'Detalhes do cliente',
            edit: 'Editar cliente',
            delete: 'Deletar cliente'
        },

        search: {
            exact_document: {
                label: 'Documento (somente números)',
                hint: 'Busca exata por documento',
                placeholder: 'Documento do cliente',
            },
        },
    },
    SubsidiariesTable: {
        title: 'Filiais registradas',

        loading: 'Buscando filiais...',
        no_data: 'Sem filiais para mostrar',
        confirm_delete: 'Deseja realmente deletar a filial?',

        name: 'Nome',
        document: 'CNPJ',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes da filial',
            edit: 'Editar filial',
            delete: 'Deletar filial',
        },
    },
    TypesTable: {
        loading: 'Buscando regras...',
        no_data: 'Sem regras para mostrar',

        title: 'Regras registradas',
        confirm_delete: 'Deseja realmente deletar a regra?',

        delete_success: 'Regra deletada',
        delete_error: 'Não foi possível deletar a regra',
        network_error: '@:globals.network_error',

        id: 'Id',
        key: 'Chave',
        name: 'Nome',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes da regra',
            edit: 'Editar regra',
            delete: 'Deletar regra',
        },
    },
    ImportEntryTable: {
        loading: 'Buscando importações...',
        no_data: 'Sem importações para mostrar',

        title: 'Histórico',

        created_at: { text: 'Iniciada em' },
        type      : { text: 'Tipo' },
        status    : { text: 'Status' },
        progress  : { text: 'Progresso' },

        item: {
            file_url_label: 'Arquivo original',
            created_by: 'por {created_by}',
        },

        tooltips: {
            refresh: 'Atualizar',
        },
    },
    actions: 'Ações',

    PrizeCategoryTable: {
        loading: 'Buscando categorias...',
        no_data: 'Sem categorias para mostrar',

        title: 'Categorias de prêmio registradas',
        confirm_delete: 'Deletar categoria?',

        delete_success: 'Categoria deletada',
        delete_error: 'Não foi possível deletar a categoria',
        network_error: '@:globals.network_error',

        name: 'Nome',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes da categoria',
            edit: 'Editar categoria',
            delete: 'Deletar categoria'
        }
    },

    PromotionsTable: {
        loading: 'Buscando promoções...',
        no_data: 'Sem promoções para mostrar',

        title: 'Promoções',
        confirm_delete: 'Deseja realmente deletar a promoção?',

        delete_success: 'Promoção deletada',
        delete_error: 'Não foi possível deletar a promoção',
        network_error: '@:globals.network_error',

        id: 'Id',
        name: 'Nome',
        start_at: 'Início',
        end_at: 'Fim',
        is_active: 'Status',
        actions: 'Ações',

        tooltips: {
            details: 'Detalhes da promoção',
            edit: 'Editar promoção',
            delete: 'Deletar promoção',
        },
    },
    LogEntryTable: {
        title: 'Logs',
        created_at: 'Data',
        message: 'Mensagem',
        severity: 'Severidade',
        request: 'Requisição',
        response: 'Resposta',
        no_data: 'Sem registros de log.',
        loading: 'Carregando registros de log...',
    },
}