/**
 * Arrendonda um numero
 *
 * Não usar .toFixed() pois em alguns casos gera bug Ex: (0.5 * 16.45).toFixed(2) = 8.22
 *                                                       (1.015).toFixed(2)       = 1.01
 *                                                       (0.15*34.9).toFixed(2)   = 5.23
 *
 * @param {(string|number)} value  Valor
 * @param {integer} decimals Quantidade de casas decimais
 *
 * @return {string}
 */
function roundNumber(value, decimals = 2) {
    var sign = value >= 0 ? 1 : -1

    return (Math.round((value*Math.pow(10, decimals)) + (sign*0.001)) / Math.pow(10, decimals)).toFixed(decimals)
}

module.exports = {
    roundNumber
}