import BaseModel   from './BaseModel'

export default class VoucherCode extends BaseModel {

    defaults() {
        return {
            code: null,
        }
    }

    toString() {
        return this.code || ''
    }

}