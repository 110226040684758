<template>
    <v-banner align="center">
        <v-row no-gutters>
            <v-col cols="12" lg="10" md="10" sm="12" class="py-3 d-flex align-center justify-center">
                <div>
                    <span class="font-weight-bold">{{ title }}</span>
                    <span>{{ description }}</span>
                    <slot name="additionalContent"></slot>
                </div>
            </v-col>

            <slot name="actions">
                <v-col cols="12" lg="2" md="2" sm="12" class="d-flex align-center justify-center" v-if="buttonConfirm">
                    <v-btn class="primary elevation-0 ml-1" @click="confirm()" :loading="loading">
                        {{ buttonConfirm }}
                    </v-btn>
                </v-col>
            </slot>
         </v-row>
    </v-banner>
</template>

<script>
export default {
    name: 'ImportantNotifications',
    props: {
        title        : String,
        description  : String,
        buttonConfirm: String,
        loading      : Boolean,
    },
    methods: {
        confirm() {
            this.$emit('confirm')
        },
    },
}
</script>

-<style>
.v-banner__text {
    width: 100%
  }
</style>
