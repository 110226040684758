var cors = {}

if (!['production', 'staging', 'staging-sa'].includes(process.env.NODE_ENV)) {
    cors = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type, X-Token-Auth, Authorization',
    }
}

export default {
    baseURL: process.env.VUE_APP_BACKEND_API_URL,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',

        ...cors,
    },
    timeout: process.env.VUE_APP_API_TIMEOUT,
}