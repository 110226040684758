import axiosInstance  from '@/services/axios/index'
import parseJWT       from '@/utils/parseJWT'
import router         from '@/router'
import Cookie         from 'js-cookie'
import $console       from '@/services/console'
import AdminUser      from '@/models/AdminUser'
import _              from 'lodash'

const DAYS_TO_EXPIRE = _.toNumber(process.env.VUE_APP_TOKEN_DAYS_TO_EXPIRE || 60)

const state = {
    token: Cookie.get('admin_token'),
    user : null,
}

const getters = {
    token     : state => state.token,
    check     : state => !!state.token,
    user      : state => state.user,
    jwtContent: state => parseJWT(state.token),
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
        Cookie.set('admin_token', token, { expires: DAYS_TO_EXPIRE, secure: process.env.NODE_ENV == 'production' })
    },

    UNSET_TOKEN: (state) => {
        state.token = null
        Cookie.remove('admin_token')
    },

    SET_USER: (state, value) => {
        if (value && !(value instanceof AdminUser))
            throw new TypeError('Esperava que user fosse do tipo AdminUser')

        state.user = value
    },
}

const actions = {
    async login({ commit }, data) {
        let res = await axiosInstance.post('/admin/login', data)
        // Catch vai cair pra cima

        if (!res || !res.data || _.isEmpty(res.data.admin) || _.isEmpty(res.data.token))
            throw new Error('Resposta incorreta no login. Falta user & token.')

        let adminUser = new AdminUser(res.data.admin)

        commit('SET_USER', adminUser)
        commit('SET_TOKEN', res.data.token)

        // Verifica se é o primeiro login desse admin
        if (adminUser.is_default_password) {
            router.push({ name: 'welcome' })
        } else {
            router.push({ name: 'home' })
        }

        return true
    },

    async logout({ commit }, params) {
        params = params || {}

        // Remove o token que estava guardado no navegador
        commit('UNSET_TOKEN')
        commit('SET_USER', null)
        commit('company/SET_SELECTED_COMPANY', null, { root: true })

        // Redireciona para o login, informando que realizou o logout
        router.push({ name: 'login', params: { ...{ logout: true }, ...params } })

        // Expira o token no backend
        // await axiosInstance.post('/admin/logout')

        return true
    },

    async fetchCurrentAdminUser({ state, commit }) {
        $console.log('fetchCurrentAdminUser()')

        let jwtContent = parseJWT(state.token)

        if (jwtContent.uid_type != 'admin')
            throw new Error('Invalid Token Content')

        // Pega o usuário da API
        let adminUser = await AdminUser.get(jwtContent.uid)

        if (!adminUser) return

        commit('SET_USER', adminUser)
    },
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}