export default [
    {
        path: '/clientes',
        name: 'customers',
        redirect: 'clientes/lista',
    },
    {
        path: '/clientes/lista',
        name: 'customers.list',
        component: () => import(/* webpackChunkName: "customers.list" */ '@/views/customers/List.vue'),
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'customers.list',
            can: 'list Customer',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Clientes',
                    disabled: true
                },
            ]
        },
    },{
        path: '/clientes/detalhes/:id?',
        name: 'customers.details',
        component: () => import(/* webpackChunkName: "customers.details" */ '@/views/customers/Details.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'customers.details',
            can: 'view Customer',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Clientes',
                    to: { name: 'customers.list' }
                },
                {
                    text: 'Detalhes',
                    disabled: true
                },
            ]
        },
    },{
        path: '/clientes/registro/:id',
        name: 'customers.register',
        component: () => import(/* webpackChunkName: "customers.register" */ '@/views/customers/Register.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'customers.register',
            can: 'list Customer',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Clientes',
                    to: { name: 'customers.list' }
                },
                {
                    text: 'Histórico',
                    to: { name: 'history' }
                },
                {
                    text: 'Registro',
                    disabled: true
                },
            ]
        },
    },
]