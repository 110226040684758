var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { "max-width": "330px", persistent: "" },
      on: { confirm: _vm.confirm, cancel: _vm.cancel },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "my-4 d-flex justify-center" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2 background elevation-0",
                      attrs: { width: "86px" },
                      on: { click: _vm.handleCancel }
                    },
                    [_vm._v(_vm._s(_vm.$t("modals.ConfirmationModal.cancel")))]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-2 primary elevation-0",
                      attrs: { width: "86px" },
                      on: { click: _vm.handleConfirm }
                    },
                    [_vm._v(_vm._s(_vm.$t("modals.ConfirmationModal.confirm")))]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column align-center" },
        [
          _c(
            "v-icon",
            { staticClass: "secondary_font--text my-5", attrs: { large: "" } },
            [_vm._v("fas fa-fw fa-exclamation-circle")]
          ),
          _vm.useHtml
            ? _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
            : _c("div", { staticClass: "d-flex flex-column align-center" }, [
                _c(
                  "span",
                  { staticClass: "text-center primary--text main-text" },
                  [_vm._v(_vm._s(_vm.text))]
                ),
                _c("span", { staticClass: "mt-1" }, [
                  _vm._v(_vm._s(_vm.$t("modals.ConfirmationModal.subtitle")))
                ])
              ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }