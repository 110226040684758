import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            primary_color: 'company/primary_color',
        }),
        isLightTheme() {
            const luminosity = this.rateColor()

            if(luminosity > 190)
                return true
        }
    },
    methods: {
        rateColor() {
            if(!this.primary_color) return

            const hexColor = this.primary_color.replace('#', '')

            const [r,g,b]  = hexColor
                .match(/\w\w/g) 
                .map(x => {
                    if(x) return parseInt(x, 16)
                })

            if(typeof(r) !== 'number' || typeof(g) !== 'number' || typeof(b) !== 'number') return
            /* 
            Mede o quanto uma cor é próxima do branco. O intervalo varia de 1 ate 255,
            quanto mais próximo ao 255 mais clara é a cor.
            */ 

            const luminosity = (r * 299 + g * 587 + b * 114) / 1000

            return luminosity
        },
    }
}