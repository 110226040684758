import axios from '@/services/axios'
import CustomerHistoryRecord from '@/models/CustomerHistoryRecord'
import i18n from '@/i18n'
import $store from '@/store'
import $constants from '@/services/constants'
import CurrencyFilter from '@/utils/filters/currency'
import { required, string, numeric, array, isnil, cpf, cnpj, gte } from '@/utils/validation'

export default class Generator extends CustomerHistoryRecord {

    defaults() {
        return {
            ...super.defaults(),
            customer_document: '',
            spent_value      : '',
            external_id      : '',
            reversal_id      : undefined,
            source_key       : '',
            products         : [],
            points           : [],
            company          : null,
            origin           : undefined,

            // Misc
            useCNPJ: false,
        }
    }

    validation() {
        return {
            id               : isnil.or(string),
            customer_id      : isnil.or(string),
            customer_document: required.and(string).and(this.useCNPJ ? cnpj : cpf),
            amount           : required.and(numeric).and(gte(0)),
            value            : required.and(numeric).and(gte(0)),
            external_id      : isnil.or(string),
            source_key       : isnil.or(string),
            products         : required.and(array),
        }
    }

    _ignoredDataFields() {
        return ['useCNPJ']
    }

    // Utils

    isReversed() {
        return !!this.reversal_id
    }

    // Getters

    /**
     * Retorna a string identificadora de um ícone que representa a geração de acordo com sua origem
     *
     * @returns {string}
     */
    get icon() {
        if (this.origin == $constants.getConstant('PROMOTION_GENERATOR_ORIGIN'))
            return '$cp_award'
        
        return '$cp_credit_card'
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get title() {
        if (this.points_amount == 0)
            return i18n.t('models.Generator.title_zero')

        let origin = this.origin || 'default'
        return i18n.t(`models.Generator.title.${origin}_origin`, {
            points_amount  : this.points_amount,
            value          : CurrencyFilter(this.spent_value),
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get subtitle() {
        return this.reversal_id ? i18n.t('models.Generator.subtitle.reversal', {
            points_amount  : this.points_amount,
            value          : CurrencyFilter(this.spent_value),
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })             : ''
    }

    /**
     * Retorna a ação realizada pelo modelo
     *
     * @returns {string}
     */
    get action() {
        return i18n.t('models.Generator.action')
    }

    // get totalAmount() {
    //     return this.points.reduce((ac, cv) => { ac + cv.amount })
    // }

    get subsidiary_name() {
        return this.company ? this.company.name : ''
    }

    get subsidiary_document() {
        return this.company ? this.company.document : ''
    }

    get formated_subsidiary_document() {
        return this.company && this.company.document ? this.company.document.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : ''
    }

    get points_value() {
        return this.value
    }

    get points_amount() {
        return this.amount
    }

    // Métodos da API

    async generate() {
        let data = this.getData([
            'value', 'source_key', 'external_id', 'products', 'customer_document', 'customer_id',
        ])

        // adiciona informações da company
        data.company_id = $store.getters['company/selected_company_id']
        data.parent_id  = $store.getters['company/id']

        return await axios({
            url: '/points/generate',
            method: 'post',
            data,
        })
    }
}