// import axios from '@/services/axios'
import axiosInstance   from '@/services/axios/index'
import dataOrDefault   from '../utils/dataOrDefault'
import CompanyJson     from '@/models/CompanyJson'
import CompanySettings from '@/models/CompanySettings'
import Subsidiary      from '@/models/Subsidiary'
import $persistence    from '@/services/persistence'
import _               from 'lodash'
// import UpdateTitleMiddleware from '@/router/middlewares/UpdateTitle'
// import router from '@/router'

const cookieSelectedCompany = $persistence.getItem('admin_selected_company')
const parsedSelectedCompany = cookieSelectedCompany ? JSON.parse(cookieSelectedCompany) : null

// Initial state
const state = {
    isLoaded           : false,

    // Informações da empresa
    id                          : '',
    name                        : '',
    document                    : '',
    levels_interval             : '',
    levels_lookback_period      : '',
    json                        : {},
    integrator_id               : '',
    subsidiaries                : [],
    blocked_at                  : '',
    program_name                : '',
    font_color                  : '#FFFAFF',
    primary_color               : '#3F51B5',
    background_color            : '#f9f9f9',
    background_url              : '',
    logo_url                    : '',
    banner                      : {},
    hero                        : {},
    display_mode                : 'POINTS',
    hide_cashback               : false,
    disable_cashback            : false,
    hide_points                 : false,
    disable_action_notifications: false,

    landing_page_domain: '',
    frontend_domain    : '',

    selected_company: parsedSelectedCompany ? new Subsidiary(parsedSelectedCompany): {},
    settings        : null,

    // ... não precisa das outras informações por enquanto

}

// Getters
const getters = {
    state                     : state => state,
    isLoaded                  : state => state.isLoaded,
    id                        : state => state.id,
    json                      : state => state.json || {},
    getId                     : state => state.id,
    name                      : state => state.name,
    document                  : state => state.document,
    integrator_id             : state => state.integrator_id,
    subsidiaries              : state => state.subsidiaries,
    font_color                : state => state.font_color,
    primary_color             : state => state.primary_color,
    logo_url                  : state => state.logo_url,
    blocked_at                : state => state.blocked_at,
    program_name              : state => state.program_name,
    background_color          : state => state.background_color,
    background_url            : state => state.background_url,
    display_mode              : state => state.display_mode || 'POINTS',
    hide_cashback             : state => _.get(state, 'hide_cashback', false),
    disable_cashback          : state => _.get(state, 'disable_cashback', false),
    hide_points               : state => _.get(state, 'hide_points', false),
    disable_action_notifications     : state => _.get(state, 'disable_action_notifications', false),
    selected_company          : state => state.selected_company,
    is_parent_company_selected: state => state.selected_company ? state.selected_company.id == state.id : false,
    selected_company_id       : state => state.selected_company ? state.selected_company.id : state.id,
    landing_page_domain       : state => state.landing_page_domain,
    frontend_domain           : state => state.frontend_domain,
    settings                  : state => state.settings,
}

// Mutations
const mutations = {
    SET_COMPANY_DATA(state, data = {}) {
        dataOrDefault(state, data)

        if (state.favicon_url)
            document.querySelector('#favicon-tag').href = state.favicon_url
    },
    SET_IS_LOADED(state, value = false) {
        state.isLoaded = value
    },
    SET_SELECTED_COMPANY(state, value) {
        state.selected_company = value

        if (value)
            $persistence.setItem('admin_selected_company', JSON.stringify(value), 30)
        else
            $persistence.removeItem('admin_selected_company')
    },
    SET_SETTINGS(state, value) {
        if (!(value instanceof CompanySettings))
            throw new TypeError('Expected an instance of CompanySettings')

        state.settings = value
    },
}

// Actions
const actions = {
    async fetchData({ commit }) {
        let domain = window.location.hostname

        let response = await axiosInstance({
            url: `company/get-company-by-domain/${domain}`,
            method: 'get',
        })

        if (!response || !response.data || response.status != 200) 
            return

        response = await axiosInstance({
            url: `company/${response.data[0].company_id}`,
            method: 'get',
        })
        
        if (!response || !response.data || response.status != 200) 
            return

        let company = {
            ...response.data,
            json: (new CompanyJson).completeJson(response.data.json)
        }

        commit('SET_COMPANY_DATA', { ...company.json, ...company })
        commit('SET_IS_LOADED', true)

        // Atualiza o título da página
        // let updateTitleMiddleware = new UpdateTitleMiddleware
        // updateTitleMiddleware.handle(router.currentRoute, null, null)
    },

    setSelectedCompany({ commit }, data) {
        commit('SET_SELECTED_COMPANY', data)
    },

    async fetchSettings({ commit }) {
        let res = await CompanySettings.get()

        if (!res)
            return

        commit('SET_SETTINGS', res)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
 