import { required, string, cnpj, length } from '@/utils/validation'
import BaseModel from '@/models/BaseModel'
import store from '@/store'

export default class Subsidiary extends BaseModel {

    defaults() {
        return {
            id           : null,
            name         : null,
            document     : null,
            parent_id    : null,
            integrator_id: null,
        }
    }

    validation() {
        return {
            name    : required.and(string).and(length(1, 191)),
            document: required.and(string).and(cnpj),
        }
    }

    async create() {
        this.integrator_id = store.getters['company/integrator_id']
        const parent_id = store.getters['company/id']

        let data = this.getData([
            'name', 'document', 'parent_id'
        ])

        this.constructor.clearData(data, false)
        
        let response = await this.request({
            url: `/company/${parent_id}/subsidiary`,
            method: 'post',
            data,
        })

        if (!response)
            return

        // Atualiza a matriz na store
        await store.dispatch('company/fetchData')

        return response
    }

    async update(fields = []) {
        let attributes = ['name', 'document']
        const parent_id = store.getters['company/id']

        if (fields.length)
            attributes = attributes.filter(field => fields.includes(field))

        let data = this.getData(attributes)

        this.constructor.clearData(data)

        let response = await this.request({
            url: `/company/${parent_id}/subsidiary/${this.id}`,
            method: 'put',
            data
        })

        if (!response)
            return

        // Atualiza a matriz na store
        await store.dispatch('company/fetchData')

        return response
    }

    async delete() {
        let data = this.getData()
        const parent_id = store.getters['company/id']

        let response = await this.request({
            url: `/company/${parent_id}/subsidiary/${this.id}`,
            method: 'delete'
        })

        if (!response)
            return

        // Atualiza a matriz na store
        await store.dispatch('company/fetchData')

        return response
    }

    // Métodos úteis

    // Retira formatação antes de enviar para o servidor
    formatToBack() {
        // [TODO-L][FID-114] internacionalizar
        if (this.document)
            this.document = this.document.replace(/\D/g, '')
    }

    // Adiciona formatação necessária para exibição
    formatToFront() {
        // [TODO-L][FID-114] internacionalizar
        if (this.document)
           this.document = this.document.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') // [TODO] Internacionalizar
    }
}