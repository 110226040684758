import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/scss/overrides.scss'

// Locales
import pt from 'vuetify/lib/locale/pt'

// Importação de ícones
import GiftIcon       from '@/shared/components/icons/GiftIcon'
import AwardIcon      from '@/shared/components/icons/AwardIcon'
import CreditCardIcon from '@/shared/components/icons/CreditCardIcon'

import HomeIcon        from '@/components/icons/HomeIcon.vue'
import DatabaseIcon    from '@/components/icons/DatabaseIcon.vue'
import ListCheckIcon   from '@/components/icons/ListCheckIcon.vue'
import EditIcon        from '@/components/icons/EditIcon.vue'
import BuildingsIcon   from '@/components/icons/BuildingsIcon.vue'
import UsersIcon       from '@/components/icons/UsersIcon.vue'
import InvoiceIcon     from '@/components/icons/InvoiceIcon.vue'
import MedalIcon       from '@/components/icons/MedalIcon'
import SettingsIcon    from '@/components/icons/SettingsIcon'
import ShoppingBagIcon from '@/components/icons/ShoppingBagIcon'
import CalculatorIcon  from '@/components/icons/CalculatorIcon'
import PackageIcon     from '@/components/icons/PackageIcon'
import UserIcon        from '@/components/icons/UserIcon'
import ListIcon        from '@/components/icons/ListIcon'
import LogoutIcon      from '@/components/icons/LogoutIcon'
import BarsIcon        from '@/components/icons/BarsIcon'
import LoginIcon       from '@/components/icons/LoginIcon'
import InfoIcon        from '@/components/icons/InfoIcon'
import BellIcon        from '@/components/icons/BellIcon'
import TrashIcon       from '@/components/icons/TrashIcon'
import UploadCloudIcon from '@/components/icons/UploadCloudIcon'
import UploadIcon      from '@/components/icons/UploadIcon'
import DownloadIcon    from '@/components/icons/DownloadIcon'
import RefreshIcon     from '@/components/icons/RefreshIcon'
import UserMinusIcon   from '@/components/icons/UserMinusIcon'
import FileIcon        from '@/components/icons/FileIcon'
import CheckIcon       from '@/components/icons/CheckIcon'
import BanIcon         from '@/components/icons/BanIcon'
import CheckCircleIcon from '@/components/icons/CheckCircleIcon'
import PlusSquareIcon  from '@/components/icons/PlusSquareIcon'
import FilterIcon      from '@/components/icons/FilterIcon'

Vue.use(Vuetify)

export default new Vuetify({
    // https://feathericons.com/
    icons: {
        iconfont: 'fa',
        values: {
            next: 'fas fa-caret-right',
            prev: 'fas fa-caret-left',
            clear: 'fas fa-times',
            cp_home: {
                component: HomeIcon,
            },
            cp_database: {
                component: DatabaseIcon,
            },
            cp_list_check: {
                component: ListCheckIcon,
            },
            cp_edit: {
                component: EditIcon,
            },
            cp_buildings: {
                component: BuildingsIcon,
            },
            cp_users: {
                component: UsersIcon,
            },
            cp_invoice: {
                component: InvoiceIcon,
            },
            cp_medal: {
                component: MedalIcon,
            },
            cp_settings: {
                component: SettingsIcon,
            },
            cp_shopping_bag: {
                component: ShoppingBagIcon,
            },
            cp_calculator: {
                component: CalculatorIcon,
            },
            cp_package: {
                component: PackageIcon,
            },
            cp_user: {
                component: UserIcon,
            },
            cp_list: {
                component: ListIcon,
            },
            cp_logout: {
                component: LogoutIcon,
            },
            cp_bars: {
                component: BarsIcon,
            },
            cp_login: {
                component: LoginIcon,
            },
            cp_info: {
                component: InfoIcon,
            },
            cp_bell: {
                component: BellIcon,
            },
            cp_trash: {
                component: TrashIcon,
            },
            cp_upload_cloud: {
                component: UploadCloudIcon,
            },
            cp_upload: {
                component: UploadIcon,
            },
            cp_download: {
                component: DownloadIcon,
            },
            cp_refresh: {
                component: RefreshIcon,
            },
            cp_user_minus: {
                component: UserMinusIcon,
            },
            cp_file: {
                component: FileIcon,
            },
            cp_check: {
                component: CheckIcon,
            },
            cp_ban: {
                component: BanIcon,
            },
            cp_check_circle: {
                component: CheckCircleIcon,
            },
            cp_plus_square: {
                component: PlusSquareIcon,
            },
            cp_gift: {
                component: GiftIcon,
            },
            cp_award: {
                component: AwardIcon,
            },
            cp_credit_card: {
                component: CreditCardIcon,
            },
            cp_filter: {
                component: FilterIcon,
            },
            // Customizados para contexto [TODO] alterar para feathericons
            cp_promotion: 'fas fa-fw fa-percentage',
            cp_remove   : 'fas fa-fw fa-trash',
            cp_logs     : 'far fa-fw fa-file-alt',

            cp_log     : 'fas fa-fw fa-bars',
            cp_error   : 'fas fa-fw fa-alert-triangle',
        },
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // customer choices
                primary   : '#1976D2',
                secondary : '#424242',
                font      : '#FFFAFF',
 
                // system

                // general
                navbar       : '#FBFBFD',
                sections     : '#FFFFFF',
                background   : '#F6F7FB',
                accent       : '#82B1FF',
                error        : '#FF5252',
                info         : '#2196F3',
                success      : '#4CAF50',
                sucesss_green: '#90c436',
                warning      : '#FF5722',
                remove       : '#FF5252',

                // fonts
                primary_font  : '#3B4050',
                secondary_font: '#9A9CB8',

                // tables
                table_divider_color: '#F9F9FB',
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),

        // Na vdd isso não é muito necessário, pois o controle de locale está sendo
        // feito pelo vue-i18n, serviço $i18n
        // Porém o tiptap-vuetify utiliza o lang.current para suas traduções e, portanto
        // se faz necessário setar isso aqui
        locales: { ptbr: pt },
        current: 'ptbr',
    },
})
