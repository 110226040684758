/**
 * Esta classe generaliza as políticas de acesso aos modelos
 *
 * https://github.com/mblarsen/browser-acl#policy
 */
export default class BasePolicy {
    /**
     * Define se um usuário pode criar novos modelos
     *
     * @param {User}      user  Usuário
     * @return {boolean}
     */
    create(user) { return true }

    /**
     * Define se um usuário pode atualizar um modelo
     *
     * @param {User}      user  Usuário
     * @param {BaseModel} model Modelo
     * @return {boolean}
     */
    edit(user, model) { return true }

    /**
     * Define se um usuário pode ver um modelo
     *
     * @param {User}      user  Usuário
     * @param {BaseModel} model Modelo
     * @return {boolean}
     */
    view(user, model)   { return true }

    /**
     * Define se um usuário pode deletar um modelo
     *
     * @param {User}      user  Usuário
     * @param {BaseModel} model Modelo
     * @return {boolean}
     */
    delete(user, model) { return true }
}