export default [
    {
        path: '/premios',
        name: 'prizes',
        redirect: 'premios/lista'
    },
    {
        path: '/premios/lista',
        name: 'prizes.list',
        component: () => import(/* webpackChunkName: "prizes.list" */ '@/views/prizes/List.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'prizes.list',
            can: 'list Prize',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard', // [TODO] Internacionalizar
                    to: { name: 'home' }
                },
                {
                    text: 'Prêmios',
                    disabled: true
                }
            ]
        },
    },
]