<template>
    <BaseInput :label="attrs.label" :disabled="attrs.disabled">
        <template #input>
            <v-text-field
                ref="input"
                solo flat
                :value="computedValue"
                @input="onInput"
                v-on="listeners"
                v-bind="attrs"
                @wheel.prevent="onWheel"
                background-color="input_background_color"
            >

                <template #append v-if="hasSlot('append')">
                    <slot name="append"></slot>
                </template>

                <template #prepend v-if="hasSlot('prepend')">
                    <slot name="prepend"></slot>
                </template>

                <template #label v-if="hasSlot('label')">
                    <slot name="label"></slot>
                </template>

                <template #message v-if="hasSlot('message')">
                    <slot name="message"></slot>
                </template>

                <slot></slot>

            </v-text-field>
        </template>
    </BaseInput>
</template>

<script>
import HasSlotMixin from '../../mixins/HasSlot'
import BaseInput from './BaseInput'

export default {
    name: 'SharedTextInput',
    inheritAttrs: false,
    components: { BaseInput },
    mixins: [ HasSlotMixin ],
    props: {
        value: [ String, Number ],
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            if (this.attrs && this.attrs.type == 'number')
                value = value !== '' &&  !this.$lodash.isNil(value) ? this.$lodash.toNumber(value) : null

            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            this.$emit('input', value || value === 0 ? value : null)
        },
        onWheel(value) {
            // Substitui o evento wheel do input type number para evitar scroll de números
            this.$emit('wheel', value)
        },

        focus() {
            this.$refs.input && this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, wheel, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>