import SharedPrize from '@/shared/models/Prize'
import PrizeCategory from '@/models/PrizeCategory'
import { string, isnil, length } from '../shared/utils/validation'
import _ from 'lodash'

export default class Prize extends SharedPrize {

    defaults() {
        return {
            ...super.defaults(),
            external_id    : null,
            integrator_data: null,
        }
    }

    relationships() {
        return {
            category: { class: PrizeCategory, defaultValue: null },
        }
    }

    validation() {
        return {
            ...super.validation(),
            external_id    : isnil.or(string.and(length(1, 191))),
            integrator_data: isnil.or(string.and(length(1, 2000))),
        }
    }

    async create() {
        let data = this.getData([
            'name', 'description', 'show_in_store',
            'expires_in', 'value', 'external_id'
        ])

        if (this.category)
            data.category_id = this.category.id

        let response = await this.request({
            url   : `/prize`,
            method: 'post',
            data,
        })

        if (!response)
            return

        if (response.data)
            this._fill(response.data)

        return response
    }

    async update() {
        let data = this.getData([
            'name', 'description', 'show_in_store',
            'expires_in', 'value', 'external_id',
        ])
        
        if (this.category)
            data.category_id = this.category.id
    
        if (this.category === null)
            data.category_id = null

        let response = await this.request({
            url   : `/prize/${this.id}`,
            method: 'put',
            data,
        })

        if (!response)
            return

        if (response.data)
            this._fill(response.data)

        return response
    }

    async delete() {
        return await this.request({
            url   : `/prize/${this.id}`,
            method: 'delete',
        })
    }

}