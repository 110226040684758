export default [
    {
        path: '/entrar',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Base.vue'),
        props: true,
        meta: {
            guestOnly: true
        },
        children: [
            {
                name: 'login',
                path: '',
                component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
                props: true,
                meta: {
                    guestOnly: true,
                    noNavbar: true,
                },
            },
            {
                name: 'auth.reset_password',
                path: '/redefinir-senha',
                component: () => import(/* webpackChunkName: "auth.reset_password" */ '@/views/auth/ResetPassword.vue'),
                props: true,
                meta: {
                    guestOnly: true,
                    noNavbar: true,
                },
            },
            {
                name: 'auth.forgot_password',
                path: '/esqueci-a-senha',
                component: () => import(/* webpackChunkName: "auth.forgot_password" */ '@/views/auth/ForgotPassword.vue'),
                props: true,
                meta: {
                    guestOnly: true,
                    noNavbar: true,
                },
            },
        ]
    },
    {
        name: 'logout',
        path: '/sair',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/auth/Logout.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            noNavbar: true,
        },
    },
]