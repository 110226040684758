var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-plus-square",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    },
    [
      _c("rect", {
        attrs: { x: "3", y: "3", width: "18", height: "18", rx: "2", ry: "2" }
      }),
      _c("line", { attrs: { x1: "12", y1: "8", x2: "12", y2: "16" } }),
      _c("line", { attrs: { x1: "8", y1: "12", x2: "16", y2: "12" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }