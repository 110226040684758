/**
 * Este arquivo importa todas as rotas no diretorio
 */

const files    = require.context('.', false, /Policy\.js$/)
var   policies = {}

files.keys().forEach(key => {
    // Ignora este proprio arquivo
    if (key === './index.js') return

    // Ignora o Base
    if (key === './BasePolicy.js') return

    let retKey = key.replace(/(\.\/|Policy\.js)/g, '')

    policies[retKey] = files(key).default
})

export default policies