import BaseModel    from './BaseModel'
import $persistence from '@/services/persistence'

export default class CookiesAgreement extends BaseModel {

    defaults() {
        return {
            id         : null,
            user_id    : null,
            user_agent : null,
            user_email : null,
            parent_id  : null,
            accepted_at: null,
        }
    }

    get itemKey() {
        return 'lgpd_loyalty_accepted'
    }

    get itemKeyOnError() {
        return 'lgpd_loyalty_accepted_error'
    }

    /**
     * Salva o registro de cookies aceitos na camada de persistência.
     *
     * @param {string} value         Valor que dever ser salvo no registro
     * @param {number} expireInYears Quantidade em anos em que o registro deve expirar
    */
    setOnPersistence(value, expireInYears) {
        $persistence.setItem(this.itemKey, value, expireInYears * 365)
    }

    /**
     * Busca o registro de cookies aceitos na camada de persistência.
     * 
     * @returns {string}
     */
    getFromPersistence() {
        return $persistence.getItem(this.itemKey)
    }

    /**
     * Deleta o registro de cookies aceitos da camada de persistência.
     */    
    deleteFromPersistence() {
        $persistence.removeItem(this.itemKey)
    }

    // Funções para tratar quando da erro no aceite dos cookies

    /**
     * Deleta o registro de cookies com erro.
    */    
    deleteFromErrorPersistence() {
        $persistence.removeItem(this.itemKeyOnError)
    }

    /**
     * Busca o registro de cookies com erro.
     * 
     * @returns {string}
     */
    getFromErrorPersistence() {
        return $persistence.getItem(this.itemKeyOnError)
    }

    /**
     * Salva o registro temporario de cookies aceitos mas que deram erros.
     *
    */
    setOnErrorPersistence() {
        $persistence.setItem(this.itemKeyOnError, true, 1)
    }

}