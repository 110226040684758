var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseInput", {
        attrs: { disabled: _vm.attrs.disabled },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-label secondary_font--text",
                      attrs: { for: "input" }
                    },
                    [_vm._v(_vm._s(_vm.attrs.label))]
                  ),
                  _vm.swapButton && !_vm.attrs.disabled
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "ml-auto primary--text caption-font-size pointer",
                          on: { click: _vm.swapMode }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.dynamicButtonTextInverse) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "input",
            fn: function() {
              return [
                _c(
                  "v-text-field",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.computedDocumentMask,
                            expression: "computedDocumentMask"
                          }
                        ],
                        ref: "input",
                        staticClass: "document-input",
                        attrs: { solo: "", flat: "", value: _vm.computedValue },
                        on: { input: _vm.onInput }
                      },
                      "v-text-field",
                      _vm.attrs,
                      false
                    ),
                    _vm.listeners
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }