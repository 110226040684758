import BasePolicy from './BasePolicy'

export default class BenefitPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onBenefit, __, company_id) {
        if (authorizable && authorizable.hasRole(['admin', 'marketing']))
            return true
    }

    create(authorizable) { return false }

    edit(authorizable, onBenefit) { return false }

    view(authorizable, onBenefit) { return false }

    list(authorizable, onBenefit) { return false }

    delete(authorizable, onBenefit) { return false }

}