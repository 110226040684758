import BasePolicy from './BasePolicy'
import $store     from '@/store'

export default class VoucherPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onType, __, company_id) {
        let companySettings = $store.getters['company/settings']
        if (companySettings.is_voucher_withdrawal_via_admin_locked)
            return false

        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (authorizable && authorizable.hasRole(['admin']))
            return true
    }

    withdraw(authorizable) {
        return authorizable && authorizable.hasRole('operator')
    }

    list(authorizable) {
        return this.is_parent_company_selected && authorizable.hasRole('admin')
    }

}