export default {
    not_authorized: 'Esta operação não foi autorizada. Verifique sua senha atual.',
    actions: 'Ações',
    type_here: 'Digite aqui',
    wait: 'Aguarde um momento...',

    CustomerHistoryForm: {
        title: 'Procure o cliente e veja seu histórico',

        alerts: {
            network_error: '@:globals.network_error',
            error: 'Ocorreu um erro ao buscar o cliente',
        },
    },
    CustomerForm: {
        name: {
            label: 'Nome',
            hint: 'Nome do cliente',
            placeholder: 'Cliente da Silva',
        },
        birthdate: {
            label: 'Data de nascimento',
            hint: 'Data de nascimento do cliente',
            placeholder: '01/01/2001',
        },
        document: {
            label: {
                cpf: 'CPF',
                cnpj: 'CNPJ'
            },
            hint: 'Número do documento do cliente',
            disabled_hint: 'Não é permitida a edição',
            placeholder: {
                cpf: '123.123.123-12',
                cnpj: '12.312.312/3123-12'
            },
        },
        address: {
            label: 'Endereço',
            hint: 'Endereço do cliente',
            placeholder: 'R. Cel Dulcídio, 588 - Batel',
        },
        email: {
            label: 'Email',
            hint: 'Email do cliente',
            disabled_hint: 'Habilitado somente para login via email',
            placeholder: 'exemplo@email.com',
            required: 'Você precisa informar um email'
        },
        phone: {
            label: 'Celular',
            hint: 'Número de celular do cliente',
            placeholder: '(00) 00000-0000',
            required: 'Você precisa informar um número'
        },
        password: {
            label: 'Senha',
            hint: 'Verifique se o CAPS LOCK está habilitado',
            placeholder: 'Insira a senha',
        },
        password_confirmation: {
            label: 'Confirmação de senha',
            hint: '@:forms.CustomerForm.password.hint',
            placeholder: 'Insira a senha novamente',
            mismatch: 'As senhas devem ser iguais',
        },
        password_update: {
            label: 'Senha (opcional)',
        },
        password_confirmation_update: {
            label: 'Confirmação de senha (opcional)'
        }
    },
    LevelForm: {
        name: {
            label: 'Nome',
            hint: 'Como o nível deve ser chamado',
            placeholder: 'Nível 1',
        },
        trigger_amount: {
            label: 'Quantidade de pontos gatilho',
            hint: 'Quantos pontos o cliente precisa pra estar neste nível',
            hint_readonly: 'Não pode ser editado',
            placeholder: '100',
        },
        description: {
            label: 'Descrição breve',
            hint: 'Uma pequena descrição do nível',
            placeholder: 'No nível 1 você vai poder...',
        },
        tos_url: {
            label: 'Adicionar termos e condições',
            label_view: 'Termos e condições',
        },
        color: {
            label: 'Cor de identificação',
            hint: 'Insira uma cor como marca do nível',
            placeholder: 'Cor do nível',
        },
        icon_url: {
            label: 'Ícone de identificação',
            hint: 'Identifique cada nível com um ícone',
            placeholder: 'Ícone do nível',
            detail: 'Use um ícone único para identificar o nível!',
        },
    },
    TypeForm: {
        key: {
            label: 'Chave',
            hint: 'Chave única do tipo',
            placeholder: 'Type',
        },
        name: {
            label: 'Nome',
            hint: 'Nome do tipo',
            placeholder: 'type',
        },
    },
    CalculationRuleForm: {
        no_data_text: 'Sem tipos para selecionar',
        type_name: {
            label: 'Tipo relacionado'
        },
        type_id: {
            label: 'Tipo',
            hint: 'Tipo ao qual será aplicada a regra de cálculo',
            placeholder: 'chave - nome'
        },
        in_ratio: {
            label: 'Proporção de entrada',
            hint: 'Quantidade de pontos gerados a cada 1 real gasto pelo cliente',
            placeholder: '1',
        },
        out_ratio: {
            label: 'Proporção de saída',
            hint: 'Valor (em reais) de cada ponto quando ele for utilizado',
            placeholder: '1',
        },
        usage_limit: {
            label: 'Limite de uso (opcional)',
            hint: 'Quantidade de pontos que podem ser usados dentro do período de uso',
            placeholder: '1000',
            suffix: 'pontos',
        },
        usage_period: {
            label: 'Período de uso (opcional)',
            hint: 'Tempo (em minutos) em que o cliente pode usar seus pontos até a quantidade limite',
            placeholder: '60',
            suffix: 'minutos',
        },
        expires_in: {
            label: 'Expira em (dias)',
            hint: 'Tempo em dias em que os pontos gerados devem ficar disponíveis para consumo',
            placeholder: '365',
        },

        in_out_hint: {
            step1: {
                description1: 'Para cada',
                description2: 'R$ 1,00',
                description3: 'gasto em produtos',
            },
            step2: {
                description1: 'Seu cliente ganha',
                description2: '0 pt | {n} pt | {n} pts',
                description3: 'na plataforma',
            },
            step3: {
                description: 'Equivalente a {value}',
            },
            step3_disable_cashback: {
                description: 'Equivalente a {value}, caso o cashback estivesse ativo',
            },
        },

        usage_hint: {
            title: 'Limites de uso',
            description1: 'Insira valores nos campos abaixo <b>somente se você deseja limitar</b> o uso dos pontos gerados por esta regra de cálculo.',
            description2: 'Por exemplo, se você deseja restringir o cliente a utilizar no <b>máximo 100 pontos a cada duas horas</b>, preencha <i>limite de uso</i> 100 pontos e <i>período de uso</i> 120 minutos',
        },
    },
    LoginForm: {
        email: {
            label: 'Email',
            hint: 'Digite o email de administrador',
            placeholder: 'exemplo@email.com',
        },
        password: {
            label: 'Senha',
            hint: 'Digite sua senha',
            placeholder: '******',
        },
        forgot_password: 'Esqueci minha senha',
    },
    ParentCompanyForm: {
        company_name: {
            label: 'Razão social da empresa',
        },
        document: {
            label: 'CNPJ da empresa',
            placeholder: '12.312.312/3123-12'
        },
        levels: {
            update_lookback: {
                label: 'Período em dias usado para determinar o nível de um cliente',
                hint: 'Ex.: se o valor for 30, o cálculo do nível será feito com base no período de 30 dias atrás até hoje',
                placeholder: '30',
            },
            renovation_interval: {
                label: 'Duração em dias de cada período do sistema de níveis',
                hint: 'Ex.: se o valor for 180, a cada 180 dias se iniciará um novo período',
                placeholder: '180',
            },
        },
        display_mode: {
            label: 'Formato de exibição dos pontos no sistema',
        },
        hide_cashback: {
            label: 'Esconder o valor do cashback para o cliente',
        },
        disable_cashback: {
            label: 'Desabilitar o cashback',
        },
        hide_points: {
            label: 'Esconder os pontos para o cliente',
        },
        disable_action_notifications: {
            label: 'Desativar envio de notificações sobre ações para o cliente',
        },
        official_program_name: {
            label: 'Nome oficial do programa',
        },
        program_name: {
            label: 'Nome do programa a ser exibido',
        },
        frontend_domain: {
            label: 'Domínio do painel de cliente',
            hint: 'Domínio pelo qual o cliente final acessará o painel para ver seus pontos',
            placeholder: 'https://frontend.com',
            required: 'Campo obrigatório',
        },
        landing_page_domain: {
            label: 'Domínio da página inicial',
            hint: 'Domínio pelo qual o usuário acessará a página promocional inicial',
            placeholder: 'https://landingpage.com',
            required: 'Campo obrigatório',
        },
        admin_domain: {
            label: 'Domínio do painel administrador',
            hint: 'URL pela qual o administrador acessará o painel',
            placeholder: 'https://admin.com',
            required: 'Campo obrigatório',
            file_upload: 'Upload de arquivo',
        },
        favicon_url: {
            label: 'Ícone da empresa',
            // label: 'Endereço do ícone da empresa',
            hint: 'Imagem que será exibida na aba do navegador',
            // placeholder: 'https://example.com/favicon.ico'
        },
        logo_url: {
            label: 'Logo da empresa',
            hint: 'Imagem que será exibida na barra superior do navegador',
            // label: 'Endereço da logo da empresa',
            // placeholder: 'https://example.com/icon.png'
        },
        background_url: {
            label: 'Imagem de fundo do sistema',
            // label: 'Endereço da imagem de fundo do sistema',
            hint: 'Imagem de fundo das páginas de login',
            placeholder: 'https://example.com/background.png'
        },
        font_color: {
            label: 'Cor da fonte principal',
        },
        primary_color: {
            label: 'Cor principal'
        },
        background_color: {
            label: 'Cor do fundo'
        },

        banner: {
            title: {
                label: 'Título'
            }
        },

        home: {
            title: {
                label: 'Título',
            },

            hero: {
                title: {
                    label: 'Título',
                },
                subtitle: {
                    label: 'Subtítulo',
                },
                description: {
                    label: 'Descrição'
                },
                background_url: {
                    label: 'Imagem de fundo',
                    hint: 'Esta é a imagem principal que aparece no fundo da página ',
                    placeholder: '@:forms.ParentCompanyForm.background_url.placeholder'
                },
                height: {
                    label: 'Altura da imagem de fundo',
                    hint: 'Escolha a altura que a imagem de fundo irá assumir na página',
                    // hint: 'small, medium, large ou fullheight',

                    options: {
                        small: 'Pequeno',
                        medium: 'Médio',
                        large: 'Grande',
                        fullheight: 'Altura total',
                    },
                },
                customHeight: {
                    label: 'Altura da seção',
                    hint: 'Em px, pd ou porcentagem',
                    placeholder: '100px'
                },
                customClass: {
                    label: 'Classes que a seção deve utilizar',
                    hint: 'Separar as classes com vírgula'
                },
            },

            features: {
                title: {
                    label: 'Título',
                },
                subtitle: {
                    label: 'Subtítulo',
                }
            }
        },

        frontend: {
            register: {
                opt_in_marketing: {
                    label: {
                        label: 'Texto do opt-in marketing',
                    },
                },
            },
        },

        landing_page: {
            navbar_isSolid: {
                label: 'Navbar transparente ?',
            },

            font_color: {
                label: 'Cor da fonte',
            },

            background_color: {
                label: 'Cor do fundo',
            }
        },

        how_it_works: {
            hero: {
                title: {
                    label: '@:forms.ParentCompanyForm.home.hero.title.label'
                },
                background_url: {
                    label: '@:forms.ParentCompanyForm.home.hero.background_url.label',
                    hint: '@:forms.ParentCompanyForm.home.hero.background_url.hint',
                    placeholder: '@:forms.ParentCompanyForm.background_url.placeholder'
                },
                height: {
                    label: '@:forms.ParentCompanyForm.home.hero.height.label',
                    hint: '@:forms.ParentCompanyForm.home.hero.height.hint',

                    options: {
                        small: '@:forms.ParentCompanyForm.home.hero.height.options.small',
                        medium: '@:forms.ParentCompanyForm.home.hero.height.options.medium',
                        large: '@:forms.ParentCompanyForm.home.hero.height.options.large',
                        fullheight: '@:forms.ParentCompanyForm.home.hero.height.options.fullheight',
                    },
                },
                customHeight: {
                    label: '@:forms.ParentCompanyForm.home.hero.customHeight.label',
                    hint: '@:forms.ParentCompanyForm.home.hero.customHeight.hint',
                    placeholder: '@:forms.ParentCompanyForm.home.hero.customHeight.placeholder',
                },
                customClass: {
                    label: '@:forms.ParentCompanyForm.home.hero.customClass.label',
                    hint: '@:forms.ParentCompanyForm.home.hero.customClass.hint',
                },
            },

            features: {
                font_color: {
                    label: 'Cor da fonte',
                },

                background_color: {
                    label: 'Cor do fundo',
                },

                description: {
                    label: 'Descrição',
                },
            },
        },

        description: {
            title: {
                label: '@:forms.ParentCompanyForm.home.hero.title.label',
            },

            description:{
                label:'@:forms.ParentCompanyForm.home.hero.description.label',
            },

            font_color: {
                label: 'Cor do título',
            },
        },

        faq: {
            title: {
                label: '@:forms.ParentCompanyForm.banner.title.label'
            },

            items: {
                question: {
                    label: 'Pergunta {index}'
                },
                answer: {
                    label: 'Resposta {index}'
                }
            }
        },

        legal: {
            cookie_policy_url: {
                label: 'Adicionar política de cookies',
            },
            privacy_policy_url: {
                label: 'Adicionar política de privacidade',
            },
            terms_url: {
                label: 'Adicionar termos de uso',
            },
            custom_terms_enable: {
                label: 'Utilizar termos de uso customizado (HTML)',
            },
            custom_terms_html: {
                label: 'HTML do texto de termos de uso',
                hint: 'Para adicionar um link: primeiro escreva o texto, depois selecione o texto que conterá o link, clique no botão superior "Adicionar link" e cole o link. Este campo suporta diversos links ao mesmo tempo.',
            },
        },

        footer: {
            mark: {
                text: {
                    label: 'Marca',
                },
                url: {
                    label: 'Link da marca'
                },
            },

            text: {
                label: 'Texto complementar'
            },

            links: {
                text: {
                    label: 'Texto',
                },
                url: {
                    label: 'Link',
                },

                add_link   : 'adicionar link',
                remove_link: 'remover link',
            },
        },

        emails_customization: {
            enabled: {
                label: 'Ativar emails customizados',
            },

            successful_transaction: {
                title: {
                    label: 'Título do email',
                },
                body: {
                    label: 'Corpo do email',
                },
            },
            customer_level_updated_period_update: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            customer_level_updated_renovation: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            customer_level_updated_setup: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            customer_level_updated_simple: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            customer_level_updated_system_update: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            promotion_triggered: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
            vouchers_received: {
                title: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.title.label',
                },
                body: {
                    label: '@:forms.ParentCompanyForm.emails_customization.successful_transaction.body.label',
                },
            },
        },
    },
    SubsidiaryForm: {
        name: {
            label: 'Razão social',
        },
        document: {
            label: 'CNPJ',
            placeholder: '12.312.312/3123-12'
        }
    },
    CustomerDetailsForm: {
        title: 'Procure o cliente',
        subtitle: 'Atalhos e informações',

        no_document   : 'Obs: Este cliente não tem documento cadastrado. Identificador do cliente: <b>{id}</b>',
        no_level      : 'Não há níveis cadastrados',
        error_level   : 'Ocorreu um error ao buscar o nível',
        no_benefits   : 'Este nível não tem benefícios',
        error_benefits: 'Ocorreu um error ao buscar os benefícios',

        generate: 'Gerar pontos',
        consumption: 'Consumir pontos',
        withdraw_voucher: 'Retirar voucher',
        history: 'Ver histórico',

        level            : 'Informações de nível',
        benefits         : 'Benefícios disponíveis',
        advanced_settings: {
            title                 : 'Opções avançadas (Modo desenvolvedor)',
            force_email_login_type: 'Forçar login por email',
        },

        alerts: {
            network_error: '@:globals.network_error',
            error: '@:forms.CustomerHistoryForm.alerts.error',
        },
    },
    GC_CommonForm: {
        products_length         : ' | Apenas um tipo selecionado. | {n} tipos selecionados.',
        products_total_value    : 'Total: R$ {value}',
        products_total_amount   : 'Total: {value} pontos',
        add_product             : 'Adicionar tipo de produto',
        line_error              : 'Ops... Ocorreu um erro.',
        line_not_found          : 'Nenhuma regra de cálculo foi encontrada para este tipo. Verifique suas regras.',
        no_items                : 'Não há nenhum item válido',
        generate_title          : 'Simular compra de diferentes tipos',
        generate_search_title   : 'Procure o cliente e gere pontos',
        consumption_title       : 'Simular consumo de diferentes tipos',
        consumption_search_title: 'Procure o cliente e consuma pontos',
        zero_value_warning      : 'Este tipo não gera pontos',

        amount: {
            label: 'Quantidade de pontos gerados',
            placeholder: '10.000',
            hint: 'Digite a quantidade de pontos a serem gerados',
            suffix: '@:globals.points_short',
        },
        value: {
            label: 'Valor a simular',
            placeholder: 'R$ 10,00',
            hint: 'Digite para gerar a quantidade de pontos equivalente ao valor',
            prefix: 'R$',
        },
        type: {
            label: 'Tipo',
            placeholder: 'Bebidas',
            hint: 'Selecione o tipo a ser simulado',
        },

        confirmations: {
            generate: 'Gerar {pointText} (cashback de {valueText}) para o cliente?',
            generate_disable_cashback: 'Gerar {pointText} (com cashback desabilitado) para o cliente?',
            consumption: 'Gerar consumo no valor de {valueText} para o cliente?',
        },

        alerts: {
            network_error: '@:globals.network_error',
            error: 'Ocorreu um erro ao buscar o cliente',
        },

        select_customer_first_side: 'Primeiro, selecione o cliente ao lado',
        select_customer_first_above: 'Primeiro, selecione o cliente acima',
    },
    ProfileForm: {
        name: {
            label: 'Nome',
            placeholder: 'Administrador',
            hint: 'Digite o nome pelo qual deseja ser chamado no sistema',
        },
        email: {
            label: 'Email',
            placeholder: 'admin@exemplo.com',
            hint: 'Digite o email para notificações do sistema',
        },
        phone: {
            label: 'Telefone',
            placeholder: '(##) #####-####',
            hint: 'Digite o telefone para contato',
        },

        default_password_header: '@:views.welcome.first_access_warning',
        current_password: {
            label: 'Senha atual',
            placeholder: '********',
            hint: 'Digite a sua senha atual para autorizar a troca de senha',
        },
        password: {
            label: 'Senha nova',
            placeholder: '********',
            hint: 'Digite a sua nova senha',
        },
        password_confirmation: {
            label: 'Confirmação da senha nova',
            placeholder: '********',
            hint: 'Digite a sua nova senha novamente',
        },
    },
    CreateUserForm: {
        name: {
            label: 'Nome',
            placeholder: 'Fulano da Silva',
            hint: 'Digite o nome do novo usuário',
        },
        email: {
            label: '*Email',
            placeholder: 'usuario@exemplo.com',
            hint: 'Digite o email para acesso ao sistema',
        },
    },
    ForgotPasswordForm: {
        description: 'Insira o email utilizado para acessar o painel no campo abaixo. Enviaremos uma mensagem a esse email com instruções para a redefinição de senha.',
        success: 'Acabamos de enviar uma mensagem contendo instruções sobre como redefinir sua senha para seu email. Verifique a sua caixa de entrada.',
        not_found: 'Este email não foi encontrado.',

        email: {
            label: 'Email',
            placeholder: 'exemplo@email.com',
            hint: 'Digite o email utilizado para acessar o painel administrador',
        },
    },
    ResetPassword: {
        loading: 'Carregando...',
        different_password: 'A confirmação da senha não coincide com a senha. Verifique novamente.',
        success: 'A sua senha foi redefinida.',
        token_expired: 'O link de redefinição de senha expirou. Tente novamente.',

        password: {
            label: 'Nova senha',
            placeholder: '******',
            hint: 'Digite a sua nova senha',
        },
        password_confirmation: {
            label: 'Confirmação da nova senha',
            placeholder: '******',
            hint: 'Digite novamente a sua nova senha',
        },
    },
    BenefitForm: {
        name: {
            label: 'Título',
            placeholder: 'Ganhe 1 prêmio a cada 500 pontos',
            hint: 'Este título irá aparecer mais que a descrição',
        },
        description: {
            label: 'Descrição breve',
            placeholder: 'Escolha entre mais de 100 prêmios disponíveis para você',
            hint: 'Este texto irá aparecer em baixo do título',
        },
        type: {
            label: 'Tipo de benefício',
            placeholder: 'Vantagem',
            hint: 'Por enquanto, só temos o tipo "vantagem"',
        },
        tos_url: {
            label: 'Adicionar termos e condições',
            label_view: 'Termos e condições',
        },
        icon_url: {
            label: 'Ícone do benefício',
            placeholder: 'Link para a imagem do benefício',
            hint: 'Cole aqui o link da imagem da promoção',
        },
        color: {
            label: 'Cor base',
            // hint: 'Por enquanto, só temos o tipo "vantagem"',
        },
    },
    PrizeForm: {
        name: {
            label: 'Nome',
            placeholder: '500 ml de chopp grátis',
            hint: 'Nome do prêmio',
        },
        description: {
            label: 'Descrição breve',
            placeholder: 'Troque seu voucher pelo chopp',
            hint: 'Este texto irá aparecer em baixo do título',
        },
        expires_in: {
            label: 'Tempo de expiração (após o resgate)',
            placeholder: '7 dias',
            hint: 'O voucher do prêmio irá expirar depois desse tempo',
            suffix: 'dias',
        },
        expires_at: {
            label: 'Data de expiração',
            placeholder: '01/01/2021',
            hint: 'O prêmio só poderá ser resgatado até esta data',
        },
        value: {
            label: 'Preço em reais',
            placeholder: '29.90',
            hint: 'O cliente poderá trocar pontos, no valor correspondente, por este prêmio',
            suffix: 'reais',
        },
        category: {
            label: 'Categoria',
        },
        tos_url: {
            label: 'Adicionar termos e condições',
        },
        available_codes: {
            label            : 'Códigos disponíveis',
            hint             : 'Limita o número de vouchers que podem ser gerados para este produto para conter somente os códigos especificados nos arquivos inseridos acima.',
            download_template: 'Baxar modelo de arquivo',
            import_hint      : 'O arquivo agora está sendo processado. Para ver o progresso {link}.',
        },
        external_id: {
            label      : 'Identificador da integração (Opcional)',
            placeholder: '123123',
            hint       : 'Este código identifica o produto na integração de destino',
        },
        photo_url: {
            label: 'Imagem do prêmio',
            // placeholder: '',
            // hint: '',
        },
        show_in_store: {
            label: 'Mostrar na loja*',
            hint: '*Ao mostrar na loja você permite que o cliente troque pontos por este prêmio',
            // placeholder: '',
        },
    },
    WithdrawVoucherForm: {
        title: 'Procure o cliente para retirar o voucher',
        subtitle: 'Procure o voucher',

        alerts: {
            network_error: '@:globals.network_error',
            error: '@:forms.CustomerHistoryForm.alerts.error',
        },
    },
    RulesForm: {
        register_rules     : 'Regras de cadastro',
        block_rules        : 'Regras de bloqueio',
        levels_system_rules: 'Sistema de níveis',

        is_age_restriction_enabled: {
            label: 'Habilitar restrição de idade',
            hint: 'Marque esta opção se quiser bloquear o cadastro de pessoas menores que a idade determinada',
        },
        allow_from_age: {
            label: 'Habilitar o cadastro de pessoas a partir desta idade',
            hint: '',
        },
        allow_to_age: {
            label: 'Habilitar o cadastro de pessoas até esta idade',
            hint: '',
        },
        is_age_gate_enabled: {
            label: 'Habilitar age gate',
            hint: 'Marque esta opção se quiser que um banner de confirmação de idade seja exibido',
        },
        age_gate_url: {
            label: 'Link de redirecionamento do age gate',
            hint: 'Insira aqui o link para o qual o usuário de ser redirecionado ao responder negativamente no age gate',
        },

        is_generation_via_admin_locked: {
            label: 'Bloquear a geração de pontos pelo painel administrador',
            hint: '',
        },
        is_consumption_via_admin_locked: {
            label: 'Bloquear o consumo de pontos pelo painel administrador',
            hint: '',
        },
        is_voucher_withdrawal_via_admin_locked: {
            label: 'Bloquear a retirada de vouchers pelo painel administrador',
            hint: '',
        },

        levels_system: {
            label: 'Selecione o sistema de níveis',

            available: {
                lookback  : 'Contínuo',
                renovation: 'Renovação',
            },
        },
    },
    PrizeCategoryForm: {
        name: {
            label: 'Nome',
            placeholder: 'Nome da Categoria',
        }
    },
    PromotionForm: {
        alerts: {
            network_error: '@:globals.network_error',
            error: 'Ocorreu um erro ao buscar os dados da ação.',
        },
        name: {
            label: 'Nome',
            placeholder: 'Promoção de Maio',
            hint: 'Título da promoção',
        },
        description: {
            label: 'Descrição breve',
            placeholder: 'Acesse o link e receba um voucher',
            hint: 'Este texto irá aparecer no corpo do modal',
        },
        start_at: {
            label: 'Data início',
            placeholder: '05/05/2021',
            hint: 'Data que irá iniciar a exibição da promoção na área do cliente',
            error: 'Data de início deve ser anterior à data de término da promoção',
        },
        end_at: {
            label: 'Data fim',
            placeholder: '15/05/2021',
            hint: 'Data que a promoção será encerrada e não será mais exibida na área do cliente',
        },
        promo_url: {
            label: 'Link',
            placeholder: 'https://www.promocaoganhaumvoucher.com.br',
            hint: 'Link para acessa da página com mais detalhes da promoção',
        },
        photo_url: {
            label: 'Imagem da promoção',
        },
        triggers: {
            title: 'Gatilhos',
            description: 'Gatilhos configurados para esta promoção',
            
            type_trigger_label: 'Tipo do gatilho',
            internal_link_label: 'Código do link interno',
            type_filter_label: 'Tipo do filtro',
            time_past_label: 'Durção do filtro (horas)',
        },
        // show_in_modal: {
        //     label: 'Abrir em modal',
        //     hint1: 'Marque essa caixa se deseja que a promoção abra em um modal.',
        //     hint2: 'Essa funcionalidade ainda está em desenvolvimento. Todas as promoções serão mostradas como um banner na tela inicial da área do cliente.',
        // },
        sections: {
            triggers: {
                title: 'Gatilhos',
                description: 'Selecione as ações que irão disparar esta promoção.',
                types: {
                    label: 'Tipo de gatilho',
                    placeholder: '@:forms.PromotionForm.sections.triggers.title (Opcional)',
                    hint: 'Tipo de gatilho da promoção',

                    click_banner  : 'Clique no banner da promoção no painel do cliente',
                    internal_link : 'Link interno',
                    profile_update: 'Atualização de dados do cliente',
                },
                internal_link: {
                    label: 'Link amigável',
                    placeholder: 'ativar_promocao_de_julho',
                    hint: 'Insira uma URL amigável. Caracteres permitidos: letras, números, hífen e underline.',
                    hint_details: 'Esta promoção será ativada quando o cliente acessar a URL <a href="{link}" target="_blank">{link}</a>',
                },
            },
            filters: {
                title: 'Filtros',
                description: 'Selecione um filtro para esta promoção',
                add_filter: 'adicionar filtro',
                remove_filter: 'remover filtro',
                types: {
                    label: 'Tipo de filtro',
                    placeholder: '@:forms.PromotionForm.sections.filters.title (Opcional)',
                    hint: 'O filtro remove alguns clientes com base em regras específicas',

                    new_register     : 'Cadastro novo',
                    activations_limit: 'Limite de ativações',
                    complete_profile : 'Perfil completo',
                },
                new_register: {
                    label: 'Tempo decorrido (horas)',
                    placeholder: '5',
                    hint: 'Considera um cadastro novo se ele foi feito nas últimas x horas',
                    hint_details: 'Clientes que fizeram seu cadastro há mais de {n} hora não são elegíveis para esta promoção. | Clientes que fizeram seu cadastro há mais de {n} hora não são elegíveis para esta promoção. | Clientes que fizeram seu cadastro há mais de {n} horas não são elegíveis para esta promoção.',
                },
                activations_limit: {
                    label: 'Limite de ativações',
                    placeholder: '1',
                    hint: 'Limita a quantidade de vezes que esta promoção pode ser ativada',
                    hint_details: 'Clientes que ativaram a promoção {n} vez não poderão ativar a promoção novamente. | Clientes que ativaram a promoção {n} vez não poderão ativar a promoção novamente. | Clientes que ativaram a promoção {n} vezes não poderão ativar a promoção novamente.',
                },
            },
            actions: {
                title: 'Ações',
                description: 'Selecione uma ação para esta promoção',
                types: {
                    label: 'Tipo de ação',
                    placeholder: '@:forms.PromotionForm.sections.actions.title (Opcional)',
                    hint: 'O que irá ocorrer quando esta promoção for ativada?',

                    generate_points: 'Gerar pontos'
                },
                generate_points: {
                    type: {
                        label: 'Regra de cálculo',
                        placeholder: 'default',
                        hint: 'Selecione a regra a ser utilizada na geração de pontos'
                    },
                    value: {
                        label: 'Valor a simular',
                        placeholder: '100',
                        hint: 'Informe a quantidade de pontos que esta promoção irá gerar'
                    },
                    hint_details_1: 'Quando esta promoção for ativada, o cliente irá receber ',
                    hint_details_2: ' 0 pt | {n} pt | {n} pts ',
                    hint_details_3: ' | R${value} do tipo {type}.',
                    
                    hint_details_3_disable_cashback: ', com cashback desabilitado',
                },
            },
        },
    },
    AnalyticsForm: {
        gtm: 'Google Tag Manager',
        gtm_id: {
            label: 'Container ID',
            hint: 'Digite o ID de seu container no GTM',
            placeholder: 'GTM-XXXX',
        },
    },
    filters: {
        CustomersFilterForm: {
            name: {
                label: 'Nome',
                hint: 'Insira uma parte do nome do cliente',
                placeholder: 'Filtrar por nome',
            },
            document: {
                label: 'Documento (somente números)',
                hint: 'Insira uma parte do documento do cliente',
                placeholder: 'Filtrar por documento',
            },
            email: {
                label: 'Email',
                hint: 'Insira uma parte do email do cliente',
                placeholder: 'Filtrar por email',
            },
            phone: {
                label: 'Celular (somente números)',
                hint: 'Insira uma parte do número de celular do cliente',
                placeholder: 'Filtrar por celular',
            },
        },
    },
    CustomFieldForm: {
        label: 'Campos personalizados',
        hint : 'Selecione os campos que gostaria de inserir em seu formulário',

        fields: {
            sex: 'Gênero',
            phone: 'Celular',
            should_show: 'Exibir aceite de cookies',
        },

        models: {
            Customer: "Formulário de cadastro de cliente",
            Cookie: "Aceite de cookies",
        }
    },
    WebhookRegisterForm: {
        url: {
            label: 'URL',
            hint: 'Insira a URL de destino',
            placeholder: 'https://example.com',
        },

        sections: {
            types: {
                title: 'Eventos',
                add_type: 'adicionar evento',
                remove_type: 'remover evento',

                label: 'Selecione um evento',
                hint: 'Insira um evento que deve disparar o webhook',
                placeholder: '@:forms.WebhookRegisterForm.sections.types.labels.customer_created',

                labels: {
                    customer_created: 'Cliente criado',
                    customer_updated: 'Cliente atualizado',
                },
            },

            additional_headers: {
                title: 'Headers (opcional)',
                add_header: 'adicionar header',
                remove_header: 'remover header',

                key: {
                    label: 'Chave',
                    hint: 'Insira a chave do header',
                    placeholder: 'Header-Key',
                },

                value: {
                    label: 'Valor',
                    hint: 'Insira o valor do header',
                    placeholder: 'header-value',
                },
            },

            auth: {
                title: 'Autenticação (opcional)',

                auth_type: {
                    label: 'Tipo de autenticação',
                    hint: 'Escolha o tipo de autenticação',
                    placeholder: 'Sem autenticação',
                },

                bearer_token: {
                    label: 'Bearer token',
                    hint: 'Insira o bearer token',
                    placeholder: 'bearer-token',
                },
            },
        },
    },
}