import BaseModel from '@/models/BaseModel'

export default class Benefit extends BaseModel {

    defaults() {
        return {
            id         : null,
            name       : null,
            description: null,
            tos_url    : null,
            icon_url   : null,
            color      : null,
            type       : 'advantage',   // Por enquanto o único benefício cadastrado é do tipo vantagem
            level_id   : null,
            created_at : null,
        }
    }

    // Métodos da API

    static async list(level_id, additionalConfig = {}) {
        let res = await this.request({
            url: `/level/${level_id}/benefit`,
            method: 'get',
            ...additionalConfig,
        })

        if (!res)
            return []

        return res.data.map(i => new this(i))
    }
}