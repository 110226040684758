import { render, staticRenderFns } from "./ImportantNotifications.vue?vue&type=template&id=0a56d3b1&"
import script from "./ImportantNotifications.vue?vue&type=script&lang=js&"
export * from "./ImportantNotifications.vue?vue&type=script&lang=js&"
import style0 from "./ImportantNotifications.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBanner,VBtn,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/cplug-fid-front-admin/cplug-fid-front-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0a56d3b1')) {
      api.createRecord('0a56d3b1', component.options)
    } else {
      api.reload('0a56d3b1', component.options)
    }
    module.hot.accept("./ImportantNotifications.vue?vue&type=template&id=0a56d3b1&", function () {
      api.rerender('0a56d3b1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/shared/components/banner/ImportantNotifications.vue"
export default component.exports