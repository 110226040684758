var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isModalOpen
    ? _c(
        "v-dialog",
        _vm._g(
          _vm._b(
            {
              model: {
                value: _vm.isModalOpen,
                callback: function($$v) {
                  _vm.isModalOpen = $$v
                },
                expression: "isModalOpen"
              }
            },
            "v-dialog",
            _vm.attrs,
            false
          ),
          _vm.listeners
        ),
        [
          _c(
            "v-card",
            { staticClass: "pa-2" },
            [
              _vm.title
                ? _c("v-card-title", [
                    _c(
                      "span",
                      {
                        staticClass:
                          "primary--text font-weight-bold modal-title"
                      },
                      [_vm._v(_vm._s(_vm.title))]
                    )
                  ])
                : _vm._e(),
              _vm.subtitle
                ? _c("v-card-subtitle", { staticClass: "pt-2" }, [
                    _vm._v(" " + _vm._s(_vm.subtitle) + " ")
                  ])
                : _vm._e(),
              _vm.hasSlot()
                ? _c(
                    "v-card-text",
                    { staticClass: "py-3", class: _vm.cardTextClass },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e(),
              _vm._t("actions", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end mx-6 mb-4" },
                  [
                    !_vm.computedCancelButton.hide
                      ? _c(
                          "v-btn",
                          _vm._b(
                            { on: { click: _vm.handleCancel } },
                            "v-btn",
                            _vm.computedCancelButton,
                            false
                          ),
                          [
                            _vm._v(
                              " " + _vm._s(_vm.computedCancelButton.label) + " "
                            )
                          ]
                        )
                      : _vm._e(),
                    !_vm.computedConfirmButton.hide
                      ? _c(
                          "v-btn",
                          _vm._b(
                            {
                              attrs: { depressed: "" },
                              on: { click: _vm.handleConfirm }
                            },
                            "v-btn",
                            _vm.computedConfirmButton,
                            false
                          ),
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.computedConfirmButton.label) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }