var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { app: "", color: "navbar", dark: "", flat: "", height: "70px" } },
    [
      _vm.$vuetify.breakpoint.smAndDown &&
      _vm.isAuthenticated &&
      _vm.user &&
      _vm.hasSelectedCompany
        ? _c("v-app-bar-nav-icon", {
            staticClass: "ml-1 ml-md-0 primary--text",
            on: { click: _vm.toggleDrawer }
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.smAndDown ? _c("v-spacer") : _vm._e(),
      _vm.logo_url
        ? _c("v-img", {
            staticClass: "mr-0 ml-0 ml-md-6 pointer",
            attrs: {
              src: _vm.logo_url,
              "max-width": "160",
              "max-height": "56",
              contain: ""
            },
            on: { click: _vm.titleClick }
          })
        : _vm._e(),
      _c(
        "v-toolbar-title",
        {
          staticClass: "d-flex align-center title clickable",
          on: { click: _vm.titleClick }
        },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "span",
                { staticClass: "ml-3 secondary_font--text program-name" },
                [
                  _vm._v(
                    _vm._s(
                      _vm.selectedCompany && _vm.selectedCompany.name
                        ? _vm.selectedCompany.name
                        : _vm.$t("navbar.title")
                    )
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c("v-spacer"),
      !_vm.isAuthenticated
        ? [
            _c(
              "v-btn",
              { attrs: { text: "", to: { name: "redirects.landing-page" } } },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.welcome")))])]
            ),
            _c(
              "v-btn",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shouldShowLoginButton,
                    expression: "shouldShowLoginButton"
                  }
                ],
                staticClass: "ml-3",
                attrs: {
                  to: { name: "login" },
                  color: "success",
                  depressed: ""
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.$t("navbar.login")))])]
            )
          ]
        : [
            _c(
              "v-icon",
              {
                staticClass: "mr-4 mr-md-0 primary-font-color",
                attrs: { color: "secondary_font", size: "20" }
              },
              [_vm._v("$cp_bell")]
            ),
            _vm.$vuetify.breakpoint.mdAndUp
              ? _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            return [
                              _c(
                                "v-btn",
                                _vm._g({ attrs: { text: "" } }, on),
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "mr-2 secondary_font--text user-name"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.user ? _vm.user.name : "")
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2 secondary_font--text",
                                      attrs: { small: "" }
                                    },
                                    [_vm._v("fas fa-angle-down")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      114580786
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { attrs: { to: { name: "settings" } } },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("navbar.settings")))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          { attrs: { to: { name: "logout" } } },
                          [
                            _c("v-list-item-title", [
                              _vm._v(_vm._s(_vm.$t("navbar.logout")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }