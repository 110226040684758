import SharedBenefit from '@/shared/models/Benefit'
import { required, string, length, isnil, positiveInteger } from '@/utils/validation'

export default class Benefit extends SharedBenefit {

    validation() {
        return {
            // id         : required.and(string.and(length(0, 191))),
            name       : required.and(string.and(length(2, 191))),
            description: required.and(string.and(length(2, 191))),
            tos_url    : isnil.or(string.and(length(2, 600))),
            icon_url   : isnil.or(string.and(length(2, 600))),
            color      : isnil.or(string.and(length(0, 7))),
            type       : required.and(string.and(length(0, 9))),
            level_id   : required.and(string.and(length(0, 191))),
            // created_at : required.and(string),
        }
    }

    // Métodos da API

    static async list(levelId, pagination, additionalParams = {}) {
        let params = {}
        if (pagination)
            params = {
                page    : pagination.page,
                per_page: pagination.per_page || pagination.perPage,
                ...additionalParams
            }

        let response = await this.request({
            url: `/level/${levelId}/benefit`,
            method: 'get',
            params
        })

        if (!response || !response.data || response.status != 200)
            return

        response.data = this.hydrate(response.data)

        return response
    }

    async create(additionalConfig = {}) {
        let data = this.getData([
            'name', 'description', 'tos_url', 'icon_url', 'cover_url', 'color', 'type',
        ])

        let res = await this.request({
            url: `/level/${this.level_id}/benefit`,
            method: 'post',
            data,
            ...additionalConfig,
        })

        // Atualiza as propriedades deste modelo depois de criar
        if (res && res.data)
            this._fill(res.data)

        return res
    }

    async update(additionalConfig = {}) {
        let data = this.getData([
            'name', 'description', 'tos_url', 'icon_url', 'cover_url', 'color', 'type',
        ])

        return await this.request({
            url: `/level/${this.level_id}/benefit/${this.id}`,
            method: 'put',
            data,
            ...additionalConfig,
        })
    }

    async delete(additionalConfig = {}) {
        return await this.request({
            url: `/level/${this.level_id}/benefit/${this.id}`,
            method: 'delete',
            ...additionalConfig,
        })
    }

    static async get(level_id, benefit_id, additionalConfig = {}) {
        let res = await this.request({
            url: `/level/${level_id}/benefit/${benefit_id}`,
            method: 'get',
            ...additionalConfig,
        })

        if (!res)
            return null

        return new Benefit(res.data)
    }

}