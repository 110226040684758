import ConsolidatedLevelData from '@/models/ConsolidatedLevelData'
import TransitionLevelData from '@/models/TransitionLevelData'
import BaseModel from '@/models/BaseModel'
import axios from '@/services/axios' 
import Level from '@/models/Level'
import $store from '@/store'
import AuthACL from '@/auth'
import moment from 'moment'
import _ from 'lodash'
// import { required, email, string, cpf, cnpj, birthday, password, required_contact, length, isnil, phone } from '@/utils/validation'
import { required, email, string, cpf, cnpj, birthday, length, isnil, phone } from '@/utils/validation'

export default class Customer extends BaseModel {

    defaults() {
        return {
            id                   : null,
            firebase_uid         : null,
            email                : null,
            phone                : null,
            name                 : null,
            birthdate            : null,
            document             : null,
            address              : null,
            avatar_url           : null,
            firebaseResult       : null,
            login_type           : null,
            emailVerified        : null,
            firebaseAction       : null,
            require_password     : true,
            balance              : {
                amount_balance : 0,
                received_amount: 0,
                spent_value    : 0,
                value_balance  : 0,
            },
            // Informações complexas
            levels_system: {
                current: undefined,
                next   : undefined,
            },
            // Informações auxiliares sobre níveis
            level_reset_pending: false,

            use_cnpj  : false,
            created_at: null,
            updated_at: null,
        }
    }

    relationships() {
        return {
            consolidated_level_data: { class: ConsolidatedLevelData, defaultValue: new ConsolidatedLevelData },
            transition_level_data  : { class: TransitionLevelData,   defaultValue: new TransitionLevelData   },
        }
    }

    validation() {
        return {
            // Quando tiver login por telefone, pode usar apenas uma das formas de contato
            // [FID-653] Comentado temporariamente enquanto não habilitamos o telefone
            // email: required_contact.and(isnil.or(string.and(email))),
            // phone: required_contact.and(isnil.or(string.and(phone))),
            email: required.and(isnil.or(string.and(email))),
            phone: isnil.or(string.and(phone)),

            name     : required.and(string).and(length(1, 191)),
            birthdate: isnil.or(string.and(birthday)),
            document : required.and(string).and(this.use_cnpj ? cnpj : cpf),
            address  : isnil.or(string)
        }
    }
    
    get consolidated_level() {
        return _.get(this, 'consolidated_level_data.level_data.level')
    }

    get transition_level() {
        return _.get(this, 'transition_level_data.level')
    }

    get consolidated_accumulated_amount() {
        return _.get(this, 'consolidated_level_data.level_data.accumulated_amount')
    }

    get transition_accumulated_amount() {
        return _.get(this, 'transition_level_data.accumulated_amount')
    }

    // Métodos da API

    static async list(pagination, additionalParams = {}) {
        let params = {}
        if (pagination) {
            params = {
                page    : pagination.page,
                per_page: pagination.per_page || pagination.perPage,
            }
        }

        let response = await axios({
            url   : '/customer/list',
            method: 'get',
            params: {
                ...params,
                ...additionalParams,
            },
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    /**
     * Conta a quantidade de clientes retornados em uma determinada query
     *
     * @param {object} queryParams parâmetros da requisição
     */
    static async count(queryParams = {}) {
        let response = await axios({
            url   : '/customer/count',
            method: 'get',
            params: queryParams,
        })

        if (!response || !response.data || response.status != 200)
            return

        return response.data
    }

    async create() {
        let isFirebase = !this.email
        let attrs      = [ 'name', 'document', 'email', 'phone', 'require_password' ]

        if (isFirebase) {
            this.firebaseAction   = 'CREATE'
            this.require_password = false
            attrs.push('firebaseAction')
        }

        let data = this.getData(attrs)

        // Pega a company selecionada e coloca como fonte deste cliente
        data.subsidiary_id = $store.getters['company/selected_company_id']

        let url      = data.email ? '/customer/create-via-email' : '/customer/create-via-firebase'
        let response = await this.request({
            url,
            method: 'post',
            data,
        })

        if (!response)
            return

        return response
    }

    async update() {
        let data = this.getData([
            'name', 'address', 'birthdate', 'document', 'email', 'phone', 'password',
            'login_type',
        ])

        let authUser = $store.getters['auth/user']

        if (!AuthACL.getACL().can(authUser, 'dev'))
            delete data.login_type

        return await this.request({
            url   : `/customer?customer_id=${this.id}`,
            method: 'put',
            data,
        })
    }

    async delete() {
        let data = this.getData()

        return await this.request({
            url   : `/customer?customer_id=${data.id}`,
            method: 'delete'
        })
    }

    async get() {
        let data = this.getData()

        return await this.request({
            url   : `/customer?customer_id=${data.id}`,
            method: 'get'
        })
    }

    async updateLevel() {
        let data = this.getData()

        let response = await this.request({
            url: `/customer/update-level?customer_id=${data.id}`,
            method: 'get'
        })
        
        if (!response || !response.data || response.status != 200)
            return

        let consolidatedLevel = _.get(response, 'data.level')
        response.data.level = consolidatedLevel ? new Level(consolidatedLevel) : null

        return response.data
    }

    static async getById(id, additionalParams) {
        let response = await this.request({
            url   : `/customer?customer_id=${id}`,
            method: 'get',
            ...additionalParams,
        })

        if (!response)
            return

        return new this(response.data)
    }

    static async getByDocument(document) {
        let response = await this.request({
            url    : '/customer',
            method : 'get',
            headers: {
                'customer-document': document,
            },
        })

        if (!response)
            return

        return new this(response.data)
    }

    static determineUseCNPJ(document) {
        return !!document && !!(document.replace(/\D/g,'').length == 14)
    }

    hasLoginType(v) {
        return this.login_type == v
    }

    // Getters & Setters
    // Para manter a compatibilidade com partes do código que só utilizavam firebase_uid

    get id() {
        return this._id || this._firebase_uid
    }
    set id(v) {
        this._id = v
    }

    get firebase_uid() {
        return this._firebase_uid
    }
    set firebase_uid(v) {
        this._firebase_uid = v
    }

    // Métodos úteis

    // Retira formatação antes de enviar para o servidor
    formatToBack() {
        // [TODO-L][FID-114] internacionalizar
        this.birthdate && (this.birthdate = moment(this.birthdate, 'DD/MM/YYYY').format('YYYY-MM-DD'))
        this.document && (this.document = this.document.replace(/\D/g, ''))
        this.phone && (this.phone = '+55' + this.phone.replace(/\D/g, ''))
        this.email && (this.email = this.email.toLowerCase())
    }

    // Adiciona formatação necessária para exibição
    formatToFront() {
        // [TODO-L][FID-114] internacionalizar
        this.birthdate && (this.birthdate = this.birthdate.replace(/\D/g, '').replace(/(\d{4})(\d{2})(\d{2})/g, '$3$2$1'))
        this.document && (this.document = this.document.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2,$3-$4'))
        this.phone && (this.phone = this.phone.replace('+55', '').replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3'))
    }
}