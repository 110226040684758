import BaseModel  from './BaseModel'
import Subsidiary from './Subsidiary'

export default class CustomerHistoryRecord extends BaseModel {

    defaults() {
        return {
            id           : undefined,
            value        : undefined,
            amount       : undefined,
            integrator_id: undefined,
            created_at   : undefined,
            customer_id  : undefined,
            chr_type     : undefined,
        }
    }

    relationships() {
        return {
            company: { class: Subsidiary },
        }
    }

    get company_name() {
        return this.company ? this.company.name : ''
    }

}