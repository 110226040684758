import isFinite         from 'lodash/isFinite'
import isInteger        from 'lodash/isInteger'

export function positiveIntegerValidator(value, _min, _max, _maxValue) {
    if (!value)
        return true

    let _value = Number(value)

    if (isNaN(_value) || !isFinite(_value) || !isInteger(_value) || _value <= 0)
        return false

    let __value = String(value)

    return (!_maxValue || _value <= _maxValue) && (!_min || __value.length >= _min) && (!_max || __value.length <= _max)
}