var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAppLoaded && _vm.shouldShowFooter
    ? _c(
        "v-footer",
        { staticClass: "notification-footer pa-0", attrs: { app: "" } },
        [
          _vm.showCookiesNotification
            ? _c("CookiesNotification", {
                on: {
                  "cookies-agreement": function($event) {
                    _vm.showCookiesNotification = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }