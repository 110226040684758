var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.customClass, style: _vm.computedStyle },
    [
      _vm._t("label", [
        !_vm.hideLabel
          ? _c(
              "label",
              {
                staticClass: "input-label secondary_font--text",
                attrs: { for: "input" }
              },
              [_vm._v(_vm._s(_vm.label))]
            )
          : _vm._e()
      ]),
      _vm._t("input")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }