export default [
    {
        path: '/promocoes',
        name: 'promotions',
        redirect: 'promocoes/lista'
    },
    {
        path: '/promocoes/lista',
        name: 'promotions.list',
        component: () => import(/* webpackChunkName: "promotions.list" */ '@/views/promotions/List.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'promotions.list',
            can: 'list Promotion',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard', // [TODO] Internacionalizar
                    to: { name: 'home' }
                },
                {
                    text: 'Promoções',
                    disabled: true
                }
            ]
        },
    },
]