<template>
    <tr>
        <td>{{ item.jti }}</td>
        <v-tooltip bottom>
            <template #activator="{ on, attrs }">
                <td>
                    <div v-bind="attrs" v-on="on">{{ item.iat | datetime }}</div>
                </td>
            </template>
            <span>{{ item.iby }}</span>
        </v-tooltip>
        <td>
            <v-tooltip bottom v-if="item.isRevoked()">
                <template #activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" color="error" label outlined small>{{ $t(`integrations.${integration.key}.settings.table.status.revoked`) }}</v-chip>
                </template>
                <span>{{ $t(`integrations.${integration.key}.settings.table.status.revoked_tooltip`, item) }}</span>
            </v-tooltip>
            <v-chip v-else color="success" label outlined small>{{ $t(`integrations.${integration.key}.settings.table.status.active`) }}</v-chip>
        </td>
        <td>
            <v-btn v-if="!item.isRevoked()" color="error" depressed small outlined block @click="revokeToken" :loading="loading">
                <v-icon small class="mr-2">$cp_ban</v-icon>
                {{ $t(`integrations.${integration.key}.settings.table.actions.revoke`) }}
            </v-btn>
            <v-btn v-else color="info" depressed small outlined block @click="activateToken" :loading="loading">
                <v-icon small class="mr-2">$cp_check</v-icon>
                {{ $t(`integrations.${integration.key}.settings.table.actions.activate`) }}
            </v-btn>
        </td>
    </tr>
</template>
<script>
import IntegratorToken      from '@/models/IntegratorToken'
import BaseIntegrationModel from '../BaseIntegrationModel'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'TokenTableItem',
    mixins: [ HasErrorHandlerMixin ],
    props: {
        integration: {
            required: true,
            type: BaseIntegrationModel,
        },
        item: {
            required: true,
            type: IntegratorToken,
        },
    },
    data: vm => ({
        loading: false,
    }),
    methods: {
        async revokeToken() {
            this.loading = true
            let res = await this.item.revoke()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t(`integrations.${this.integration.key}.settings.table.actions.revoke_success`), 'success')
            this.$emit('update')
        },
        async activateToken() {
            this.loading = true
            let res = await this.item.activate()
                .catch(this.errorHandler)
            this.loading = false

            if (!res)
                return

            this.$bus.$emit('message', this.$t(`integrations.${this.integration.key}.settings.table.actions.activate_success`), 'success')
            this.$emit('update')
        },
    },
}
</script>