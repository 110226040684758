export default [
    {
        path: '/premios/categoria',
        name: 'prizeCategory',
        redirect: 'premios/lista'
    },
    {
        path: '/premios/categoria/lista',
        name: 'prizeCategory.list',
        component: () => import(/* webpackChunkName: "prizeCategory.list" */ '@/views/prizes/category/List.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'prizeCategory.list',
            can: 'list PrizeCategory',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Prêmios',
                    to: { name: 'prizes.list' }
                },
                {
                    text: 'Categoria',
                   disabled: true
                },
            ]
        },
    },
]