import ConstantsService from '@/services/constants'

export default {
    [ConstantsService.getConstant('INTEGRATIONS_KEY_SELF_COMPANY_TOKEN')]: {
        settings: {
            subheader: 'Configurações de integração interna da empresa',
            description: 'Para ter acesso programático a todas as informações da empresa via API é necessário gerar um token. Tome muito cuidado, pois esse token dará <b class="red--text">ACESSO TOTAL</b> às informações da empresa.',
            generate_token: 'Gerar novo token',
            generate_token_confirmation: '<b>Atenção:</b> Este token dará <b class="red--text">ACESSO TOTAL</b> às informações da empresa. Você tem certeza que deseja continuar com a geração?',
            generate_token_success: `<b class="red--text">Atenção: Este token será exibindo somente uma vez. Guarde-o em local seguro.</b><br><br>
            <code class="word-break-all">Token ID: {token_jti}</code><br><br>
            <code class="word-break-all">{token}</code><br>`,
            error: 'Ocorreu um erro ao carregar os tokens.',

            defined_errors: {
                'max-active-tokens-reached': 'Esta empresa atingiu o máximo de tokens ativos permitidos.',
            },

            table: {
                header: {
                    iat: 'Gerado em',
                    jti: 'Token ID',
                    status: 'Status',
                },
                actions: {
                    revoke: 'Revogar',
                    revoke_success: 'O token foi revogado com sucesso.',
                    activate: 'Ativar',
                    activate_success: 'O token foi reativado com sucesso.',
                },
                status: {
                    active: 'Ativo',
                    revoked: 'Revogado',
                    revoked_tooltip: 'Revogado em {revoked_at_formatted} por {revoked_by}',
                },
            },
        },
    },
    [ConstantsService.getConstant('INTEGRATIONS_KEY_CPLUG_SUBSCRIPTION')]: {
        settings: {
            subheader: 'Clube de Assinaturas CPlug',
            description: 'Ative as funcionalidades do clube de assinaturas da CPlug para seus clientes fidelidade.',
            error: 'Ocorreu um erro ao atualizar o clube de assinaturas CPlug.',

            enable: {
                success: 'Integração ativada com sucesso.',
            },
            disable: {
                success: 'A integração foi desativada.',
            },
        },
    },
}