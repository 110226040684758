import $console from '@/services/console'
import $bus     from '@/services/bus'
import $bugsnag from '@/services/bugsnag'
import $i18n    from '@/i18n'
import _        from 'lodash'

/**
 * Função genérica de tratamento de erros que
 * lida com erros não tratados diretamente nos componentes.
 *
 * Obs. Executa sobre qualquer endpoint ou instância do axios em que seja atribuída.
 * Este comportamento é diferente do @/services/axios/instance que só executa sobre o
 * endpoint do fidelidade.
 *
 * @param {Vue} context Componente vue em que o erro foi gerado
 * @param {Error} error Instância de erro gerado
 */
export default function (context, error) {
    if (!error) {
        $console.warn(`errorHandler received a non error.`)
        $console.trace()
        return
    }

    // Verifica network error
    if (error.message == 'Network Error') {
        $bus.$emit('alert', $i18n.t('errors.network_error'), 'error')
        return
    }

    // Por padrão, emite um alert com a mensagem de erro padão para determinado http status code.
    let status           = _.get(error, 'response.status', null)
    let messageIsDefined = !!$i18n.t('errors.http')[status]
    let message          = messageIsDefined ? $i18n.t(`errors.http.${status}`) : ''

    if (message) {
        $bus.$emit('alert', message, 'error')
    }

    // Mostra erro para dev
    $console.error('--- ErrorHandler ---')
    $console.error('context', context)
    $console.error('error', error)
    $console.error('')

    // Só envia erros que não tem resposta, pois se tem resposta o erro vai
    // ser pego no backend
    if (!status && $bugsnag.isEnabled()) {
        $bugsnag.getInstance().notify(error)
    }
}