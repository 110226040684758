import Vue      from 'vue'
import policies from './policies'
import ACL      from 'vue-browser-acl'
import $store   from '@/store'
import $router  from '@/router'

// Modelos
import Customer        from '@/models/Customer'
import Type            from '@/models/Type'
import CalculationRule from '@/models/CalculationRule'
import Generator       from '@/models/Generator'
import Consumption     from '@/models/Consumption'
import AdminUser       from '@/models/AdminUser'
import Company         from '@/models/Company'
import Subsidiary      from '@/models/Subsidiary'
import Level           from '@/models/Level'
import PrizeCategory   from '@/models/PrizeCategory'
import Benefit         from '@/models/Benefit'
import IntegratorToken from '@/models/IntegratorToken'
import CompanySettings from '@/models/CompanySettings'
import Report          from '@/models/Report'
import ImportEntry     from '@/models/ImportEntry'
// import Point    from '@/models/Point'

export default new class AuthACL {
    constructor() {
        this.acl = null
    }

    /**
     * Retorna a instância de ACL para realizar autorizações fora do Vue
     *
     * @return {ACL}
     */
    getACL() {
        return this.acl
    }

    /**
     * Retorna o usuário que será considerado para realizar as verificações de permissão
     *
     * @return {User}
     */
    getUser() {
        return $store.getters['auth/user']
    }

    /**
     * Inicializa a configuração das permissões de usuário
     */
    async setup() {
        Vue.use(ACL, this.getUser, (acl) => {
            this.setupACL(acl)
            this.acl = acl
        }, { router: $router })
    }

    /**
     * Inicializa a configuração das permissões de usuário
     *
     * @param {acl} acl acl
     */
    setupACL(acl) {
        this.setupMapping(acl)

        for (let key in policies) {
            let policy = policies[key]
            acl.policy(policy, key)
        }

        acl.rule('dev', (user) => user.hasRole('dev'))
    }

    /**
     * Inicializa a configuração do mapeamento entre modelos e strings
     *
     * @param {acl} acl acl
     */
    setupMapping(acl) {
        acl.register(Customer,        'Customer')
        acl.register(Type,            'Type')
        acl.register(CalculationRule, 'CalculationRule')
        acl.register(Generator,       'Generator')
        acl.register(Consumption,     'Consumption')
        acl.register(AdminUser,       'AdminUser')
        acl.register(Company,         'Company')
        acl.register(Subsidiary,      'Subsidiary')
        acl.register(Level,           'Level')
        acl.register(Benefit,         'Benefit')
        acl.register(IntegratorToken, 'IntegratorToken')
        acl.register(CompanySettings, 'CompanySettings')
        acl.register(ImportEntry,     'ImportEntry')
        acl.register(Report,          'Report')
        // acl.register(Point, 'Point')
        acl.register(PrizeCategory, 'PrizeCategory')
    }
}