export default [
    {
        name: 'select_company',
        path: '/selecao-de-empresa',
        component: () => import(/* webpackChunkName: "select_company" */ '@/views/select_company/SelectCompany.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            title: 'select_company',
            breadcrumbs: [
                {
                    text: 'Seleção de empresa',
                    disabled: true
                },
            ],
        },
    },
]