var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-banner",
    { attrs: { align: "center" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "py-3 d-flex align-center justify-center",
              attrs: { cols: "12", lg: "10", md: "10", sm: "12" }
            },
            [
              _c(
                "div",
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.description))]),
                  _vm._t("additionalContent")
                ],
                2
              )
            ]
          ),
          _vm._t("actions", [
            _vm.buttonConfirm
              ? _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center justify-center",
                    attrs: { cols: "12", lg: "2", md: "2", sm: "12" }
                  },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary elevation-0 ml-1",
                        attrs: { loading: _vm.loading },
                        on: {
                          click: function($event) {
                            return _vm.confirm()
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.buttonConfirm) + " ")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }