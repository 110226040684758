<template>
    <v-navigation-drawer v-if="selected_company_id" color="sections" :mini-variant="$vuetify.breakpoint.mdAndUp && mini" :value="value" :permanent="$vuetify.breakpoint.mdAndUp" @input="onInput" app disable-resize-watcher>
        <v-list-item :class="mini ? 'px-0' : ''">
            <v-list-item-content>
                <v-row>
                    <v-col v-if="$vuetify.breakpoint.smAndDown || !mini" cols="10">
                        <v-list-item-title class="title">
                            {{ program_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ $t('drawer.subtitle') }}
                        </v-list-item-subtitle>
                    </v-col>

                    <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp && !mini">
                        <v-icon @click="mini = true" color="primary" class="px-2 py-1 mt-2 mr-1 close-drawer-icon">
                            fas fa-angle-left
                        </v-icon>
                    </v-col>

                    <v-col cols="12" v-if="$vuetify.breakpoint.mdAndUp && mini" class="pt-2 pb-5">
                        <v-btn @click="mini = false" icon class="ml-2">
                            <v-icon color="primary">$cp_bars</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-list-item-content>
        </v-list-item>

        <v-list
            class="drawer-list"
            dense
            nav
        >
            <template v-if="isAuthenticated">
                <v-list-item link
                    v-for="item in items"
                    :key="item.title"
                    :to="item.to"
                    :disabled="item.disabled"
                >
                    <v-icon color="primary" class="list-icon">{{ item.icon }}</v-icon>

                    <v-list-item-content>
                        <v-list-item-title class="pl-4">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-else>
                <v-list-item link :to="{ name: 'login' }">
                    <v-icon color="primary" class="list-icon">$cp_login</v-icon>

                    <v-list-item-content>
                        <v-list-item-title class="pl-4">{{ $t('actions.login') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item link @click="welcome">
                    <v-icon color="primary" class="list-icon">$cp_info</v-icon>

                    <v-list-item-content>
                        <v-list-item-title class="pl-4">{{ $t('drawer.welcome') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>


        <v-list-item v-if="!isAuthenticated">
            <v-list-item-content>
                <v-list-item-subtitle>
                    <p>{{ $t('drawer.auth_needed') }}</p>
                </v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Drawer',
    props: {
        value: Boolean,
    },
    data: vm => ({
        configError: false,
        mini: true,
    }),
    methods: {
        onInput(value) {
            this.$emit('input', value)
        },
        welcome() {
            if (!this.landingPageUrl) {
                this.configError = true
                return
            }

            this.$emit('input', false)
            window.location = this.landingPageUrl
        },
    },
    computed: {
        ...mapGetters({
            program_name       : 'company/program_name',
            selected_company_id: 'company/selected_company_id',
            isAuthenticated    : 'auth/check',
        }),

        items() {
            let items = [
                { title: this.$t('drawer.home'), disabled: !this.isAuthenticated, to: { name: 'home' }, icon: '$cp_home' },

                this.$can('view',     'Customer')    ? { title: this.$t('drawer.find_customer'),     disabled: !this.isAuthenticated, to: { name: 'customers.details' },      icon: '$cp_user' }         : false,
                this.$can('withdraw', 'Voucher')     ? { title: this.$t('drawer.withdraw_voucher'),  disabled: !this.isAuthenticated, to: { name: 'vouchers.withdraw' },      icon: '$cp_package' }      : false,
                this.$can('create',   'Generator')   ? { title: this.$t('drawer.generate'),          disabled: !this.isAuthenticated, to: { name: 'generate' },               icon: '$cp_list_check' }   : false,
                this.$can('create',   'Consumption') ? { title: this.$t('drawer.consumption'),       disabled: !this.isAuthenticated, to: { name: 'consumption' },            icon: '$cp_database' }     : false,
                this.$can('list',     'Company')     ? { title: this.$t('drawer.company'),           disabled: !this.isAuthenticated, to: { name: 'companies.parent' },       icon: '$cp_edit' }         : false,
                this.$can('list',     'Subsidiary')  ? { title: this.$t('drawer.subsidiaries'),      disabled: !this.isAuthenticated, to: { name: 'companies.subsidiaries' }, icon: '$cp_buildings' }    : false,
                this.$can('list',     'Customer')    ? { title: this.$t('drawer.customers'),         disabled: !this.isAuthenticated, to: { name: 'customers' },              icon: '$cp_users' }        : false,
                this.$can('list',     'Type')        ? { title: this.$t('drawer.types'),             disabled: !this.isAuthenticated, to: { name: 'types' },                  icon: '$cp_calculator' }   : false,
                this.$can('list',     'Prize')       ? { title: this.$t('drawer.prizes'),            disabled: !this.isAuthenticated, to: { name: 'prizes' },                 icon: '$cp_shopping_bag' } : false,
                this.$can('list',     'Promotion')   ? { title: this.$t('drawer.promotions'),        disabled: !this.isAuthenticated, to: { name: 'promotions' },             icon: '$cp_promotion' }    : false,
                this.$can('list',     'Level')       ? { title: this.$t('drawer.levels'),            disabled: !this.isAuthenticated, to: { name: 'levels' },                 icon: '$cp_medal' }        : false,
                this.$can('history',  'Customer')    ? { title: this.$t('drawer.history'),           disabled: !this.isAuthenticated, to: { name: 'history' },                icon: '$cp_invoice' }      : false,
                this.$can('list',     'Report')      ? { title: this.$t('drawer.reports'),           disabled: !this.isAuthenticated, to: { name: 'reports' },                icon: '$cp_file' }         : false,
                this.$can('list',     'ImportEntry') ? { title: this.$t('drawer.import'),            disabled: !this.isAuthenticated, to: { name: 'import' },                 icon: '$cp_upload' }       : false,
                this.$can('view',     'LogEntry')    ? { title: this.$t('drawer.logs'),              disabled: !this.isAuthenticated, to: { name: 'logs' },                   icon: '$cp_logs' }         : false,

                { title: this.$t('drawer.settings'),       disabled: !this.isAuthenticated, to: { name: 'settings' },       icon: '$cp_settings' },
                { title: this.$t('drawer.select_company'), disabled: !this.isAuthenticated, to: { name: 'select_company' }, icon: '$cp_list'     },
            ]

            // Só mostra esta rota se está em modo desenvolvimento
            if (process.env.NODE_ENV === 'development')
                items.push({ title: 'dev', to: { name: 'dev' }, icon: 'fas fa-fw fa-code' })

            if (this.isAuthenticated)
                items.push({ title: this.$t('drawer.logout'), disabled: !this.isAuthenticated, to: { name: 'logout' }, icon: '$cp_logout' })

            return items.filter(i => !!i)
        },
    },
}
</script>

<style lang="scss">
.close-drawer-icon {
    float: right;
}

.list-icon {
    width: 30px;
    opacity: 0.3;
}

.v-list-item--active .list-icon {
    opacity: 1;
}

.v-list--nav .v-list-item:before {
    border-radius: 8px;
}
</style>