import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        isModalOpen: false,

        resolvePromise: null,
        rejectPromise: null,

        defaultConfig: {},
        currentConfigs: {},

        resolveValue: null,
    }),
    methods: {
        /**
         * Abre a modal com as seguintes configurações
         *
         * @param {*} config Configurações da modal
         */
        open(config = {}) {
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject

                this.validateConfigs(config)
                this.mergeConfigs(config)

                // if (!(await this.beforeOpen()))
                //     return
                this.beforeOpen()
                    .then(r => {
                        if (!r)
                            return

                        this.isModalOpen = true
        
                        setTimeout(() => {
                            this.opened()
                        })
                    })
            })
        },

        /**
         * É executado depois de abrir a modal
         */
        opened() {
            //
        },

        /**
         * É executado antes de abrir a modal
         *
         * @returns {boolean} true quando deve continuar com a abertura da modal
         */
        async beforeOpen() {
            return true
        },

        /**
         * Junta as configs passadas pelo open com as configs padrão desta modal
         *
         * @param {*} config Configurações da modal
         */
        mergeConfigs(config = {}) {
            this.currentConfigs = this.$lodash.defaults(config, this.defaultConfigs)
        },

        /**
         * Valida se as configurações da modal estão válidas
         * throw error em caso de erro
         *
         * @param {*} config Configurações da modal
         */
        validateConfigs(config = {}) {
            //
        },

        /**
         * É executado antes de cancelar a aÃ§ão
         *
         * @returns {boolean} true quando deve continuar com o cancelamento
         */
        async beforeCancel() {
            return true
        },

        /**
         * Cancela a aÃ§ão e fecha a modal
         */
        async cancel() {
            if (!(await this.beforeCancel.apply(this, arguments)))
                return

            this.resolve()
            this.close()
        },

        /**
         * É executado antes de limpar
         *
         * @returns {boolean} true quando deve continuar com a limpeza
         */
        async beforeClear() {
            return true
        },

        async clear() {
            if (!(await this.beforeClear.apply(this, arguments)))
                return

            this.currentConfigs = this.defaultConfigs
            this.resolveValue = null
        },

        async close() {
            this.isModalOpen = false
            await this.clear()
                .catch(this.errorHandler)
        },

        /**
         * É executado antes de confirmar
         *
         * @returns {boolean} true quando deve continuar com a confirmaÃ§ão
         */
        async beforeConfirm() {
            return true
        },

        async confirm() {
            if (!(await this.beforeConfirm.apply(this, arguments)))
                return

            this.resolve(this.resolveValue)
            await this.close()
                .catch(this.errorHandler)
        },


        // Promise ===============

        resolve() {
            typeof this.resolvePromise == 'function' && this.resolvePromise.apply(this, arguments)
        },
        reject() {
            typeof this.rejectPromise == 'function' && this.rejectPromise.apply(this, arguments)
        },
    },
}