import BaseModel from './BaseModel'

export default class Subsidiary extends BaseModel {

    defaults() {
        return {
            name      : null,
            document  : null,
        }
    }

    // Não precisa de validação, pois não havera o cadastro nesta app

}