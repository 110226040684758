import axios from '@/services/axios'
import BaseModel from '@/models/BaseModel'
import ConstantsService from '@/services/constants'
import { required, string, length } from '@/utils/validation'

export default class Type extends BaseModel {

    defaults() {
        return {
            id              : '',
            key             : '',
            name            : '',
            calculation_rule: null,

            allow_update: null,
            allow_delete: null,
        }
    }

    validation() {
        return {
            key:  required.and(string).and(length(1, 191)),
            name: required.and(string).and(length(1, 191))
        }
    }

    get select_text() {
        return `${this.name} - ${this.key}`
    }

    // Métodos da API

    static async list(pagination, additionalParams = {}) {
        let params = {}
        if (pagination)
            params = {
                page: pagination.page,
                per_page: pagination.per_page || pagination.perPage,
                ...additionalParams
            }

        let response = await axios({
            url: '/type',
            method: 'get',
            params
        })

        if (!response || !response.data || response.status != 200)
            return

        // A regra default deve poder ser editada, mas, para isso, o ícone de edição do tipo default precisa ficar ativo
        response.data.forEach((item, index) => {
            if (item.id == ConstantsService.getConstant('DEFAULT_TYP_ID'))
                response.data[index].allow_update = true
        })

        return response
    }

    async create() {
        let data = this.getData([
            'key', 'name'
        ])

        delete data.id

        let response = await this.request({
            url: `/type`,
            method: 'post',
            data,
        })

        if (!response || !response.data || response.status != 201)
            return

        return response
    }

    async update() {
        let data = this.getData([
            'key', 'name'
        ])

        let response = await this.request({
            url: `/type/${this.id}`,
            method: 'put',
            data
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async delete() {
        let data = this.getData()

        let response = await this.request({
            url: `/type/${data.id}`,
            method: 'delete'
        })

        if (!response || response.status != 204)
            return

        return response
    }

    async get() {
        let data = this.getData()

        let response = await this.request({
            url: `/type/${data.id}`,
            method: 'get'
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }
}