var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    [
      _c("td", [_vm._v(_vm._s(_vm.item.jti))]),
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c("td", [
                    _c("div", _vm._g(_vm._b({}, "div", attrs, false), on), [
                      _vm._v(_vm._s(_vm._f("datetime")(_vm.item.iat)))
                    ])
                  ])
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v(_vm._s(_vm.item.iby))])]
      ),
      _c(
        "td",
        [
          _vm.item.isRevoked()
            ? _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-chip",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      color: "error",
                                      label: "",
                                      outlined: "",
                                      small: ""
                                    }
                                  },
                                  "v-chip",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "integrations." +
                                        _vm.integration.key +
                                        ".settings.table.status.revoked"
                                    )
                                  )
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    4147020838
                  )
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "integrations." +
                            _vm.integration.key +
                            ".settings.table.status.revoked_tooltip",
                          _vm.item
                        )
                      )
                    )
                  ])
                ]
              )
            : _c(
                "v-chip",
                {
                  attrs: {
                    color: "success",
                    label: "",
                    outlined: "",
                    small: ""
                  }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "integrations." +
                          _vm.integration.key +
                          ".settings.table.status.active"
                      )
                    )
                  )
                ]
              )
        ],
        1
      ),
      _c(
        "td",
        [
          !_vm.item.isRevoked()
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    depressed: "",
                    small: "",
                    outlined: "",
                    block: "",
                    loading: _vm.loading
                  },
                  on: { click: _vm.revokeToken }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("$cp_ban")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "integrations." +
                            _vm.integration.key +
                            ".settings.table.actions.revoke"
                        )
                      ) +
                      " "
                  )
                ],
                1
              )
            : _c(
                "v-btn",
                {
                  attrs: {
                    color: "info",
                    depressed: "",
                    small: "",
                    outlined: "",
                    block: "",
                    loading: _vm.loading
                  },
                  on: { click: _vm.activateToken }
                },
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
                    _vm._v("$cp_check")
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "integrations." +
                            _vm.integration.key +
                            ".settings.table.actions.activate"
                        )
                      ) +
                      " "
                  )
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }