var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseInput", {
        attrs: { disabled: _vm.attrs.disabled },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function() {
              return [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "label",
                    {
                      staticClass: "input-label secondary_font--text",
                      attrs: { for: "input" }
                    },
                    [_vm._v(_vm._s(_vm.attrs.label))]
                  ),
                  _vm.search_link
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "ml-auto primary--text caption-font-size pointer",
                          staticStyle: { textDecoration: "none" },
                          attrs: { href: _vm.search_link, target: "_blank" }
                        },
                        [_vm._v(_vm._s(_vm.link_title))]
                      )
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "input",
            fn: function() {
              return [
                _c(
                  "v-text-field",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.computedPostalCodeMask,
                            expression: "computedPostalCodeMask"
                          }
                        ],
                        ref: "input",
                        staticClass: "postalcode-input",
                        attrs: {
                          solo: "",
                          flat: "",
                          value: _vm.localValue,
                          "background-color": "input_background_color"
                        },
                        on: { input: _vm.onInput }
                      },
                      "v-text-field",
                      _vm.attrs,
                      false
                    ),
                    _vm.listeners
                  )
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }