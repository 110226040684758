<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="19.954" height="21.271" viewBox="0 0 19.954 21.271"><g class="a" transform="translate(0.15 0.15)"><g transform="translate(0)"><path class="b" d="M32.395,0h-13.1a3.28,3.28,0,0,0-3.276,3.276V7.207a.655.655,0,0,0,.655.655h4.586V20.3h0v.012a.588.588,0,0,0,.024.118.607.607,0,0,0,.024.126s0,0,0,0a.631.631,0,0,0,.216.266.514.514,0,0,0,.066.043.61.61,0,0,0,.567.051l.012-.005h0l3.019-1.207,3.032,1.213a.65.65,0,0,0,.486,0l3.033-1.213,3.032,1.213a.655.655,0,0,0,.9-.609V3.276A3.28,3.28,0,0,0,32.395,0ZM21.257,6.551H17.326V3.276a1.965,1.965,0,0,1,3.931,0Zm13.1,12.791-2.377-.951a.65.65,0,0,0-.486,0L28.464,19.6,25.432,18.39a.65.65,0,0,0-.486,0l-2.378.951V3.276A3.238,3.238,0,0,0,21.9,1.31h10.5A1.968,1.968,0,0,1,34.36,3.276Z" transform="translate(-16.016)"/></g><g transform="translate(7.862 3.931)"><path class="b" d="M216.533,96h-7.862a.655.655,0,0,0,0,1.31h7.862a.655.655,0,1,0,0-1.31Z" transform="translate(-208.016 -96)"/></g><g transform="translate(7.862 9.172)"><path class="b" d="M216.533,224h-7.862a.655.655,0,1,0,0,1.31h7.862a.655.655,0,1,0,0-1.31Z" transform="translate(-208.016 -224)"/></g><g transform="translate(10.482 11.793)"><path class="b" d="M277.912,288h-5.241a.655.655,0,1,0,0,1.31h5.241a.655.655,0,1,0,0-1.31Z" transform="translate(-272.016 -288)"/></g><g transform="translate(10.482 14.413)"><path class="b" d="M277.912,352h-5.241a.655.655,0,1,0,0,1.31h5.241a.655.655,0,1,0,0-1.31Z" transform="translate(-272.016 -352)"/></g></g></svg>
</template>

<script>
export default {
    name: 'InvoiceIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0.3px;
}
</style>