import BaseModel from '@/models/BaseModel'

export default class Point extends BaseModel {

    defaults() {
        return {
            id                 : null,
            expires_at         : null,
            generator_id       : null,
            calculation_rule_id: null,
            parent_id          : null,
            amount             : null,
            consumption_id     : null,
            value              : null,
            customer_id        : null,
        }
    }
}