export default class BaseMiddleware {

    /**
     * Avalia se deve executar este middleware ou não
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve executar este middleware?
     */
    shouldRun(to, from) {
        return true
    }

    /**
     * Executa ações sobre esta rota
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve retornar false quando este middleware modifica a rota com next
     */
    handle(to, from, next) {
        return true
    }

}