export default {
    name: 'home',
    path: '/painel',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/home/Dashboard.vue'),
    meta: {
        requiresAuthentication: true,
        requiresSelectedCompany: true,
        breadcrumbs: [
            {
                text: 'Home',
                disabled: true
            },
        ],
    },
}