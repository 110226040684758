export default {
    cards: {
        PrizeCard: {
            edit: 'Editar',
            delete: 'Remover',
            price: '{price} pontos',
        },
        CustomerCard: {
            see_history: 'Ver histórico do cliente',
            balance : {
                amount_balance: {
                    label: 'Saldo em pontos',
                    value:  '{n}pt | {n}pt | {n}pts',
                },
                value_balance: {
                    label: 'Saldo em dinheiro',
                    value: 'R$ {value}',
                },
                spent_value: {
                    label: 'Total gasto em compras',
                    value: 'R$ {value}',
                },
                received_amount: {
                    label: 'Total de pontos recebidos',
                    value: '{n}pt | {n}pt | {n}pts',
                },
            },
        },
        LevelCard: {
            text_current: 'O cliente está no nível',
            text_next: 'O nível do próximo período é',
            accumulated_amount: 'Pontos acumulados',

            levels_system_header: 'Sistema de níveis',
            level_update_header: 'Atualização do nível',

            current: 'Atual',
            next: 'Novo',

            status: 'Status',
            status_updated: 'Atualizado',
            status_pending: 'Pendente',

            levels_system: {
                lookback: {
                    name: 'Contínuo',
                    consolidated_text: 'O cliente está no nível',
                },
                renovation: {
                    name: 'Renovação',
                    consolidated_text: 'Nível do período atual',
                    transition_text: 'Nível do próximo período',
                },
            },
        },
        HighlightedCard: {
            error_title       : '@:errors.simple.generic_title',
            error_subtitle    : '@:errors.simple.generic',
        },
    },
    inputs: {
        SearchCustomerInput: {
            cpf: {
                label: 'CPF',
                placeholder: '123.123.123-12',
                hint: 'Digite o CPF do cliente',
            },
            cnpj: {
                label: 'CNPJ',
                placeholder: '12.312.312/3123-12',
                hint: 'Digite o CPF do cliente',
            },
            success: 'Um cliente foi encontrado',
            not_found: 'Cliente não encontrado', 
        },
        SearchVoucherInput: {
            code: {
                label: 'Código do voucher',
                placeholder: '123456',
                hint: 'Digite o código do voucher apresentado pelo cliente',
                success: 'Um voucher foi encontrado',
            },
    
            withdrawn_voucher: 'Voucher já retirado',
            voucher_not_found: 'Voucher não encontrado',
        },
        FileInput: {
            select: 'Selecionar arquivo',
            change: 'Trocar arquivo',
            view: 'Ver arquivo',
            success: 'O arquivo foi salvo',
            size_error: ' Este arquivo excede o limite de {max} MB. Escolha um arquivo mais leve.',
            error: 'Houve um erro ao atualizar o arquivo.',
        },
        ImageInput: {
            success: 'A imagem foi atualizada',
            add: 'Adicionar imagem',
            size_error: 'Esta imagem excede o limite de {max} MB. Escolha uma imagem mais leve',
            error: 'Houve um erro ao atualizar a imagem.',
        },
        ReportGroupByCombobox: {
            invalid_max: 'Escolha até {max} agrupamentos',
        },
    },
    iterators: {
        BaseIterator: {
            no_data: 'Sem produtos para mostrar',
        },
    },
    filters: {
        common_title: 'Aplicar filtros'
    },
}