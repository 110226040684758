<template>
    <div>
        <BaseInput :disabled="attrs.disabled">
            <template #label>
                <div class="d-flex">
                    <label class="input-label secondary_font--text" for="input">{{ attrs.label }}</label>
                    <span v-if="swapButton && !attrs.disabled" @click="swapMode" class="ml-auto primary--text caption-font-size pointer">
                        {{ dynamicButtonTextInverse }}
                    </span>
                </div>
            </template>

            <template #input>
                <v-text-field
                    ref="input"
                    solo flat
                    class="document-input"
                    :value="computedValue"
                    @input="onInput"
                    v-on="listeners"
                    v-bind="attrs"
                    v-mask="computedDocumentMask"
                >
                </v-text-field>
            </template>
        </BaseInput>
    </div>
</template>

<script>
import BaseInput from './BaseInput'
import { mask } from '@/utils/vue-the-mask'

export default {
    name: 'SharedDocumentInput',
    inheritAttrs: false,
    directives: { mask },
    components: { BaseInput },
    props: {
        value: String,
        useCNPJ: Boolean,
        swapButton: {
            type: Boolean,
            default: true
        }
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            this.$emit('input', value.replace(/\D/g,'') ? value : null)
        },

        focus() {
            this.$refs.input.focus()
        },

        swapMode() {
            this.$emit('update:useCNPJ', !this.useCNPJ)
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },
        computedDocumentMask() {
            return this.useCNPJ ? this.$t('formats.cnpj_mask') : this.$t('formats.cpf_mask')
        },
        dynamicButtonTextInverse() {
            return this.useCNPJ ? 'Trocar para CPF' : 'Trocar para CNPJ'
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>

<style lang="scss">
.document-input {
    .v-input__append-outer {
        margin: 0 !important;
        margin-top: .34rem !important;
    }
}
</style>