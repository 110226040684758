import BaseModel from './BaseModel'

/**
 * Este modelo generaliza uma coleção(array) de modelos
 * e contém várias funções úteis
 */
export default class Collection extends Array {
    constructor() {
        super(...arguments)
        this._setModel()
        this._validateModels()
    }

    /**
     * Verifica se essa coleção contém apenas modelos BaseModel
     * 
     * @throws {TypeError}
     */
    _validateModels() {
        if (!this.every(item => item instanceof this.getModel()))
            throw new TypeError(`Collection expects every item to be instance of ${this.getModel().name}`)
    }

    /**
     * Verifica se essa coleção contém apenas modelos BaseModel
     *
     * @throws {TypeError}
     */
    _setModel() {
        this._Model = undefined
        if (this[0]) {
            if (!(this[0] instanceof BaseModel))
                throw new TypeError('Collection expects every item to be instance of BaseModel')
            this._Model = this[0].constructor
        }
    }

    /**
     * Retorna a classe BaseModel ao qual esta coleção se refere
     */
    getModel() {
        return this._Model || BaseModel
    }

    /**
     * Cria uma coleção a partir de um array e um modelo
     *
     * @param {object[]}  array array de objetos a serem hidratados
     * @param {BaseModel} Model classe que será instanciada
     */
    static hydrate(array, Model) {
        return Model.hydrate(array)
    }

    /**
     * Roda getData em cada um dos modelos desta coleção
     *
     * @returns {array}
     */
    toData() {
        return this.map(item => item.getData(...arguments))
    }
}