var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-info",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    },
    [
      _c("circle", { attrs: { cx: "12", cy: "12", r: "10" } }),
      _c("line", { attrs: { x1: "12", y1: "16", x2: "12", y2: "12" } }),
      _c("line", { attrs: { x1: "12", y1: "8", x2: "12.01", y2: "8" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }