var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "23.333",
        height: "21",
        viewBox: "0 0 23.333 21"
      }
    },
    [
      _c("path", {
        staticClass: "a",
        attrs: {
          d:
            "M14.667,9.167V4.5H3v21H26.333V9.167Zm-7,14H5.333V20.833H7.667Zm0-4.667H5.333V16.167H7.667Zm0-4.667H5.333V11.5H7.667Zm0-4.667H5.333V6.833H7.667Zm4.667,14H10V20.833h2.333Zm0-4.667H10V16.167h2.333Zm0-4.667H10V11.5h2.333Zm0-4.667H10V6.833h2.333ZM24,23.167H14.667V20.833H17V18.5H14.667V16.167H17V13.833H14.667V11.5H24Zm-2.333-9.333H19.333v2.333h2.333Zm0,4.667H19.333v2.333h2.333Z",
          transform: "translate(-3 -4.5)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }