// import moment from 'moment'
import BaseModel from '@/models/BaseModel'
import { required, email, string, password, required_password, isnil, phone, length, array } from '@/utils/validation'
import _ from 'lodash'
import $store from '@/store'

export default class AdminUser extends BaseModel {

    defaults() {
        return {
            email                : null,
            name                 : null,
            password             : null,
            password_confirmation: null,
            current_password     : null,
            is_default_password  : true,
            phone                : null,
            roles                : [],
        }
    }

    validation() {
        return {
            email                : required.and(email).and(string).and(length(2, 191)),
            name                 : required.and(string).and(length(2, 191)),
            password             : password(6, 30),
            password_confirmation: password(6, 30),
            current_password     : this.password ? required_password(6, 30) : () => true,
            phone                : isnil.or(string.and(phone)),
            roles                : isnil.or(array),
        }
    }

    // Métodos da API

    async update(adminEmail) {
        let data = this.getData([
            'name',
            'email',
            'phone',
            'roles',
            'password',
            'password_confirmation',
            'current_password',
        ])

        // Quando não está alterando a senha, não precisa passar esses outros campos
        if (!data.password) {
            data = _.omit(data, ['password', 'password_confirmation', 'current_password'])
        }

        // Atualiza informações do usuário na API
        // Erros estão sendo tratados no chamador (caller)
        let response = await this.request({
            url: '/admin',
            method: 'put',
            data,
            headers: {
                'admin-email': adminEmail,
            },
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async create() {
        let data = this.getData([
            'name',
            'email',
            'phone',
            'roles',
        ])

        // Erros estão sendo tratados no chamador (caller)
        let response = await this.request({
            url: `/admin`,
            method: 'post',
            data,
        })

        if (!response)
            return

        return response
    }

    async delete() {
        let response = await this.request({
            url: '/admin',
            method: 'delete',
            headers: {
                'admin-email': this.email,
            },  
        })

        if (!response)
            return

        return response
    }

    static async list(additionalConfig = {}) {
        // Erros estão sendo tratados no chamador (caller)
        let response = await this.request({
            url: '/admin/list',
            method: 'get',
            ...additionalConfig,
        })

        if (!response)
            return

        return response.data ? response.data.map(d => new this(d)) : []
    }

    static async get(admin_uid, additionalConfig = {}) {
        // Erros estão sendo tratados no chamador (caller)
        let res = await this.request({
            url: '/admin',
            method: 'get',
            headers: {
                'admin-email': admin_uid,
            },  
            ...additionalConfig,
        })

        if (!res)
            return

        return new this(res.data)
    }

    // Métodos úteis

    // Retira formatação antes de enviar para o servidor
    formatToBack() {
        // [TODO-L][FID-114] internacionalizar
        this.phone && (this.phone = '+55' + this.phone.replace(/\D/g, ''))
    }

    // Adiciona formatação necessária para exibição
    formatToFront() {
        // [TODO-L][FID-114] internacionalizar
        this.phone && (this.phone = this.phone.replace('+55', '').replace(/\D/g, ''))
    }

    /**
     * Procura dentro das permissões deste modelo se ele tem o perfil especificado.
     *
     * @param {(string|array)} role        perfil de permissões
     * @param {(string|array)} company_ids id da empresa em que se deve procurar
     * @returns {boolean}
     */
    hasRole(role = [], company_ids = []) {
        role        = _.castArray(role)
        company_ids = _.castArray(company_ids)

        if (!Array.isArray(role) || !Array.isArray(company_ids))
            throw new TypeError('hasRole(): Expected an array or a string as role argument')

        return Array.isArray(this.roles) &&
            this.roles.some(r => (!role.length || role.includes(r.role)) && (!company_ids.length || company_ids.includes(r.company_id)))
    }

    /**
     * Avalia se este usuário está desabilitado para alterações de permissão
     *
     * @returns {boolean}
     */
    isDisabledForPermissionChange() {
        return this.email == $store.getters['auth/user'].email
    }

    /**
     * Retorna o papel principal deste usuário
     *
     * @return {(object|undefined)} r
     * @return {string} r.company_id id da empresa em que este usuário exerce determinado papel
     * @return {object} r.role       função deste usuário neste escopo
     */
    getMainRole() {
        let mainRoleIndex = this.roles.findIndex(r => r.role != 'dev')
        return this.roles[mainRoleIndex]
    }

    /**
     * Define o papel deste cliente em uma empresa
     *
     * @param {object} obj objeto que descreve o papel de um cliente em uma empresa
     * @param {string} obj.company_id id da empresa em que este usuário exerce determinado papel
     * @param {string} obj.role       função deste usuário neste escopo
     */
    setMainRole(obj = {}) {
        let mainRoleIndex = this.roles.findIndex(r => r.role != 'dev')

        if (!this.roles[mainRoleIndex]) {
            this.roles.push(obj)
            return
        }

        this.roles[mainRoleIndex] = Object.assign(this.roles[mainRoleIndex], obj)
    }
}