import BasePolicy from './BasePolicy'
import $store from '@/store'

export default class GeneratorPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onCustomer, __, company_id) {
        let companySettings = $store.getters['company/settings']
        if (companySettings.is_generation_via_admin_locked)
            return false

        if (authorizable && authorizable.hasRole('admin'))
            return true
    }

    create(authorizable) {
        return authorizable && authorizable.hasRole('operator')
    }

}