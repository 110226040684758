import SelfCompanyTokenIntegration from './SelfCompanyTokenIntegration'
// import CPlugSubscriptionIntegration from './CPlugSubscriptionIntegration'
import $console from '../console'

export default new class IntegrationService {
    constructor() {
        // Registra todas as integrações disponíveis
        this._availableIntegrations = [
            new SelfCompanyTokenIntegration,
            // [TEMP] Desativado até Assinaturas estar em prod
            // new CPlugSubscriptionIntegration,
        ]
    }

    async setup() {
        $console.log('[integr] setting up integrations...')

        // Roda o setup de cada integração
        for (let int of this._availableIntegrations)
            await int.setup()
    }

    /**
     * Retorna todas as integrações ativas no momento
     *
     * @returns {array}
     */
    getActiveIntegrations() {
        return this._availableIntegrations.filter(int => int.isActive())
    }

}