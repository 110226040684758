var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        title: _vm.title,
        subtitle: _vm.subtitle,
        cancelButton: { hide: true },
        confirmButton: {
          label: _vm.$t("modals.AlertModal.confirm"),
          color: _vm.color
        },
        "max-width": "400px",
        persistent: ""
      },
      on: { cancel: _vm.cancel, confirm: _vm.confirm },
      model: {
        value: _vm.isModalOpen,
        callback: function($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen"
      }
    },
    [
      _vm.useHtml
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.text) } })
        : _c(
            "div",
            {
              staticClass:
                "d-flex justify-center align-center text-center mb-4 primary_font--text modal-text"
            },
            [_vm._v(" " + _vm._s(_vm.text) + " ")]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }