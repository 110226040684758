var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CookiesNotification", {
    staticClass: "cookies-notification",
    attrs: { cookies_policy_url: _vm.cookies_policy_url, loading: _vm.loading },
    on: {
      "accept-terms": function($event) {
        return _vm.acceptTerms()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }