var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-subheader", { staticClass: "mb-4 mb-md-0 pl-0 section-subtitle" }, [
        _vm._v(
          _vm._s(
            _vm.$t(
              "integrations." + _vm.integration.key + ".settings.subheader"
            )
          )
        )
      ]),
      _c("p", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$t(
              "integrations." + _vm.integration.key + ".settings.description"
            )
          )
        }
      }),
      _vm.hasError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "integrations." + _vm.integration.key + ".settings.error"
                  )
                ) +
                " "
            )
          ])
        : _vm.loading
        ? _c("v-progress-linear")
        : [
            _vm.hasTokens
              ? _c("v-simple-table", [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "integrations." +
                                _vm.integration.key +
                                ".settings.table.header.jti"
                            )
                          )
                        )
                      ]),
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "integrations." +
                                _vm.integration.key +
                                ".settings.table.header.iat"
                            )
                          )
                        )
                      ]),
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "integrations." +
                                _vm.integration.key +
                                ".settings.table.header.status"
                            )
                          )
                        )
                      ]),
                      _c("th", { staticClass: "text-left" }, [
                        _vm._v(_vm._s(_vm.$t("tables.actions")))
                      ])
                    ])
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.tokens, function(item) {
                      return _c("TokenTableItem", {
                        key: item.name,
                        attrs: { item: item, integration: _vm.integration },
                        on: { update: _vm.fetchTokens }
                      })
                    }),
                    1
                  )
                ])
              : _vm._e(),
            _c(
              "v-btn",
              {
                attrs: {
                  color: _vm.isLightTheme ? "success" : "primary",
                  depressed: "",
                  block: "",
                  outlined: ""
                },
                on: { click: _vm.generateToken }
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "integrations." +
                        _vm.integration.key +
                        ".settings.generate_token"
                    )
                  )
                )
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }