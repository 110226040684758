var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { color: _vm.color, eager: "" },
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [_vm._v(" " + _vm._s(_vm.text) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }