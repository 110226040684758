import BasePolicy from './BasePolicy'
import $store from '@/store'

export default class CalculationRulePolicy extends BasePolicy {

    beforeAll(verb, authorizable, onCalculationRule, __, company_id) {
        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (this.is_parent_company_selected && authorizable && authorizable.hasRole(['admin', 'marketing']))
            return true
    }

    create(authorizable) { return false }

    edit(authorizable, onCalculationRule) { return false }

    view(authorizable, onCalculationRule) { return false }

    list(authorizable, onCalculationRule) { return false }

    delete(authorizable, onCalculationRule) { return false }

}