import BaseMiddleware from './BaseMiddleware'
import store from '@/store'
import _ from 'lodash'

export default class GuestMiddleware extends BaseMiddleware {

    /**
     * Avalia se deve executar este middleware ou não
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve executar este middleware?
     */
    shouldRun(to, from) {
        return to.meta.requiresSelectedCompany
    }

    /**
     * Executa ações sobre esta rota
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve retornar false quando este middleware modifica a rota com next
     */
    handle(to, from, next) {
        let hasSelectedCompany = !_.isEmpty(store.getters['company/selected_company'])

        if (!hasSelectedCompany)
            next({ name: 'select_company', params: { to } })

        return hasSelectedCompany
    }

}