<template>
    <v-app-bar app color="navbar" dark flat height="70px">
        <!-- [TODO] se der problema com a cor primária usar font--text -->
        <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown && isAuthenticated && user && hasSelectedCompany" @click="toggleDrawer" class="ml-1 ml-md-0 primary--text"></v-app-bar-nav-icon>

        <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>

        <v-img v-if="logo_url" @click="titleClick" class="mr-0 ml-0 ml-md-6 pointer" :src="logo_url" max-width="160" max-height="56" contain/>
        <v-toolbar-title @click="titleClick" class="d-flex align-center title clickable">
            <span v-if="$vuetify.breakpoint.mdAndUp" class="ml-3 secondary_font--text program-name">{{ selectedCompany && selectedCompany.name ? selectedCompany.name : $t('navbar.title') }}</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- Botões de autenticação -->
        <template v-if="!isAuthenticated">
            <v-btn text :to="{ name: 'redirects.landing-page' }">
                <span>{{ $t('navbar.welcome') }}</span>
            </v-btn>

            <v-btn v-show="shouldShowLoginButton" :to="{ name: 'login' }" color="success" depressed class="ml-3">
                <span>{{ $t('navbar.login') }}</span>
            </v-btn>
        </template>
        <template v-else>
            <v-icon color="secondary_font" size="20" class="mr-4 mr-md-0 primary-font-color">$cp_bell</v-icon>

            <v-menu v-if="$vuetify.breakpoint.mdAndUp" offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                        <span class="mr-2 secondary_font--text user-name">{{ user ? user.name : '' }}</span>
                        <v-icon small class="ml-2 secondary_font--text">fas fa-angle-down</v-icon>
                    </v-btn>
                    </template>
                <v-list>
                    <v-list-item :to="{ name: 'settings' }">
                        <v-list-item-title>{{ $t('navbar.settings') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'logout' }">
                        <v-list-item-title>{{ $t('navbar.logout') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

        </template>

    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Navbar',
    methods: {
        titleClick() {
            this.$router.push({ name: 'home' })
        },
        toggleDrawer() {
            this.$emit('toggleDrawer')
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/check',
            user           : 'auth/user',
            logo_url       : 'company/logo_url',
            selectedCompany: 'company/selected_company',
        }),
        hasSelectedCompany() {
            return !this.$lodash.isEmpty(this.selectedCompany)
        },
        shouldShowLoginButton() {
            return this.$route.name !== 'login'
        },
    },
}
</script>

<style lang="scss">
.program-name {
    font-size: 18px;
}

.user-name {
    font-size: 14px;
}
</style>