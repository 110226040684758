import SharedCompanySettings from '@/shared/models/CompanySettings'
import constants from '@/services/constants'
import $store from '@/store'
import { required, boolean } from '../utils/validation'

export default class CompanySettings extends SharedCompanySettings {

    defaults() {
        return {
            ...super.defaults(),
            is_generation_via_admin_locked        : false,
            is_consumption_via_admin_locked       : false,
            is_voucher_withdrawal_via_admin_locked: false,
        }
    }

    validation() {
        return {
            ...super.validation(),
            is_generation_via_admin_locked        : required.and(boolean),
            is_consumption_via_admin_locked       : required.and(boolean),
            is_voucher_withdrawal_via_admin_locked: required.and(boolean),
        }
    }

    async update() {
        let company_id = $store.getters['company/id']
        return await this.request({
            url: `/company/${company_id}/settings`,
            method: 'put',
            data: this.getData(),
        })
    }

    static availableLevelsSystems() {
        return [
            constants.getConstant('LOOKBACK_LEVELS_SYSTEM'),
            constants.getConstant('RENOVATION_LEVELS_SYSTEM'),    
        ]
    }
}