<template>
    <BaseModal
        ref="modal"
        v-model="isModalOpen"
        :title="title" @cancel="cancel" @confirm="confirm"
        :subtitle="subtitle"
        :cancelButton="{ hide: true }"
        :confirmButton="{
            label: $t('modals.AlertModal.confirm'),
            color,
        }"
        max-width="400px"
        persistent
    >
        <span v-if="useHtml" v-html="text"></span>
        <div v-else class="d-flex justify-center align-center text-center mb-4 primary_font--text modal-text">
            {{ text }}
        </div>
    </BaseModal>
</template>

<script>
import BaseModal      from '@/components/modals/BaseModal'
import BaseModalMixin from '@/components/modals/BaseModalMixin'

export default {
    name: 'AlertModal',
    components: {
        BaseModal
    },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        title    : '',
        subtitle : '',
        useHtml  : false,
        text     : '',
        color    : '',
        isSuccess: null,
    }),
    methods: {
        showSuccess(text, useHtml = false) {
            this.title     = this.$t('modals.AlertModal.success.title')
            this.subtitle  = this.$t('modals.AlertModal.success.subtitle')
            this.useHtml   = useHtml
            this.text      = text
            this.color     = 'success'
            this.isSuccess = true
            return this.open()
        },
        showError(text, useHtml = false) {
            this.title     = this.$t('modals.AlertModal.error.title')
            this.subtitle  = this.$t('modals.AlertModal.error.subtitle')
            this.useHtml   = useHtml
            this.text      = text
            this.color     = 'error'
            this.isSuccess = false
            return this.open()
        },
        beforeClear() {
            this.$emit('closed')
            this.title     = ''
            this.subtitle  = ''
            this.useHtml   = false
            this.text      = ''
            this.color     = ''
            this.isSuccess = null
            return true
        },
    }
}
</script>