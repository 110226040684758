var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "mask",
        rawName: "v-mask",
        value: _vm.config,
        expression: "config"
      }
    ],
    attrs: { type: "text" },
    domProps: { value: _vm.display },
    on: { input: _vm.onInput }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }