import BasePersistence from './BasePersistence'
import moment          from 'moment'
import _               from 'lodash'

/**
 * Implementa a persistência na localStorage.
 */
export default class Local extends BasePersistence {

    get length() {
        let count = 0
        Object.values(localStorage).forEach(response => {
            try {
                let item = JSON.parse(response)
                if (!item.expires_at || moment().isBefore(item.expires_at))
                    count++
            } catch (e) {
                // Se não foi possível fazer o parse, não tem data de expiração.
                count++
            }
        })

        return count
    }

    /**
     * Retorna a chave com o índice informado.
     *
     * @param {integer} index Índice da chave
     * @returns {string}
     */
    key(index) {
        return localStorage.key(index)
    }

    /**
     * Retorna o item com a chave informada.
     * Se o item já expirou, não serão retornado e seu registro será apagado da localStorage.
     *
     * @param {string} key Chave do item
     * @returns {string}
     */
    getItem(key) {
        let response = localStorage.getItem(key)
        if (!response)
            return
        
        try {
            let item = JSON.parse(response)
            if (item.expires_at && moment().isAfter(item.expires_at)) {
                localStorage.removeItem(key)
                return
            }
            return item.value
        } catch (e) {
            // Se não foi possível fazer o parse, não tem data de expiração.
            return response
        }
    }

    /**
     * Cria ou atualiza, com o valor recebido, o item que possui a chave informada. 
     * O item deixará de ser acessível após a quantidade de dias informada em daysToExpire.
     *
     * @param {string} key          Chave do item a ser criado ou atualizado
     * @param {string} value        Valor a ser atribuído ao item
     * @param {string} daysToExpire Quantidade de dias em que o item deve expirar
     */
    setItem(key, value, daysToExpire) {
        let item = { value }
        
        if (!_.isUndefined(daysToExpire)) {
            if (!_.isInteger(daysToExpire))
                throw new TypeError(`Expected daysToExpire to be an integer number. Received: ${daysToExpire}`)

            item.expires_at = moment().add(daysToExpire, 'days').valueOf()
        }

        localStorage.setItem(key, JSON.stringify(item))
    }

    /**
     * Remove o item com a chave informada.
     *
     * @param {string} key Chave do item a ser deletado.
     */
    removeItem(key) {
        localStorage.removeItem(key)
    }

    /**
     * Remove todos os itens existentes.
     */
    clear() {
        localStorage.clear()
    }

}