import BaseModel from './BaseModel'

export default class Company extends BaseModel {

    defaults() {
        return {
            id           : '',
            name         : '', 
            document     : '',
            levels       : '',
            parent_id    : '',
            json         : '',
            integrator_id: '',
            subsidiaries : [],
        }
    }
}