import BasePolicy from './BasePolicy'
import $store     from '@/store'

export default class PromotionPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onType, __, company_id) {
        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (this.is_parent_company_selected && authorizable && authorizable.hasRole(['admin', 'marketing']))
            return true
    }

    create(authorizable) { return false }

    edit(authorizable, onType) { return false }

    view(authorizable, onType) { return false }

    list(authorizable, onType) { return false }

    delete(authorizable, onType) { return false }

}