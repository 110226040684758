import Vue from 'vue'

/**
 * Este arquivo importa todos os filtros no diretorio
 */

const files = require.context('.', false, /\.js$/)
const filters = {}

files.keys().forEach(key => {
    // Ignora este proprio arquivo
    if (key === './index.js') return

    let Rkey = key.replace(/(\.\/|\.js)/g, '')
    let value = files(key).default

    filters[Rkey] = value

    // Registra cada um dos filtros no vue
    Vue.filter(Rkey, value)
})

export default filters
