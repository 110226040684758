export default {
    // Descrição completa do erro e do paleativo
    generic      : 'Ocorreu um erro ao finalizar a sua requisição. Nossa equipe já foi informada. Tente novamente mais tarde.',
    network_error: 'Há um problema na conexão. Verifique a sua conexão e tente novamente.',
    timeout      : 'Hmm... Isso está demorando mais que o normal. Verifique a sua conexão e tente novamente.',

    http: { 
        400: '@:errors.generic',
        401: 'A sua sessão expirou, entre em sua conta novamente.',
        403: 'Ops.. Você não tem permissão para acessar esse conteúdo.',
        408: 'O servidor demorou demais para responder. Tente novamente mais tarde.',
        422: 'Há um erro de validação no formulário. Verifique os dados e tente novamente.',
        429: 'Ocorreram muitas requisições, aguarde um momento e tente novamente.',
        500: '@:errors.generic',
        501: 'Esta funcionalidade ainda não está implementada.',
        502: '@:errors.generic',
        509: '@:errors.generic',
    },

    // Versões enxutas dos erros acima para layouts compactos
    simple: {
        generic      : 'Ocorreu um erro.',
        generic_title: 'Erro.',
        network_error: 'Há um problema na conexão.',
        timeout      : 'Conexão lenta.',
    
        http: { 
            400: '@:errors.simple.generic',
            401: 'Não autenticado.',
            403: 'Não autorizado.',
            408: 'Timeout.',
            422: 'Erro de validação.',
            429: 'Muitas requisições.',
            500: '@:errors.simple.generic',
            501: 'Não implementado.',
            502: '@:errors.simple.generic',
            509: '@:errors.simple.generic',
        },
    },
}
