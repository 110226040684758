export default {
    loading            : 'Carregando...',
    points             : 'pontos',
    points_short       : 'pts',
    amount_points      : '{amount} pontos',
    date               : 'Data',
    hour               : 'Hora',
    value              : 'Valor',
    received           : 'Recebido',
    expended           : 'Gasto',
    reversed           : 'Estornado',
    expires            : 'Expira em',
    amount_points_short: '{n}pt | {n}pt | {n}pts',
    currency           : 'R$ {value}',
    network_error      : 'Falha na conexão. Verifique sua rede e tente novamente.',
    years              : 'anos',
    reports            : 'Relatórios',
    powered_by_cplug   : 'Desenvolvido por ConnectPlug',
    coming_soon        : 'Em breve',
    active             : 'Ativo',
    inactive           : 'Inativo',
    // network_error: 'Não foi possível conectar ao servidor'
}