/*eslint-disable no-console */

import _ from 'lodash'

let $console     = {}
let forceConsole = !!localStorage.getItem('dev_force_console')

if (forceConsole || !['production', 'staging'].includes(process.env.NODE_ENV)) {
    $console = {
        log     : console.log,
        info    : console.info,
        warn    : console.warn,
        error   : console.error,
        time    : console.time,
        timeEnd : console.timeEnd,
        group   : console.group,
        groupEnd: console.groupEnd,
        trace   : console.trace,
        count   : console.count,
    }
} else {
    let nullFunction = _.once(() => null)
    $console = {
        log     : nullFunction,
        info    : nullFunction,
        warn    : nullFunction,
        error   : nullFunction,
        time    : nullFunction,
        timeEnd : nullFunction,
        group   : nullFunction,
        groupEnd: nullFunction,
        trace   : nullFunction,
        count   : nullFunction,
    }
}

/*eslint-enable no-console */

export default $console