export default [
    {
        path: '/niveis',
        name: 'levels',
        redirect: 'niveis/lista'
    },
    {
        path: '/niveis/lista',
        name: 'levels.list',
        component: () => import(/* webpackChunkName: "levels.list" */ '@/views/levels/List.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'levels.list',
            can: 'list Level',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Níveis',
                    disabled: true
                }
            ]
        },
    },
    {
        path: '/niveis/:levelId/beneficios',
        name: 'levels.benefits',
        component: () => import(/* webpackChunkName: "levels.benefits" */ '@/views/levels/benefits/List.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'levels.benefits',
            can: 'list Benefit',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Níveis',
                    to: { name: 'levels.list' }
                },
                {
                    text: 'Benefícios',
                    disabled: true
                }
            ],
        },
    },
    {
        path: '/levels/:levelId/prize',
        name: 'levels.prize',
        component: () => import(/* webpackChunkName: "levels.prize" */ '@/views/levels/prize/Details.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'levels.prize',
            can: 'edit Level',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Níveis',
                    to: { name: 'levels.list' }
                },
                {
                    text: 'Prêmio',
                    disabled: true
                },
            ],
        },
    },
]