export default {
    name: 'generate',
    path: '/gerar-pontos/:id?',
    component: () => import(/* webpackChunkName: "generate" */ '@/views/generate/GenerateView.vue'),
    props: true,
    meta: {
        requiresAuthentication: true,
        requiresSelectedCompany: true,
        title: 'generate',
        can: 'create Generator',
        fail: '/painel',
        breadcrumbs: [
            {
                text: 'Dashboard',
                to: { name: 'home' }
            },
            {
                text: 'Gerar pontos',
                disabled: true
            }
        ]
    },
}