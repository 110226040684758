<template>
    <BaseModal ref="modal"
        v-model="isModalOpen"
        @confirm="confirm"
        @cancel="cancel"
        max-width="330px"
        persistent
    >
        <div class="d-flex flex-column align-center">
            <v-icon large class="secondary_font--text my-5">fas fa-fw fa-exclamation-circle</v-icon>
            <span v-if="useHtml" v-html="text"></span>
            <div v-else class="d-flex flex-column align-center">
                <span class="text-center primary--text main-text">{{ text }}</span>
                <span class="mt-1">{{ $t('modals.ConfirmationModal.subtitle') }}</span>
            </div>
        </div>

        <template #actions>
            <div class="my-4 d-flex justify-center">
                <v-btn @click="handleCancel" width="86px" class="mr-2 background elevation-0">{{ $t('modals.ConfirmationModal.cancel') }}</v-btn>
                <v-btn @click="handleConfirm" width="86px" class="ml-2 primary elevation-0">{{ $t('modals.ConfirmationModal.confirm') }}</v-btn>
            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModalMixin from '@/components/modals/BaseModalMixin'
import BaseModal      from '@/components/modals/BaseModal'

export default {
    components: {
        BaseModal
    },
    mixins: [ BaseModalMixin ],
    data: vm => ({
        subtitle: vm.$t('modals.ConfirmationModal.subtitle'),
        useHtml : false,
        text    : '',
    }),
    methods: {
        handleCancel() {
            this.$refs.modal.handleCancel()
        },

        handleConfirm() {
            this.$refs.modal.handleConfirm()
        },

        async beforeConfirm() {
            this.resolveValue = true
            return true
        },

        async showConfirmation(text, useHtml = false) {
            this.useHtml = useHtml
            this.text    = text

            return await this.open()
        },
    },
}
</script>

<style scoped lang="scss">
.main-text {
    font-size: 16px;
    font-weight: bold;
}
</style>