export default {
    dashboard   : 'Dashboard',
    company     : 'Matriz',
    parent      : 'Minha empresa',
    subsidiaries: 'Filiais',
    title       : 'CPlug Fidelidade',
    subtitle    : 'Painel administrador',

    home            : 'Home',
    find_customer   : 'Buscar cliente',
    levels          : 'Níveis',
    customers       : 'Clientes',
    types           : 'Regras de cálculo',
    generate        : 'Gerar pontos',
    consumption     : 'Gerar consumos',
    settings        : 'Configurações',
    history         : 'Histórico',
    prizes          : 'Prêmios',
    promotions      : 'Promoções',
    withdraw_voucher: 'Retirar voucher',
    select_company  : 'Selecionar empresa',
    import          : '@:actions.import',
    reports         : '@:globals.reports',
    welcome         : 'Informações',
    logout          : 'Sair',
    logs            : 'Logs',
}