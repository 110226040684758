export default {
    name: 'reports',
    path: '/reports',
    redirect: '/reports/list',
    component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/Base.vue'),
    children: [
        {
            name: 'reports.list',
            path: 'list',
            component: () => import(/* webpackChunkName: "reports" */ '@/views/reports/List.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' }
                    },
                    {
                        text: 'Relatórios',
                        disabled: true
                    },
                ],
            },
        },
        {
            name: 'reports.customer.created_at',
            path: 'customer/created_at',
            component: () => import(/* webpackChunkName: "reports.customer.created_at" */ '@/views/reports/customer/CustomerCreatedAtReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Clientes',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Novos cadastros',
                        disabled: true,
                    },
                ],
            },
        },
        {
            name: 'reports.vouchers',
            path: 'vouchers',
            component: () => import(/* webpackChunkName: "reports.vouchers" */ '@/views/reports/voucher/VouchersReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Vouchers',
                        to: { name: 'reports.list' },
                        disabled: true,
                    },
                ],
            },
        },
        {
            name: 'reports.generators',
            path: 'generators',
            component: () => import(/* webpackChunkName: "reports.generators" */ '@/views/reports/generator/GeneratorCreatedAtReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Gerações',
                        to: { name: 'reports.list' },
                        disabled: true,
                    },
                ],
            },
        },
        {
            name: 'reports.consumptions',
            path: 'consumptions',
            component: () => import(/* webpackChunkName: "reports.consumptions" */ '@/views/reports/consumption/ConsumptionCreatedAtReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Consumos',
                        to: { name: 'reports.list' },
                        disabled: true,
                    },
                ],
            },
        },
        {
            name: 'reports.interactions',
            path: 'interactions',
            component: () => import(/* webpackChunkName: "reports.interactions" */ '@/views/reports/interactions/InteractionsReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Interações',
                        to: { name: 'reports.list' },
                        disabled: true,
                    },
                ],
            },
        },
        {
            name: 'reports.frequencies',
            path: 'frequencies',
            component: () => import(/* webpackChunkName: "reports.frequencies" */ '@/views/reports/frequency/FrequenciesReport.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Relatórios',
                        to: { name: 'reports.list' },
                    },
                    {
                        text: 'Frequência',
                        to: { name: 'reports.list' },
                        disabled: true,
                    },
                ],
            },
        },
    ],
}