var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selected_company_id
    ? _c(
        "v-navigation-drawer",
        {
          attrs: {
            color: "sections",
            "mini-variant": _vm.$vuetify.breakpoint.mdAndUp && _vm.mini,
            value: _vm.value,
            permanent: _vm.$vuetify.breakpoint.mdAndUp,
            app: "",
            "disable-resize-watcher": ""
          },
          on: { input: _vm.onInput }
        },
        [
          _c(
            "v-list-item",
            { class: _vm.mini ? "px-0" : "" },
            [
              _c(
                "v-list-item-content",
                [
                  _c(
                    "v-row",
                    [
                      _vm.$vuetify.breakpoint.smAndDown || !_vm.mini
                        ? _c(
                            "v-col",
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                "v-list-item-title",
                                { staticClass: "title" },
                                [_vm._v(" " + _vm._s(_vm.program_name) + " ")]
                              ),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("drawer.subtitle")) + " "
                                )
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$vuetify.breakpoint.mdAndUp && !_vm.mini
                        ? _c(
                            "v-col",
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass:
                                    "px-2 py-1 mt-2 mr-1 close-drawer-icon",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.mini = true
                                    }
                                  }
                                },
                                [_vm._v(" fas fa-angle-left ")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$vuetify.breakpoint.mdAndUp && _vm.mini
                        ? _c(
                            "v-col",
                            { staticClass: "pt-2 pb-5", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: { icon: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.mini = false
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "primary" } },
                                    [_vm._v("$cp_bars")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-list",
            { staticClass: "drawer-list", attrs: { dense: "", nav: "" } },
            [
              _vm.isAuthenticated
                ? _vm._l(_vm.items, function(item) {
                    return _c(
                      "v-list-item",
                      {
                        key: item.title,
                        attrs: {
                          link: "",
                          to: item.to,
                          disabled: item.disabled
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "list-icon",
                            attrs: { color: "primary" }
                          },
                          [_vm._v(_vm._s(item.icon))]
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", { staticClass: "pl-4" }, [
                              _vm._v(_vm._s(item.title))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : [
                    _c(
                      "v-list-item",
                      { attrs: { link: "", to: { name: "login" } } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "list-icon",
                            attrs: { color: "primary" }
                          },
                          [_vm._v("$cp_login")]
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", { staticClass: "pl-4" }, [
                              _vm._v(_vm._s(_vm.$t("actions.login")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      { attrs: { link: "" }, on: { click: _vm.welcome } },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "list-icon",
                            attrs: { color: "primary" }
                          },
                          [_vm._v("$cp_info")]
                        ),
                        _c(
                          "v-list-item-content",
                          [
                            _c("v-list-item-title", { staticClass: "pl-4" }, [
                              _vm._v(_vm._s(_vm.$t("drawer.welcome")))
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
            ],
            2
          ),
          !_vm.isAuthenticated
            ? _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-subtitle", [
                        _c("p", [_vm._v(_vm._s(_vm.$t("drawer.auth_needed")))])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }