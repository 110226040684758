/**
 * Esta classe abstrata é a generalização de todos os drivers de persistência disponíveis.
 */
 export default class BasePersistence {

    get length() {
        // Deve ser implementado nos filhos
        return undefined
    }

    /**
     * Retorna a chave com o índice informado.
     *
     * @param {integer} index Índice da chave
     * @returns {string}
     */
    key(index) {
        // Deve ser implementado nos filhos
    }

    /**
     * Retorna o item com a chave informada.
     *
     * @param {string} key Chave do item
     * @returns {string}
     */
    getItem(key) {
        // Deve ser implementado nos filhos
    }

    /**
     * Cria ou atualiza, com o valor recebido, o item que possui a chave informada.
     *
     * @param {string} key   Chave do item a ser criado ou atualizado
     * @param {string} value Valor a ser atribuído ao item
     */
    setItem(key, value) {
        // Deve ser implementado nos filhos
    }

    /**
     * Remove o item com a chave informada.
     *
     * @param {string} key Chave do item a ser removido
     */
    removeItem(key) {
        // Deve ser implementado nos filhos
    }

    /**
     * Remove todos os itens existentes.
     */
    clear() {
        // Deve ser implementado nos filhos
    }

}