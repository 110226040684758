import Type          from '@/models/Type'
import $constants    from '@/services/constants'

const state = {
    data: [],
}

const getters = {
    data: state => state.data,
}

const mutations = {
    SET_DATA: (state, data) => {
        state.data = data
    },
}

const actions = {
    async fetch({ state, commit }) {
        // Pega o Type da API
        let res = await Type.list({ per_page: $constants.getConstant('PAGINATION_MAX_PER_PAGE') })

        if (!res)
            return

        let hydratedData = []
        for (let item of res.data)
            hydratedData.push(new Type(item))

        commit('SET_DATA', hydratedData)
    },
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}