import SharedVoucher from '@/shared/models/Voucher'
import { required, string, cpf, cnpj, length } from '@/utils/validation'
import $store from '@/store'
import _ from 'lodash'
import i18n from '@/i18n'
import constants from '@/services/constants'

export default class Voucher extends SharedVoucher {

    validation() {
        return {
            customer_document: required.and(string).and(this.useCNPJ ? cnpj : cpf),
            code: required.and(string).and(length(6, 191)),
        }
    }

    static async getByCodeAndDocument(code, customerDocument) {
        let response = await this.request({
            url: `/voucher/get-voucher-by-code/${code}`,
            method: 'get',
            headers: {
                'customer-document': customerDocument,
            },
        })

        if (!response)
            return

        return new this(response.data)
    }

    async withdraw() {
        return await this.request({
            url   : '/voucher/withdraw',
            method: 'post',
            data: {
                code             : String(this.code),
                customer_document: this.customer_document,
                // Pega a company selecionada e coloca como lugar de retirada
                withdrawal_company: $store.getters['company/selected_company_id'],
                parent_id         : $store.getters['company/id'],
            },
        })
    }

    get is_purchased() {
        return _.get(this, 'consumption.value') > 0
    }

    get created_by_text() {
        let type = _.get(this, 'created_by.type')
        const definedTypes = [
            constants.getConstant('VOUCHER_CREATED_BY_PURCHASE'),
            constants.getConstant('VOUCHER_CREATED_BY_LEVEL_UP'),
        ]
        type = definedTypes.includes(type) ? type : 'default'
        return i18n.t(`models.Voucher.type.${type}`)
    }

    get prize_name() {
        return _.get(this, 'prize.name')
    }

    get withdrawal_company_name() {
        return _.get(this, 'withdrawal_company.name')
    }
}