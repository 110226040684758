/**
 * Este arquivo importa todos os arquivos de tradução deste diretório
 */

const files = require.context('.', false, /\.js$/)
const langFiles = {}

files.keys().forEach(key => {
    // Ignora este proprio arquivo
    if (key === './index.js') return

    langFiles[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})

export default langFiles
