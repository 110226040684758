<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20.087" viewBox="0 0 24 20.087"><g class="a" transform="translate(-0.5 -3.413)"><path class="b" d="M17.5,28.5v-2a4,4,0,0,0-4-4h-8a4,4,0,0,0-4,4v2" transform="translate(0 -6)"/><path class="b" d="M15.5,8.5a4,4,0,1,1-4-4,4,4,0,0,1,4,4Z" transform="translate(-2 0)"/><path class="b" d="M33,28.565v-2a4,4,0,0,0-3-3.87" transform="translate(-9.5 -6.065)"/><path class="b" d="M24,4.7a4,4,0,0,1,0,7.75" transform="translate(-7.5 -0.065)"/></g></svg>
</template>

<script>
export default {
    name: 'UsersIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>

