<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"><g class="a" transform="translate(-0.5 -0.5)"><path class="b" d="M18.682,16.091A2.591,2.591,0,1,1,16.091,13.5,2.591,2.591,0,0,1,18.682,16.091Z" transform="translate(-5.091 -5.091)"/><path class="b" d="M17.391,13.591a1.425,1.425,0,0,0,.285,1.572l.052.052a1.728,1.728,0,1,1-2.444,2.444l-.052-.052A1.437,1.437,0,0,0,12.8,18.626v.147a1.727,1.727,0,0,1-3.455,0v-.078a1.425,1.425,0,0,0-.933-1.3,1.425,1.425,0,0,0-1.572.285l-.052.052a1.728,1.728,0,1,1-2.444-2.444l.052-.052A1.437,1.437,0,0,0,3.374,12.8H3.227a1.727,1.727,0,0,1,0-3.455h.078a1.425,1.425,0,0,0,1.3-.933,1.425,1.425,0,0,0-.285-1.572l-.052-.052A1.728,1.728,0,1,1,6.716,4.341l.052.052a1.425,1.425,0,0,0,1.572.285h.069a1.425,1.425,0,0,0,.864-1.3V3.227a1.727,1.727,0,0,1,3.455,0v.078a1.437,1.437,0,0,0,2.435,1.019l.052-.052a1.728,1.728,0,1,1,2.444,2.444l-.052.052a1.425,1.425,0,0,0-.285,1.572v.069a1.425,1.425,0,0,0,1.3.864h.147a1.727,1.727,0,0,1,0,3.455H18.7a1.425,1.425,0,0,0-1.3.864Z" transform="translate(0 0)"/></g></svg>
</template>

<script>
export default {
    name: 'SettingsIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>