<template>
    <CookiesNotification
        class="cookies-notification"
        @accept-terms="acceptTerms()"
        :cookies_policy_url="cookies_policy_url"
        :loading="loading"
    />
</template>

<script>
import CookiesNotification  from '@/shared/components/banner/CookiesNotification'
import CookiesAgreement     from '@/models/CookiesAgreement'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import { mapGetters }       from 'vuex'

export default {
    name: 'CookiesNotificationBanner',
    components: { CookiesNotification },
    mixins: [ HasErrorHandlerMixin ],
    data: vm => ({
        // Para clientes do painel admin isso é estático
        cookies_policy_url: vm.$constants.getConstant('COOKIE_POLICY_URL'),
        loading: false,
    }),
    methods: {
        async acceptTerms() {
            const admin_email         = this.$lodash.get(this.user, 'email')
            const cookiesAgreement    = new CookiesAgreement({ user_id: admin_email })
            const cookiesExpiresYears = this.isUserLoggedIn ? this.$constants.getConstant('COOKIE_EXPIRES_IN_YEARS_AUTHENTICATED') : this.$constants.getConstant('COOKIE_EXPIRES_IN_YEARS_GUEST')

            this.loading = true

            // Guarda o registro de cookies aceitos na camada de persistência
            cookiesAgreement.setOnPersistence(true, cookiesExpiresYears)

            if (this.isUserLoggedIn) {
                let response = await cookiesAgreement.create()
                    .catch(this.preErrorHandler(cookiesAgreement))
                    .finally(() => {
                        this.loading = false
                    })
    
                if (!response)
                    return
            }

            this.loading = false

            this.$bus.$emit('message', this.$t('messages.success'), 'success')
            this.$emit('cookies-agreement')
        },
        preErrorHandler(cookiesAgreement) {
            return async (e) => {
                // Em caso de erro, remove o registro da camada de persistência
                cookiesAgreement.deleteFromPersistence()

                let errorType = this.$lodash.get(e, 'response.data.code')

                const definedErrors = [
                    'agreement-already-exists',
                ]

                errorType = errorType && definedErrors.includes(errorType) ? errorType : 'generic'

                // Se já aceitou a política armazena o registro na camada de persistência e não mostra mais a mensagem
                if (errorType == 'agreement-already-exists') {
                    const cookiesExpiresYears = this.isUserLoggedIn ? 3 : 1
                    cookiesAgreement.setOnPersistence(true, cookiesExpiresYears)
                    this.$bus.$emit('message', this.$t('messages.success'), 'success')
                    this.$emit('cookies-agreement')
                    return
                }
    
                await new Promise((resolve) => {
                    this.$bus.$emit('alert', this.$t(`banners.CookiesNotification.errors.${errorType}`), 'error', resolve)
                })
    
                this.errorHandler(e)
            }
        },
    },
    computed: {
        ...mapGetters({
            company_id    : 'company/id',
            user          : 'auth/user',
            isUserLoggedIn: 'auth/check',
            isLoaded      : 'app/isLoaded'
        }),
    }
}
</script>

<style lang="scss" scoped>
.cookies-notification {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>