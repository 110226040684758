import BasePolicy from './BasePolicy'
import $store from '@/store'

export default class LogEntryPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onImportEntry, __, company_id) {
        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (this.is_parent_company_selected && authorizable.hasRole('admin'))
            return true
    }

    view(authorizable, onImportEntry) {
        return false
    }

}