var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    attrs: { label: _vm.attrs.label, disabled: _vm.attrs.disabled },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function() {
          return [
            _c(
              "v-textarea",
              _vm._g(
                _vm._b(
                  {
                    ref: "input",
                    attrs: { solo: "", flat: "", value: _vm.computedValue },
                    on: { input: _vm.onInput }
                  },
                  "v-textarea",
                  _vm.attrs,
                  false
                ),
                _vm.listeners
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }