import store from '@/store'
import BaseMiddleware from './BaseMiddleware'
import i18n from '@/i18n'

export default class UpdateTitleMiddleware extends BaseMiddleware {

    handle(to, from, next) {
        if (!to.meta)
            return true

        let program_name = i18n.t('navigation.program_name')
        // let program_name = store.getters['company/program_name'] || i18n.t('navigation.generic.default_program_name')

        if (!to.meta.title) {
            document.title = i18n.t('navigation.generic.title', { program_name })
            document.meta  = i18n.t('navigation.generic.meta',  { program_name })
        } else {
            document.title = i18n.t(`navigation.${to.meta.title}.title`, { program_name })
            document.meta  = i18n.t(`navigation.${to.meta.title}.meta`,  { program_name })
        }
        
        return true
    }
}