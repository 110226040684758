<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.103" height="13.204" viewBox="0 0 14.103 13.204"><g transform="translate(0 0)"><path class="b" d="M13.221,19.822H10.433a3.424,3.424,0,0,0,.492-.394,1.844,1.844,0,0,0,0-2.593,1.863,1.863,0,0,0-2.585,0,4.851,4.851,0,0,0-1.256,2.987H7.02a4.856,4.856,0,0,0-1.256-2.987,1.863,1.863,0,0,0-2.585,0,1.845,1.845,0,0,0,0,2.593,3.488,3.488,0,0,0,.493.394H.881A.883.883,0,0,0,0,20.7v2.2a.441.441,0,0,0,.441.441H.881v5.289a.883.883,0,0,0,.881.881H12.34a.883.883,0,0,0,.881-.881V23.348h.441a.441.441,0,0,0,.441-.441V20.7A.883.883,0,0,0,13.221,19.822ZM3.8,17.457a.94.94,0,0,1,1.335,0c.594.6,1.185,2.124.979,2.337a.291.291,0,0,1-.163.029A4.334,4.334,0,0,1,3.8,18.807.961.961,0,0,1,3.8,17.457Zm2.807,11.18H1.763V23.348H6.611Zm0-6.17H.881V20.7H6.611Zm2.353-5.01a.95.95,0,1,1,1.335,1.35,4.334,4.334,0,0,1-2.152,1.015.29.29,0,0,1-.164-.029C7.78,19.581,8.37,18.054,8.964,17.457Zm3.376,11.18H7.492V23.348H12.34Zm.881-6.17H7.492V20.7h5.729Z" transform="translate(0 -16.314)"/></g></svg>
</template>

<script>
export default {
    name: 'GiftIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: currentColor;
}
</style>