<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="21.33" height="21.146" viewBox="0 0 21.33 21.146"><g class="a" transform="translate(-2 -1.818)"><path class="b" d="M11.564,6H4.9A1.9,1.9,0,0,0,3,7.9V21.225a1.9,1.9,0,0,0,1.9,1.9H18.225a1.9,1.9,0,0,0,1.9-1.9V14.564" transform="translate(0 -1.163)"/><path class="b" d="M21.991,3.409a2.019,2.019,0,0,1,2.855,2.855l-9.04,9.04L12,16.255l.952-3.806Z" transform="translate(-3.291 0)"/></g></svg>
</template>

<script>
export default {
    name: 'EditIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>

