<template>
    <div>
        <!-- Calendário / Date picker -->
        <v-menu
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            v-model="calendar.open"
            offset-y
            bottom right
        >

            <template #activator="{ on }">
                <!-- Input textual -->
                <cp-text-field
                    v-bind="attrs" v-on="listeners"
                    v-model="computedTextInput"
                    return-masked-value
                    v-mask="mask"
                    :error-messages="computedErrorMessages"
                    @blur="onBlur"
                    @focus="onFocus"
                    :required="required"
                    :rules="rules"
                >

                    <!-- Ícone para abrir o calendário -->
                    <template #append>
                        <v-icon class="clickable" v-on="on" small>fas fa-fw fa-calendar</v-icon>
                    </template>

                </cp-text-field>
            </template>

            <!-- Calendário -->
            <v-date-picker ref="picker"
                no-title :locale="$locale.get()"
                v-model="computedCalendarInput"
                :rules="rules" :reactive="reactive"
                :required="required"
            />
        </v-menu>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import Moment from 'moment'

/**
 * Obs. estou deixando os console.log neste arquivo pois talvez ele precise de melhorias.
 */

export default {
    name: 'DateInput',
    props: {
        value: [Moment, String, null],

        // Indica se deve emitir o valor da data assim que for digitada uma data válida
        reactive: Boolean,

        // Indica se o campo é obrigatório
        required: Boolean,

        // Indica se o componente deve aceitar e emitir valores em UTC
        utc: Boolean,

        // Mensagens de erro que vem da api ou do componente pai
        errorMessages: {
            type: Array,
            default: () => [],
        },

        // Especifica um formato de entrada e saída, caso não se deseje utilizar o moment (não recomendado)
        outputFormat: {
            type: String,
            default: () => null,
        },
    },
    directives: { mask },
    data: vm => ({
        rules             : [],
        mask              : vm.$t('formats.date'),
        localErrorMessages: [],
        maskedValue       : '',

        // Calendário
        calendar: {
            open: false,
        },

        momentFormat: null,
        calendarFormat: 'YYYY-MM-DD',
    }),
    created() {
        // Deinfe o formato de exibição de acordo com o browser
        this.momentFormat = this.$moment()._locale._longDateFormat.L
        // console.log('created() this.momentFormat', this.momentFormat)

        // Se o input já tem um valor, então roda o computed
        if (this.value) {
            // console.log('created() this.value', this.value)
            let value2 = this.$moment(this.value, this.outputFormat)
            this.maskedValue = this.$moment(value2).format(this.momentFormat)
            // console.log('this.maskedValue', this.maskedValue)
        }
    },
    methods: {
        input(value) {
            if (!this.$moment.isMoment(value) && value !== null)
                throw TypeError('Expected to output a moment instance or null')

            if (value && this.utc)
                value.utc()

            if (value && this.outputFormat)
                value = value.format(this.outputFormat)

            this.$emit('input', value)
        },
        onFocus(evt) {
            this.$emit('focus', evt)
        },

        onBlur(evt) {
            this.validate()
            this.$emit('blur', evt)
        },

        clearErrorMessages() {
            this.localErrorMessages = []
        },
        clear() {
            // Obs. Como o input só é emitido quando há uma data válida, se ela é inválida o input fica sendo ''
            // desta forma, ele não limpa mesmo setando o valor como ''. Precisamos que o input seja emitido com algum
            // valor para o vue reagir.

            // Coloca uma data válida aleatória
            this.input(this.$moment().format(this.momentFormat))
            this.$nextTick(() => {
                // Limpa no próximo tick
                this.input(null)
            })

            this.clearErrorMessages()
        },
        setInvalidDateError(type) {
            // console.log('setInvalidDateError', type)
            // Mapping para os arquivos de tradução
            const map = {
                required: this.$t('validation.required'),
                format  : this.$t('validation.invalid_format'),
            }

            // Mapping ou msg padrão
            let message = map[type] || this.$t('validation.invalid_date')
            this.localErrorMessages = [message]
        },

        validate() {
            let momentDate = this.$moment(this.maskedValue, this.momentFormat)

            // console.log('this.momentFormat', this.momentFormat)
            // console.log('this.maskedValue', this.maskedValue)
            // console.log('momentDate', momentDate)

            // Verifica se o campo está vazio
            if (!this.maskedValue) {
                if (this.required) {
                    this.setInvalidDateError('required')
                }

                return
            }

            // Verifica se a data está incompleta ou no formato errado
            if (momentDate.format(this.momentFormat) != this.maskedValue) {
                this.setInvalidDateError('format')
                return
            }

            // Veririca se a data é válida
            if (!momentDate.isValid()) {
                this.setInvalidDateError()
                return
            }
        },

        openCalendar() {
            this.calendar.open = true
        },
        closeCalendar() {
            this.calendar.open = false
        },
    },
    computed: {
        computedCalendarInput: {
            get() {
                // console.log('calendar get this.value', this.value)
                if (!this.value)
                    return ''

                // Cria uma instância do moment
                let momentDate = this.$moment(this.value, this.outputFormat)
                // console.log('calendar get momentDate', momentDate.format())

                // Formata de acordo com o locale
                return momentDate.format(this.calendarFormat)
            },
            set(value) {
                // console.log('calendar set value', value)
                this.closeCalendar()
                let momentDate = this.$moment(value, this.calendarFormat)
                // console.log('calendar set momentDate', momentDate.format())
                this.input(momentDate)
            },
        },
        computedTextInput: {
            get() {
                // console.log('text get this.value', this.value)
                if (!this.value)
                    return ''

                this.clearErrorMessages()

                // Assumindo que o valor esteja no formato YYYY-MM-DD

                // Cria uma instância do moment
                let momentDate = this.$moment(this.value, this.outputFormat)
                // console.log('text get momentDate', momentDate.format())

                // Formata de acordo com o locale
                return momentDate.format(this.momentFormat)
            },
            set(value) {
                this.clearErrorMessages()

                // console.log('text set value', value)

                if (!value) {
                    this.input(null)
                    return
                }

                let momentDate = this.$moment(value, this.momentFormat)
                // console.log('text set momentDate', momentDate.format())

                // Guarda o último valor com mask para realizar as comparações
                this.maskedValue = value
                // console.log('set this.maskedValue to', value)

                // Se o formato não está correto, não emite a alteração
                if (momentDate.format(this.momentFormat) != value) {
                    // Se está no formato completo e ainda assim não está valido, mostra mensagem de erro
                    if (value.length == this.momentFormat.length) {
                        this.setInvalidDateError('format')
                    }
                    // console.log('computedTextInput set: formato incorreto')
                    return
                }

                // Input como moment
                this.input(momentDate)
            },
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, blur, focus, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, rules, errorMessages, required, ...attrs } = this.$attrs
            this.rules = rules
            return { ...attrs }
        },

        /**
         * Junta os erros de validação local deste componente com os erros passados para ele via props
         *
         * @return {array}
         */
        computedErrorMessages() {
            return [...(this.localErrorMessages || []), ...(this.errorMessages || [])]
        },
    },
}
</script>

<i18n>
{
    "pt-br": {
        "formats": {
            "date": "##/##/####",
            "datetime": "##/##/#### ##:##"
        },
        "validation": {
            "invalid_date"  : "Data inválida",
            "required"      : "Este campo é obrigatório",
            "invalid_format": "Formato inválido"
        }
    }
}
</i18n>