export default {
    companies: {
        parent: {
            Infos: {
                title: 'Informações da empresa',
                subtitle: 'Verifique as informações personalizáveis da empresa',
                edit: 'Editar',

                general: {
                    title: 'Empresa',

                    levels: {
                        title: 'Configurações de níveis',
                    },
                },

                frontend: {
                    register: {
                        title: 'Seção 01 - Registro',
                    },
                },

                system_site: {
                    title: 'Sistema e site'
                },

                banner: {
                    title: 'Banner de registro do site'
                },

                home: {
                    navbar: {
                        title: 'Seção 01 - Navbar da home'
                    },

                    hero: {
                        title: 'Seção 02 - Principal da home',
                    },

                    features: {
                        title: 'Seção 03 - Funcionalidades da home',
                    },
                },

                landing_page: {
                    title: 'Configurações da Navbar'
                },

                how_it_works: {
                    hero: {
                        title: 'Seção 04 - Principal do "como funciona"',
                    },
                    features: {
                        title: 'Seção 05 - Funcionalidades do "como funciona"',
                    },
                },

                description: {
                    title: 'Seção 06 - "Descrição"',
                },

                faq: {
                    title: 'Página FAQ',

                    items: {
                        title: 'Perguntas'
                    }
                },

                legal: {
                    title: 'Informações legais'
                },

                footer: {
                    links: {
                        title: 'Links',
                    },
                },

                custom_emails: {
                    description: 'Com esta configuração ativa, os emails enviados pelo sistema deixam de utilizar os textos padrão e passam a utilizar os textos definidos abaixo.',

                    successful_transaction: {
                        title: 'Transação realizada',
                        description: 'Este email será enviado sempre que for realizada alguma transação para o cliente. Alguns exemplos de transações são: gerações e consumos de pontos.',
                    },
                    customer_level_updated_period_update: {
                        title: 'Período de cálculo de níveis atualizado',
                        description: 'Este email será enviado sempre que uma alteração no período utilizado para o cálculo dos níveis causar uma mudança no nível do cliente.',
                    },
                    customer_level_updated_renovation: {
                        title: 'Renovação de níveis',
                        description: 'Quando o sistema de níveis for de renovação, este email será enviado sempre que um período se encerrar e um novo período se iniciar.',
                    },
                    customer_level_updated_setup: {
                        title: 'Inicialização dos níveis do cliente',
                        description: 'Este email será enviado sempre que algum dos dois seguintes casos ocorrer: um cliente se registrar e a empresa já tiver níveis configurados; ou, para todos os clientes, quando a empresa configurar seus níveis pela primeira vez.',
                    },
                    customer_level_updated_simple: {
                        title: 'Mudança de nível de cliente',
                        description: 'Este email será enviado sempre que um cliente mudar de nível por conta do funcionamento normal do sistema de níveis.',
                    },
                    customer_level_updated_system_update: {
                        title: 'Sistema de níveis atualizado',
                        description: 'Este email será enviado sempre que um cliente for impactado por atualização no sistema de níveis.',
                    },
                    promotion_triggered: {
                        title: 'Participação em promoção',
                        description: 'Este email será enviado sempre que um cliente participar de uma promoção.',
                    },
                    vouchers_received: {
                        title: 'Voucher recebido',
                        description: 'Este email será enviado sempre que um cliente receber um voucher. Está é uma situação específica e não se confunde com o caso do cliente comprar um voucher.',
                    },
                },
            },
            Personalize: {
                title: 'Personalize sua conta',

                cards_titles: {
                    general             : 'Dados principais',
                    levels              : 'Níveis',
                    program_settings    : 'Configurações do programa',
                    registration_message: 'Mensagem de registro',
                    general_layout      : 'Layout geral',
                    frontend_layout     : 'Layout sistema',
                    landing_page_layout : 'Layout landing page',
                    faq                 : 'Perguntas frequentes',
                    terms               : 'Termos legais',
                    footer              : 'Rodapé',
                    emails_customization: 'Customização de emails',
                },

                alerts: {
                    used_document: 'CNPJ já utilizado',
                    success: 'Personalização da empresa feita!',
                    error: 'Houve um erro ao personalizar a empresa'
                },
            },
        },
        subsidiaries: {
            title: 'Filiais',

            Create: {
                title   : 'Adicionar filial',

                alerts: {
                    used_document: 'O CNPJ inserido já está em uso',
                    network_error: '@:globals.network_error',
                    error        : 'Não foi possível adicionar a subsidiária',
                },
            },
            Edit: {
                title: 'Editar filial',

                alerts: {
                    used_document: '@:views.companies.subsidiaries.Create.alerts.used_document',
                    network_error: '@:globals.network_error',
                    error: 'Não foi possível atualizar as informações da filial',
                    error_get: 'Não foi possível encontrar a filial',
                },
            },
            Details: {
                title: 'Detalhes da filial',

                alerts: {
                    error: 'Não foi possível encontrar a filial',
                },
            },
            List: {
                alerts: {
                    create_success: 'A subsidiária foi adicionada com sucesso!',
                    edit_success: 'As informações da filial foram atualizadas!',
                    delete_success: 'Filial deletada',
                },
            },
        }
    },
    customers: {
        title: 'Clientes',

        Create: {
            title: 'Adicionar cliente',

            alerts: {
                used_email: 'O email inserido já está em uso',
                used_phone: 'O celular inserido já está em uso',
                used_document: 'O documento inserido já está em uso',
                network_error: '@:globals.network_error',
                error: 'Não foi possível adicionar o cliente',
            },
        },
        Details: {
            title: 'Detalhes do cliente',
        },
        Edit: {
            title: 'Editar cliente',

            alerts: {
                used_email: '@:views.customers.Create.alerts.used_email',
                used_phone: '@:views.customers.Create.alerts.used_phone',
                used_document: '@:views.customers.Create.alerts.used_document',
                network_error: '@:globals.network_error',
                error: 'Não foi possível atualizar as informações do cliente',
                error_get: 'Não foi possível encontrar o cliente',
            },
        },
        List: {
            alerts: {
                create_success: 'O cliente foi adicionado com sucesso!',
                edit_success: 'As informações do cliente foram atualizadas!',
                delete_success: 'Cliente deletado',
            },
        },
        History: {
            title: 'Histórico do cliente',
            list_title: 'Últimos registros',
            spent_value: 'Valor total gasto',
            received_amount: 'Total de pontos recebidos',
            no_registers: 'O cliente não tem registros',

            tabs: {
                all: 'Todos',
                fifteen_days: 'Últimos 15 dias',
                thirty_days: 'Últimos 30 dias',
            },

            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível obter o histórico do cliente',
            },
        },
        Register: {
            subsidiary_document: 'CNPJ filial: {document}',
        },
        Find: {
            search_field: {
                label: 'Documento',
                placeholder: '123.123.123-12',
                hint: 'Digite o documento do cliente',
            },
            customer_not_found: 'Parece que este cliente não está cadastrado.',
            create_customer: 'Cadastrar cliente',
        },
    },

    levels: {
        List: {
            title: 'Níveis',

            confirmations: {
                confirm_submit: 'Depois de finalizada a criação, não será mais possível adicionar ou remover níveis. Também não será possível alterar a quantidade de pontos gatilho dos níveis criados',
                confirm_delete: 'Deletar nível?',
            },

            alerts: {
                finish_success: 'Níveis criados com sucesso!',
                create_success: 'O nível foi adicionado com sucesso!',
                edit_success: 'As informações do nível foram atualizadas!',
                delete_success: 'Nível deletado',
                error: 'Não foi possível listar os níveis',
                network_error: '@:globals.network_error',
                error_get: 'Não foi possível encontrar o nível',
                error_create: 'Não foi possível criar os níveis',
                non_zero_first_trigger_amount: 'O primeiro nível deve precisar de zero pontos para ser alcançado',
                non_ascending_trigger_amounts: 'Os valor de gatilho de um nível deve ser maior que o do nível anterior',
            },
        },
        Create: {
            title: 'Novo nível',

            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível adicionar o nível',
            },
        },
        Edit: {
            title: 'Editar nível',

            benefits: 'Benefícios',
            prize   : 'Prêmio',

            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível atualizar as informações do nível',
            },
        },
        Details: {
            title: 'Detalhes do nível',

            benefits: '@:views.levels.Edit.benefits',
            prize: '@:views.levels.Edit.prize',
        },

        benefits: {
            List: {
                title: 'benefícios',

                confirmations: {
                    confirm_delete: 'Deletar benefício?',
                },

                alerts: {
                    error_get_level: 'Não foi possível encontrar o nível',
                    create_success: 'O benefício foi adicionado com sucesso!',
                    edit_success: 'As informações do benefício foram atualizadas!',
                    delete_success: 'Benefício deletado',
                    error: 'Não foi possível listar os benefícios',
                    network_error: '@:globals.network_error',
                },
            },
            Create: {
                title: 'Novo benefício',

                alerts: {
                    network_error: '@:globals.network_error',
                    error: 'Não foi possível adicionar o benefício',
                },
            },
            Edit: {
                title: 'Editar benefício',

                alerts: {
                    network_error: '@:globals.network_error',
                    error: 'Não foi possível atualizar as informações do benefício',
                },
            },
            Details: {
                title: 'Detalhes do benefício',
            },
        },

        prize: {
            title: 'Prêmio do nível',
            subtitle: 'Ofereça um prêmio aos clientes que atingem este nível',
            description: 'Quando um cliente atingir este nível, ele receberá um voucher para o prêmio automaticamente.',

            no_selected_prize: 'Não há nenhum prêmio selecionado',
            select_prize: 'Escolher um prêmio',
            change_prize: 'Escolher outro prêmio',
            remove_prize: 'Remover prêmio',
            success: 'Prêmio atualizado com sucesso!',
            remove_success: 'Prêmio removido com sucesso!',
        },
    },
    types: {
        title: 'Regras de cálculo',
        personalization: 'Personalização',

        Create: {
            title: 'Adicionar regra',

            alerts: {
                used_key: 'A chave inserida já está em uso',
                network_error: '@:globals.network_error',
                error: 'Não foi possível adicionar a regra',
            },
        },
        Edit: {
            title: 'Editar regra',

            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível atualizar as informações da regra',
                error_get: 'Não foi possível exibir a regra',
                error_get_clr: '@:views.types.Edit.alerts.error_get',
            },
        },
        Details: {
            title: 'Detalhes da regra',
            subtitleCLR: 'Personalização da regra',
            no_calculation_rule: 'Esta regra não está personalizada e funciona exatamente como a regra default',
            edit_calculation_rule: 'Personalizar regra',

            alerts: {
                network_error: '@:globals.network_error',
                error: '@:views.types.Edit.alerts.error_get',
            }
        },
        List: {
            alerts: {
                create_success: 'A regra foi adicionada com sucesso!',
                edit_success: 'As informações da regra foram atualizadas!',
                delete_success: 'Regra deletada',
            },
        }
    },
    calculationRules: {
        Create: {
            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível personalizar a regra. Você pode fazer isso mais tarde, até lá, esta regra funcionará exatamente como a regra default',
            },
        },
        Edit: {
            alerts: {
                network_error: '@:globals.network_error',
                error: '@:views.calculationRules.Create.alerts.error',
                error_get: 'Não foi possível encontrar personalização da categoria',
            }
        },
    },
    vouchers: {
        Withdraw: {
            title: 'Retirar voucher',
            withdraw: 'Retirar voucher',

            success: 'O voucher foi retirado com sucesso',
            errors: {
                network_error: '@:globals.network_error',
                generic: 'Houve um erro ao retirar o voucher do cliente',
            },
        },
    },
    login: {
        title: 'Entrar',
        subtitle: 'Bem-vindo de volta!',
        login_error: 'As credenciais estão incorretas.',
        logout_success: 'Você saiu da sua conta.',
        token_expired: 'A sua sessão expirou! Entre com suas credenciais novamente.',
        go_to_frontend: 'Ir para o painel de cliente',
        passwordReset: 'A sua senha foi redefinida com sucesso.',
        auth_needed: 'Você precisa entrar na sua conta para poder fazer isso',
        reAuth: 'Houve um erro ao reautenticar. Insira suas novas informações de login para entrar',
        tokenExpired: '@:errors.http.401',
    },
    dashboard: {
        title: 'Bem-vindo',

        subsections: {
            last_notifications: {
                title: 'Últimas notificações',
                see_all: 'Ver todas',
            },
            last_registered_customers: {
                title: 'Últimos clientes registrados',
                see_all: 'Ver todos',
            }
        },

        cards: {
            registered_customers: {
                subtitle: 'Clientes registrados',
            },

            generator_count: {
                subtitle: 'Gerações',
            },

            consumption_count: {
                subtitle: 'Consumos',
            },

            generated_points: {
                subtitle: 'Pontos gerados',
            },

            consumed_points: {
                subtitle: 'Pontos consumidos',
            },

            generated_vouchers: {
                subtitle: 'Vouchers gerados',
            },

            buyed_vouchers: {
                subtitle: 'Vouchers comprados',
            },

            withdrawn_vouchers: {
                subtitle: 'Vouchers retirados',
            },

            sub_note: 'Para informações mais detalhadas veja relatórios.',
        },

        coming_soon: {
            notifications: {
                title: 'Disponível em breve',
                subtitle: 'Notificações',
            },

            registered_customers: {
                title: '@:views.dashboard.coming_soon.notifications.title',
                subtitle: 'Clientes registrados',
            },
        },

        filter: {
            'label' : 'Período:',
            'generic_day' : '{n} dia | {n} dia | {n} dias',
        }
    },
    generate: {
        title: 'Gerar pontos',
        generate: 'Gerar pontos',
        success: 'Os pontos foram gerados com sucesso',

        errors: {
            network_error: '@:globals.network_error',
            generic: 'Houve um erro ao gerar os pontos para o cliente.'
        }
    },
    consumption: {
        title: 'Gerar consumo',
        generate: 'Gerar consumo',
        success: 'O consumo foi registrado com sucesso',

        errors: {
            usage_limit_reached: 'O limite de uso foi atingido.',
            customer_last_consumption_still_processing: 'O último consumo deste cliente ainda está em processamento. Tente novamente mais tarde.',
            customer_already_has_intention: 'Este cliente já tem uma intenção de compra em andamento.',
            insufficient_funds: 'O cliente não tem saldo suficiente.',
            network_error: '@:globals.network_error',
            generic: 'Houve um erro ao gerar o consumo para o cliente.',
        }
    },
    settings: {
        Tabs: {
            title: 'Configurações gerais',

            tabs_titles: {
                profile: 'Perfil',
                users: 'Usuários',
                rules: 'Regras',
                analytics: 'Monitoramento',
                integrations: 'Integrações',
                webhook_registers: 'Webhooks',
            },
        },

        tabs_items: {
            Profile: {
                title: 'Mantenha suas informações sempre atualizadas',

                update_success: 'Seus dados foram atualizados',
            },

            Users: {
                users_table: {
                    title: 'Lista de usuários',

                    name_and_email: 'Nome e Email',
                    role: 'Permissões',

                    role_label: 'Permissão',
                    company_label: 'em',

                    add_user: 'Adicionar usuário',
                    remove_user: 'Remover usuário',
                    confirm_remove_user: 'Você tem certeza que deseja remover o usuário "{name}"?',
                    remove_success: 'O usuário foi removido com sucesso',
                    create_success: 'Novo usuário criado com sucesso',
                },
            },

            Rules: {
                title: 'Gerencie as regras do site e do painel',
                confirmation_text: 'Atenção, se prosseguir você estará alterando o sistema de níveis, o   que implicará reiniciar os níveis no novo sistema',

                fetch_error: 'Ocorreu um erro ao carregar as regras',
                update_error: 'Ocorreu um erro ao carregar as regras',
                update_success: 'As regras foram atualizadas com sucesso!',
            },

            Analytics: {
                title: 'Gerencie as configurações de monitoramento',

                fetch_error: 'Ocorreu um erro ao carregar as configurações',
                update_error: 'Ocorreu um erro ao carregar as configurações',
                update_success: 'As configurações foram atualizadas com sucesso!',
            },

            Integrations: {
                title: 'Gerencie as integrações',
            },

            WebhookRegisters: {
                title: 'Gerencie o envio de webhooks',

                confirm_remove: 'Você tem certeza que deseja remover webhook para: {url} ?',
                create_success: 'Webhook registrado com sucesso',
                update_success: 'Registro de webhook atualizado com sucesso',
                remove_success: 'Registro de webhook removido com sucesso',

                webhook_registers_table: {
                    title: 'Gerencie o envio de webhooks',

                    url: 'URL',
                    types: 'Eventos',


                    name_and_email: 'Nome e Email',
                    role: 'Permissões',

                    role_label: 'Permissão',
                    company_label: 'em',

                    create_webhook_register: 'Registrar webhook',
                    update_webhook_register: 'Atualizar registro de webhook',
                    remove_webhook_register: 'Remover registro de webhook',
                    details_webhook_register: 'Detalhes do registro de webhook',
                },
            },
        },
    },
    welcome: {
        title: 'Seja bem-vindo ao sistema de fidelidade da ConnectPlug',
        subtitle: 'Painel administrador',

        description1: 'Com este painel você poderá customizar todos os detalhes da sua empresa que são exibidos na página inicial do sistema de fidelidade, configurar as regras de cálculo de pontos, além de ver informações relevantes sobre o uso do sistema por seus clientes.',
        first_access_warning: 'Vimos que este é seu primeiro acesso ao sistema, portanto recomendamos a alteração da senha inicial.',
        change_password: 'Alterar a senha',
        go_to_dashboard: 'Ir para o painel principal',
    },
    history: {
        title: 'Histórico do cliente',
    },
    select_company: {
        title: 'Selecione a sua empresa',
        company_selected: 'Empresa {name} selecionada',

        matrix: 'Matriz',
        subsidiary: 'Filial',
    },

    // [TODO] Retirar e adicionar a shared, visto que se refere a PrizeCard
    Prizes: {
        tabs: {
            list: {
                prize_card: {
                    expires_in: 'Válido por {expires_in} dias',
                    never_expires: 'Válido para sempre!',
                },
            },
        },
    },

    prizes: {
        title: 'Produtos',
        create: 'Criar prêmio',
        category: 'Categorias',
        not_found: 'Este prêmio não foi encontrado',
        empty: 'Parece que não há prêmios cadastrados',
        remove_success: 'Produto removido com sucesso',

        tabs: {
            all: 'Todos',
            removed: 'Removidos'
        },

        Create: {
            title: 'Adicionar produto',

            alerts: {
                success: 'Produto adcionado com sucesso',
                network_error: '@:globals.network_error',
                error: 'Não foi possível adicionar o produto',
            }
        },
        Edit: {
            title: 'Editar produto',

            alerts: {
                success: 'Produto atualizado com sucesso',
                network_error: '@:globals.network_error',
                error: 'Não foi possível atualizar as informações do produto',
                error_get: 'Não foi possível encontrar o produto',
            }
        },
    },

    import: {
        layout_info: 'Em breve as importações também estarão de visual novo',

        view: {
            title: 'Detalhes da importação',

            info: {
                id        : { label: 'Identificador' },
                type      : { label: 'Tipo de importação' },
                status    : { label: 'Status' },
                created_at: { label: 'Criada em' },
                created_by: { label: 'Criada por' },
                file_url  : { label: 'Arquivo original', value: 'clique para fazer download' },
            },

            progress: {
                title: 'Progresso da importação',

                progress         : { value: 'Linha {row}' },
                validate_progress: { label: 'Validado até' },
                process_progress : { label: 'Processado até', hint: 'Os registros até a linha {row} foram processados com sucesso. Você pode removê-los da próxima tentativa de importação.' },
            },

            error: {
                title: 'Detalhes do erro',

                code: { label: 'Código de erro' },
                message: { label: 'Mensagem de erro' },
                status_details: { label: 'Dados adicionais' },
            },
        },
        List: {
            title: 'Importações', 
            add: 'Importar',

            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível exibir o histórico de importações',
            }
        },
        customer: {
            title            : 'Importar clientes',
            description1     : 'Para importar clientes, utilize o modelo fornecido abaixo.',
            description2     : 'Depois de garantir que seus dados estejam de acordo com o modelo, faça upload do arquivo para iniciar a importação.',
            obs              : 'Observações',
            obs1             : 'Pontos gerados a partir da importação de clientes têm validade de 1 ano;',
            obs2             : 'Nenhum email será enviado para os clientes da importação;',
            obs3             : 'A importação ainda não suporta saldo negativo. Remova clientes com saldo negativo da importação.',
            download_template: 'Baixar planilha modelo',
            upload_template  : 'Fazer upload',
            update_register_customers: 'Atualizar dados de clientes cadastrados',
            update_register_customers_hint: 'Ao marcar essa opção, os cadastros de clientes que já existem no sistema de fidelidade serão atualizados com as informações da planilha de importação (os pontos e saldo mantém os que estão no fidelidade).',
            reset_balance: 'Zerar o saldo de todos os clientes na importação',
            reset_balance_hint: 'Ao marcar essa opção, todos os clientes que forem atualizados terão seu saldo zerado.',
        },
    },
    logs: {
        List: {
            title: 'Logs',
        },
    },
    prizeCategory: {
        title: 'Categorias de prêmio',

        Create: {
            title: 'Adicionar Categoria',

            alerts: {
                success      : 'A categoria foi adicionada com sucesso!',
                network_error: '@:globals.network_error',
                error        : 'Não foi possível adicionar a categoria'
            }
        },
        Edit: {
            title: 'Editar Categoria',

            alerts: {
                success      : 'A informação da categoria foi atualizada!',
                network_error: '@:globals.network_error',
                error_get    : 'Não foi possível encontrar a categoria',
                error        : 'Não foi possível atualizar a informação da categoria',
            }
        },
        Details: {
            title: 'Detalhes da Categoria',
        },
        List: {
            alerts: {
                network_error: '@:globals.network_error',
                error: 'Não foi possível listar as categorias',
                delete_success: 'Categoria deletada',
            }
        }
    },

    reports: {
        title: 'Relatórios disponíveis',

        layout_info: 'Em breve os relatórios também estarão de visual novo',

        list: {
            customer: {
                subheader : 'Clientes',
                created_at: {
                    title   : 'Novos clientes por data de cadastro',
                    subtitle: 'Veja os clientes que se cadastraram no programa filtrando por datas.',
                },
            },
            voucher: {
                subheader : 'Vouchers',
                withdrawn_at: {
                    title   : 'Voucher por data de retirada',
                    subtitle: 'Veja os vouchers que foram retirados filtrando por datas.',
                },
                created_at: {
                    title   : 'Voucher por data de criação',
                    subtitle: 'Veja os vouchers que foram criados filtrando por datas.',
                },
            },
            points: {
                subheader: 'Pontos',
                generator: {
                    title   : 'Geração de pontos por data',
                    subtitle: 'Veja os pontos que foram gerados no programa filtrando por datas.',
                },
                consumption: {
                    title   : 'Consumo de pontos por data',
                    subtitle: 'Veja os pontos que foram consumidos no programa filtrando por datas.',
                },
                interactions: {
                    title   : 'Interações',
                    subtitle: 'Veja todas as interações (gerações, consumos, estornos e vouchers) do sistema.',
                },
                frequencies: {
                    title   : 'Frequência',
                    subtitle: 'Veja as interações do clientes agrupadas por dia.',
                },
            },
            custom: {
                subheader: 'Relatórios personalizados (Em breve)',
            },
        },

        commons: {
            header: {
                label: 'Descrição',
                count: 'Contagem',
            },
            content: {
                title                     : 'Resultados',
                export                    : 'Exportar',
                export_tooltip            : 'Exporta os resultados deste relatório para um arquivo csv',
                error                     : 'Ocorreu um erro ao gerar este relatório.',
                count                     : 'Foram encontrados {count} itens.',
                count_error               : 'Ocorreu um erro ao contar o número de resultados.',
                unknown_label             : 'Informação não vinculada',
                root_label                : 'Agrupamento raiz',
                nested_group_count        : '{count_results} em {count_groups}',
                nested_group_count_results: 'nenhum resultado | {n} resultado | {n} resultados',
                nested_group_count_groups : 'nenhum grupo | {n} grupo | {n} grupos',
                group_count               : 'nenhum resultado | {n} resultado | {n} resultados',
            },
            form: {
                from: {
                    label      : 'De',
                    placeholder: '01/01/2020',
                    hint       : 'Digite a data de início da busca',
                },
                to: {
                    label      : 'Até',
                    placeholder: '01/01/2021',
                    hint       : 'Digite a data de fim da busca',
                },
                count: {
                    label: 'Mostrar apenas somas do agrupamento',
                },
                include_reversals: {
                    label: 'Incluir registros cancelados',
                },
                sort_by: {
                    label      : 'Ordenar por',
                    placeholder: 'Data de criação',
                    hint       : 'Este campo irá ordenar os resultados',

                    created_at: 'Data de criação',
                },
                group_by: {
                    label      : 'Agrupar por',
                    hint       : 'Selecione até 3 agrupamentos',
                    placeholder: 'Filial, semana',

                    company: 'filial',
                    // weekday: 'dia da semana', // [TODO][FID-1022]
                    hour   : 'hora',
                    day    : 'dia',
                    week   : 'semana',
                    month  : 'mês',
                    year   : 'ano',
                },
            },
        },

        customer: {
            created_at: {
                title: '@:views.reports.list.customer.created_at.title',
                description: '@:views.reports.list.customer.created_at.subtitle',

                form: {
                    group_by: {
                        company: 'filial de registro',
                    },
                },

                content: {
                    name          : 'Nome',
                    document      : 'Documento',
                    created_at    : 'Cadastro em',
                    go_to_customer: 'Ver este cliente',
                },
            },
            sort_hint: 'Resultados ordenados por data de cadastro do cliente',
        },
        vouchers: {
            title      : 'Relatório de Vouchers',
            description: 'Veja vouchers criados e retirados ordenando por datas',

            form: {
                group_by: {
                    company   : 'filial de retirada',
                    prize     : 'tipo de prêmio retirado',
                    created_by: 'meio de geração',

                    created_at_hour   : 'data de criação: hora',
                    created_at_day    : 'data de criação: dia',
                    created_at_week   : 'data de criação: semana',
                    created_at_month  : 'data de criação: mês',
                    created_at_year   : 'data de criação: ano',
                    withdrawn_at_hour : 'data de retirada: hora',
                    withdrawn_at_day  : 'data de retirada: dia',
                    withdrawn_at_week : 'data de retirada: semana',
                    withdrawn_at_month: 'data de retirada: mês',
                    withdrawn_at_year : 'data de retirada: ano',
                },
                sort_by: {
                    withdrawn_at: 'Data de retirada',
                },
            },

            content: {
                customer    : 'Cliente',
                created_by  : 'Meio de geração',
                created_at  : 'Gerado em',
                withdrawn_at: 'Retirado em',
                prize_name  : 'Ref. prêmio',
                company_name: 'Filial de retirada',
            },
        },
        generator: {
            title      : 'Relatório de Gerações',
            description: 'Veja pontos gerados filtrando por datas',

            form: {
                group_by: {
                    company   : 'filial de geração',
                    // created_by: 'meio de geração',
                },
            },

            content: {
                created_at  : 'Data',
                value       : 'Valor gerado',
                amount      : 'Qtde. pontos',
                spent_value : 'Valor gasto',
                company_name: 'Filial',
            },
        },
        interactions: {
            title      : 'Relatório de Interações',
            description: 'Veja todas as interações (gerações, consumos, estornos e vouchers) do sistema',

            form: {
                group_by: {
                    company : 'filial',
                    customer: 'cliente',
                    chr_type: 'tipo',
                },
            },

            content: {
                created_at       : 'Data',
                value            : 'Valor',
                amount           : 'Pontos',
                company_name     : 'Filial',
                customer_document: 'Cliente',
                chr_type         : 'Tipo',
                reversal_id      : 'ID do cancelamento',
            },
        },
        frequencies: {
            title      : 'Relatório de Frequência',
            description: '@:views.reports.list.points.frequencies.subtitle',

            header: {
                frequency_sum: 'Frequência',
            },

            content: {
                see_customer: 'clique para ver detalhes deste cliente',
            },
        },
        consumption: {
            title      : 'Relatório de Consumos',
            description: 'Veja pontos consumidos filtrando por datas',

            form: {
                group_by: {
                    company   : 'filial de consumo',
                    // created_by: 'meio de geração',
                },
            },

            content: {
                created_at  : 'Data',
                value       : 'Valor consumido',
                amount      : 'Qtde. pontos',
                company_name: 'Filial',
            },
        },
    },

    promotions: {
        title: 'Promoções',
        create: 'Criar promoção',
        not_found: 'Esta promoção não foi encontrada',
        empty: 'Parece que não há promoções cadastradas',
        remove_success: 'Promoção removida com sucesso',

        Create: {
            title: 'Adicionar promoção',

            alerts: {
                success: 'Promoção adcionada com sucesso',
                network_error: '@:globals.network_error',
                used_trigger_code: 'O código desta promoção já foi utilizado. Por favor escolha outro código.',
                error: 'Não foi possível adicionar a promoção',
            }
        },
        Edit: {
            title: 'Editar promoção',

            alerts: {
                success: 'Promoção atualizada com sucesso',
                network_error: '@:globals.network_error',
                error: 'Não foi possível atualizar as informações da promoção',
                used_trigger_code: '@:views.promotions.Create.alerts.used_trigger_code',
                error_get: 'Não foi possível encontrar a promoção',
            }
        },
        Details: {
            title: 'Detalhes da promoção',

            alerts: {
                network_error: '@:globals.network_error',
                error: '@:views.promotions.Edit.alerts.error_get',
            }
        },
        List: {
            alerts: {
                create_success: 'A promoção foi adicionada com sucesso!',
                edit_success: 'As informações da promoção foram atualizadas!',
                delete_success: 'Promoção deletada',
            },
        }
    },
}