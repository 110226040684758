import BaseMiddleware from './BaseMiddleware'
import store from '@/store'

export default class AuthenticatedMiddleware extends BaseMiddleware {

    /**
     * Avalia se deve executar este middleware ou não
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve executar este middleware?
     */
    shouldRun(to, from) {
        return to.meta.requiresAuthentication
    }

    /**
     * Executa ações sobre esta rota
     *
     * @param {Route} to de qual rota está vindo
     * @param {Route} from para qual rota está indo
     *
     * @return {boolean} deve retornar false quando este middleware modifica a rota com next
     */
    handle(to, from, next) {
        let isAuthenticated = store.getters['auth/check']

        if (!isAuthenticated) {
            next({ name: 'login', params: { authNeeded: true } })
        }

        return isAuthenticated
    }

}