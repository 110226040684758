<template>
    <div :class="customClass" :style="computedStyle">
        <slot name="label">
            <label v-if="!hideLabel" class="input-label secondary_font--text" for="input">{{ label }}</label>
        </slot>
        <slot name="input"></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseInput',
    props: {
        label: {
            type: String,
            require: true,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        width   : String,
        maxWidth: String,
        inline  : Boolean,
        disabled: Boolean,
    },
    data: vm => ({
        customClass: {
            'input-disabled': !!vm.disabled,
        },
    }),
    computed: {
        computedStyle() {
            return {
                'width': this.width,
                'max-width': this.maxWidth,
            }
        },
    },
    watch: {
        disabled(value) {
            this.customClass['input-disabled'] = !!value
        },
    },
}
</script>

<style lang="scss">
.input-disabled input, .input-disabled textarea {
    color: var(--v-secondary_font-base) !important;
}
</style>