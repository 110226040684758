// Componentes shared
import DocumentInput    from './components/inputs/DocumentInput'
import PasswordInput    from './components/inputs/PasswordInput'
import TextareaInput    from './components/inputs/TextareaInput'
import PhoneInput       from './components/inputs/PhoneInput'
import TextInput        from './components/inputs/TextInput'
import DateInput        from './components/inputs/DateInput'
import SelectInput      from './components/inputs/SelectInput'
import PostalCodeInput  from './components/inputs/PostalCodeInput'
import $console         from '@/services/console'
import AmountInput      from './components/inputs/AmountInput'
// Etc.
// import sharedLocale from './i18n/index'

// Assume estar no repo local
// import $i18n from '@/i18n'

const MyPlugin = {}

MyPlugin.install = function (Vue, options) {
    $console.log('[shared] installing shared assets...')

    // Registra componentes shared globais
    Vue.component('cp-document-field'  , DocumentInput  )
    Vue.component('cp-password-field'  , PasswordInput  )
    Vue.component('cp-phone-field'     , PhoneInput     )
    Vue.component('cp-textarea'        , TextareaInput  )
    Vue.component('cp-text-field'      , TextInput      )
    Vue.component('cp-date-field'      , DateInput      )
    Vue.component('cp-select-field'    , SelectInput    )
    Vue.component('cp-postalcode-field', PostalCodeInput)
    Vue.component('cp-amount'          , AmountInput    )

    // Faz o merge das traduções shared com as que estão locais no projeto
    // $i18n.mergeLocale('pt-br', sharedLocale['pt-br']) // Por enquanto pt-br estático

    // // 1. add global method or property
    // Vue.myGlobalMethod = function () {
    //   // some logic ...
    // }

    // // 2. add a global asset
    // Vue.directive('my-directive', {
    //   bind (el, binding, vnode, oldVnode) {
    //     // some logic ...
    //   }
    // })

    // // 3. inject some component options
    // Vue.mixin({
    //   created: function () {
    //     // some logic ...
    //   }
    // })

    // 4. add an instance method
    // Vue.prototype.$teste = function (methodOptions) {
    // }
}

export default MyPlugin