import BaseModel from '@/models/BaseModel'
import axios from '@/services/axios'
import i18n from '@/i18n'
import _ from 'lodash'

export default class ImportEntry extends BaseModel {

    defaults() {
        return {
            id            : undefined,
            created_at    : undefined,
            created_by    : undefined,
            status        : undefined,
            status_details: {
                code   : undefined,
                message: undefined,
                data   : undefined,
            },
            progress: {
                total            : 0,
                batch_size       : 0,
                validate_progress: 0,
                process_progress : 0,
            },
            file_url      : undefined,
            type          : undefined,
        }
    }

    // Métodos úteis

    hasError() {
        return this.status == 'ERROR'
    }

    isDone() {
        return this.status == 'DONE'
    }

    hasProgress() {
        return !_.isEmpty(this.progress) && this.progress.total
    }

    // Getters & Setters

    get progress_process_percentage() {
        return this.hasProgress() ? this.progress.process_progress * 100 / this.progress.total : 0
    }

    get progress_validate_percentage() {
        return this.hasProgress() ? this.progress.validate_progress * 100 / this.progress.total : 0
    }

    get progress_percentage() {
        return this.hasProgress() ? (this.progress.validate_progress + this.progress.process_progress) * 100 / (this.progress.total * 2) : 0
    }

    get status_i18n() {
        const definedStatuses = [
            'INITIALIZED', 'CREATED', 'ERROR', 'PROCESS_PROGRESS', 'VALIDATE_PROGRESS', 'DONE'
        ]

        let mapKey = definedStatuses.includes(this.status) ? this.status : 'UNKNOWN'

        return i18n.t(`models.ImportEntry.status.${mapKey}`)
    }

    get status_color() {
        const colorMapping = {
            'INITIALIZED'      : 'primary',
            'CREATED'          : 'primary',
            'ERROR'            : 'error',
            'PROCESS_PROGRESS' : 'primary',
            'VALIDATE_PROGRESS': 'primary',
            'DONE'             : 'success',
        }

        return colorMapping[this.status] || 'primary'
    }

    get type_i18n() {
        const definedTypes = [
            'customer',
            'prizes/available_codes',
        ]

        let mapKey = definedTypes.includes(this.type) ? this.type : 'UNKNOWN'

        return i18n.t(`models.ImportEntry.types.${mapKey}`)
    }

    get error_message_i18n() {
        if (!this.hasError())
            return ''

        const definedCodes = [
            'ROW_VALIDATION', 'DUPLICATED_COLUMN_VALUE', 'ROW_LIMIT',
            'HEADERS_LENGTH_MISMATCH',
        ]

        let mapKey = definedCodes.includes(this.status_details.code) ? this.status_details.code : 'UNKNOWN'

        return i18n.t(`models.ImportEntry.errors.${mapKey}`, { ...this.status_details.data })
    }

    get lastProcessedRow() {
        let index = this.progress.process_progress * this.progress.batch_size
        return this.hasProgress() && index ? index : 0
    }

    get lastValidatedRow() {
        let index = this.progress.validate_progress * this.progress.batch_size
        return this.hasProgress() && index ? index : 0
    }

    // Métodos da API

    /**
     * Faz uma requisição para listar as importações
     *
     * @param {object} pagination parâmetros de paginação
     */
    static async list(pagination = {}) {
        let params = {
            page    : pagination.page     || 1,
            per_page: pagination.per_page || pagination.perPage || 10,
            order   : pagination.order    || 'DESC',
        }

        let response = await axios({
            url   : `/import`,
            method: 'get',
            params,
        })

        if (!response)
            return

        return response
    }

    static async get(id, additionalConfig = {}) {
        let response = await axios({
            url   : `/import/${id}`,
            method: 'get',
            ...additionalConfig,
        })

        if (!response)
            return

        return response
    }

}