import $console from '@/services/console'
import $store   from '@/store'

export default new class Middleware {

    constructor() {
        this._middlewares = []
        this.loadMiddlewares()
    }

    /**
     * Carrega todos os middlewares da pasta nesta classe
     */
    loadMiddlewares() {
        const files = require.context('.', false, /\.js$/)
        var middlewares = []

        files.keys().forEach(key => {
            // Ignora este proprio arquivo
            if (key === './index.js' || key === './BaseMiddleware.js') return
            middlewares.push(new (files(key).default))
        })

        this._middlewares = middlewares
    }

    /**
     * Passa os middlewares em um router
     *
     * @param {VueRouter} router instância do router
     */
    async setup(router) {
        router.beforeEach(this.beforeEach.bind(this))
    }

    async beforeEach(to, from, next) {
        // [LOG]
        $console.log(`[router] from ${from.name} to ${to.name}`)

        // Verifica se os serviços já foram carregados [FID-550]
        // obs. tem que vir antes da execução dos middlewares
        let isAppLoaded = $store.getters['app/isLoaded']

        if (!isAppLoaded) {
            if (to.name == 'loading')
                next()
            else
                next({ name: 'loading', params: { redirect: to } })
            return
        }

        let promises = this._middlewares.map(async (middleware) => !(await middleware.shouldRun(to, from)) || await middleware.handle(to, from, next) )
        let shouldNext = !(await Promise.all(promises)).some(a => !a)
        if (shouldNext)
            next()
    }
}