export default class BaseIntegrationModel {
    constructor(key) {
        this._key      = key
        this._isActive = false
    }

    // Métodos

    /**
     * Roda a ativação desta integração
     */
    async setup() {
        // Por padrão, não faz nada
    }

    /**
     * Retorna o componente de configuração desta integração.
     * Deve ser implementado nas classes filhas.
     *
     * @return {component}
     */
    get settingsComponent() {
        throw new Error(`Não há componente de configuração para a integração ${this.key}`)
    }

    // Acessors

    /**
     * Retorna se a integração está ativa ou não
     *
     * @returns {boolean}
     */
    isActive() {
        return this._isActive
    }

    /**
     * Marca a integração como ativa
     */
    activate() {
        this._isActive = true
    }

    /**
     * Marca a integração como desativada
     */
    deactivate() {
        this._isActive = false
    }

    // Getters & Setters

    /**
     * Retorna a chave (identificador) desta integração
     */
    get key() {
        return this._key
    }

    set key(value) {
        // Não tem efeito, a key só pode ser setada uma vez no construtor
    }
}