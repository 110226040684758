export default {
    name: 'history',
    path: '/historico/:id?',
    component: () => import(/* webpackChunkName: "history" */ '@/views/history/History.vue'),
    props: true,
    meta: {
        requiresAuthentication: true,
        requiresSelectedCompany: true,
        title: 'customers.history',
        can: 'list Customer',
        fail: '/painel',
        breadcrumbs: [
            {
                text: 'Dashboard',
                to: { name: 'home' }
            },
            {
                text: 'Histórico',
                disabled: true
            }
        ]
    },
}