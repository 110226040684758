var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ImportantNotifications", {
    staticClass: "bt",
    attrs: {
      title: _vm.title,
      description: _vm.description,
      buttonConfirm: _vm.agreeAndClose,
      loading: _vm.loading
    },
    on: { confirm: _vm.acceptTerms },
    scopedSlots: _vm._u([
      {
        key: "additionalContent",
        fn: function() {
          return [
            _c(
              "a",
              {
                staticClass: "primary--text font-weight-bold mx-1",
                attrs: { href: _vm.cookies_policy_url, target: "_blank" }
              },
              [_vm._v(_vm._s(_vm.cookies_policy))]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }