import Level from '@/models/Level'
import $constants from '@/services/constants'

const state = {
    levels   : [],
    isCreated: false,
}

const getters = {
    levels         : state => state.levels,
    isCreated      : state => state.isCreated,
    getLevelById   : state => (id) => state.levels.find(l => l.id == id),
    getLevelByIndex: state => (index) => state.levels[index],
}

const mutations = {
    SET_LEVELS: (state, levels) => {
        state.levels = levels
    },
    ADD_LEVEL: (state, level) => {
        state.levels.push(level)
    },
    UPDATE_LEVEL: (state, level) => {
        state.levels[level.id] = level
    },
    DELETE_LEVEL: (state, id) => {
        state.levels.splice(id, 1)

        state.levels.forEach((level, index) => {
            level.id = index
        })
    },
    SET_IS_CREATED: (state, value) => {
        state.isCreated = value
    },
}

const actions = {
    async fetchLevels({ commit }) {
        // Busca todos os níveis
        let response = await Level.list({ per_page: $constants.getConstant('PAGINATION_MAX_PER_PAGE') })

        if (!response || !response.data || !response.status == 200)
            return

        let levels = []
        for (let level of response.data)
            levels.push(new Level(level))

        if (levels.length) {
            commit('SET_LEVELS', levels)
            commit('SET_IS_CREATED', true)
        }

        return levels
    },
    addLevel({ commit, state }, level) {
        if (!(level instanceof Level))
            return

        level.id = state.levels.length
        if (level.id != 0) {
            let prevLevel = state.levels[state.levels.length - 1]

            // Adiciona nível atual ao next do anterior
            prevLevel.next = {
                id: level.id,
                amount: level.trigger_amount,
            }
            
            // Adiciona nível anterior ao prev do atual
            level.prev = {
                id: prevLevel.id,
                amount: prevLevel.trigger_amount,
            }
        }

        commit('SET_LEVELS', state.levels.concat(level))
    },
    updateLevel({ commit }, level) {
        if (!(level instanceof Level))
            return

        commit('UPDATE_LEVEL', level)
    },
    deleteLevel({ commit }, id) {
        commit('DELETE_LEVEL', id)
    },
}

export default {
    namespaced: true,
    state, getters, mutations, actions
}