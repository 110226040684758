export default {
    name: 'logs',
    path: '/logs',
    redirect: '/logs/list',
    component: () => import(/* webpackChunkName: "logs" */ '@/views/logs/Base.vue'),
    children: [
        {
            name: 'logs.list',
            path: 'list',
            component: () => import(/* webpackChunkName: "logs" */ '@/views/logs/List.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' }
                    },
                    {
                        text: 'Logs',
                        disabled: true
                    },
                ]
            }
        },
    ],
}