export default {
    name: 'consumption',
    path: '/consumo/:id?',
    component: () => import(/* webpackChunkName: "consumption" */ '@/views/consumption/ConsumptionView.vue'),
    props: true,
    meta: {
        requiresAuthentication: true,
        requiresSelectedCompany: true,
        title: 'consumption',
        can: 'create Consumption',
        fail: '/painel',
        breadcrumbs: [
            {
                text: 'Dashboard',
                to: { name: 'home' }
            },
            {
                text: 'Gerar consumo',
                disabled: true
            }
        ]
    },
}