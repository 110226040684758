<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="17.99" height="19.767" viewBox="0 0 17.99 19.767"><g class="a"><path class="b" d="M3,8.218,11,2,18.99,8.218V17.99a1.777,1.777,0,0,1-1.777,1.777H4.777A1.777,1.777,0,0,1,3,17.99Z" transform="translate(-2 -1)"/></g></svg>
</template>

<script>
export default {
    name: 'HomeIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>