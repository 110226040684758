import SharedCustomerHistoryRecord from '@/shared/models/CustomerHistoryRecord'
import i18n from '@/i18n'

/**
 * Esta classe deve ser usada como pai para todos os modelos
 * que aparecem no histórico de cliente
 */
export default class CustomerHistoryRecord extends SharedCustomerHistoryRecord {

    getChrTypeLabel() {
        return this.constructor.getChrTypeLabel(this.chr_type)
    }

    static getChrTypeLabel(type) {
        const allowedTypes = ['CNS', 'GNR', 'RVS']
        let dynamicKey = allowedTypes.includes(type) ? type : 'default'

        return i18n.t(`models.CustomerHistoryRecord.chr_type_label.${dynamicKey}`)
    }

}