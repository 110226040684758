const files  = require.context('./drivers/', false, /\.js$/)
const driver = process.env.VUE_APP_PERSISTENCE_DRIVER || 'Local'

let persistence = null
files.keys().forEach(key => {
    if (key !== 'BasePersistence' && key === `./${driver}.js`) {
        persistence = files(key).default
        return
    }
})

export default persistence