import BaseModel from './BaseModel'
import { required, string, length, positiveNumber, isnil, positiveInteger, boolean } from '../utils/validation'

export default class Prize extends BaseModel {

    defaults() {
        return {
            id           : null,
            name         : null,
            description  : null,
            tos_url      : null,
            created_at   : null,
            updated_at   : null,
            expires_at   : null,
            expires_in   : null,
            withdrawn_at : null,
            value        : null,
            photo_url    : null,
            integrator_id: null,
            show_in_store: true,
        }
    }

    validation() {
        return {
            name         : required.and(string.and(length(1, 191))),
            description  : isnil.or(string.and(length(1, 600))),
            tos_url      : isnil.or(string.and(length(1, 600))),
            // expires_at   : isnil.or(string),
            expires_in   : positiveInteger,
            // expires_in   : positiveInteger,
            // withdrawn_at : positiveInteger,
            value        : positiveNumber,
            photo_url    : isnil.or(string.and(length(1, 600))),
            show_in_store: boolean,
        }
    }

    static async list(adittionalConfig = {}) {
        let response = await this.request({
            url   : `/prize`,
            method: 'get',
            ...adittionalConfig,
        })

        if (!response)
            return

        return response.data.map(d => new this(d))
    }

    static async get(prizeId) {
        let response = await this.request({
            url   : `/prize/${prizeId}`,
            method: 'get',
        })

        if (!response)
            return null

        return response.data ? new this(response.data) : null
    }

}