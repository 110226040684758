import BasePolicy from './BasePolicy'

export default class CustomerPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onCustomer, __, company_id) {
        // if (authorizable.hasRole('admin'))
        //     return true
    }

    create(authorizable) {
        return true
    }

    edit(authorizable, onCustomer) {
        return true
        // return authorizable.hasRole('operator')
    }

    view(authorizable, onCustomer) {
        return true
    }

    list(authorizable, onCustomer) {
        return true
    }

    delete(authorizable, onCustomer) {
        return true
        // return authorizable.hasRole('operator')
    }

    history(authorizable, onCustomer) {
        return true
        // return authorizable.hasRole('operator')
    }
}