import axios from '@/services/axios'
import BaseModel from './BaseModel'
import { required, string, length } from '@/utils/validation'
import _ from 'lodash'

export default class PrizeCategory extends BaseModel {

    defaults() {
        return {
            id            : '',
            name          : '',
        }
    }

    validation() {
        return {
            name          : required.and(string).and(length(1, 191)),
        }
    }

    static async list(pagination) {
        let params = {}
        if (pagination)
            params = {
                page: pagination.page,
                per_page: pagination.per_page || pagination.perPage,
            }

        const response = await axios({
            url: `/prize/category`,
            method: 'get',
            params
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async get() {
        const data = this.getData()
        
        const response = await this.request({
            url: `prize/category/${data.id}`,
            method: 'get'
        })
        
        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async create() {
        const data = this.getData(['name'])

        const response = await this.request({
            url   : `/prize/category`,
            method: 'post',
            data,
        })

        if (!response || !response.data || response.status != 200)
        return

        this._fill(response.data)

        return response
    }

    async update() {
        let data = this.getData(['name'])

        let response = await this.request({
            url   : `/prize/category/${this.id}`,
            method: 'put',
            data,
        })

        if (!response || !response.data || response.status != 200)
            return

        if (response.data)
            this._fill(response.data)

        return response
    }

    async delete() {
        return await this.request({
            url   : `/prize/category/${this.id}`,
            method: 'delete',
        })
    }

}