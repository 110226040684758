export default [
    {
        name: 'welcome',
        path: '/bem-vindo',
        component: () => import(/* webpackChunkName: "welcome" */ '@/views/welcome/Welcome.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            title: 'welcome',
        },
    },
]