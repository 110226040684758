<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="24.738" height="16.492" viewBox="0 0 24.738 16.492"><path class="a" d="M17.136,13.712H3v2.356H17.136Zm0-4.712H3v2.356H17.136ZM3,20.78h9.424V18.424H3Zm22.971-5.3,1.767,1.767L19.5,25.492l-5.313-5.3,1.767-1.767L19.5,21.958Z" transform="translate(-3 -9)"/></svg>
</template>

<script>
export default {
    name: 'ListCheckIcon',
}
</script>

<style scoped lang="scss">
.a {
    fill: currentColor;
}
</style>