import Vue from 'vue'
// Bugsnag precisa ser iniciado antes de todo o resto para poder
// reportar erros que possam ocorrer nos módulos iniciais
import BugsnagService from '@/services/bugsnag'
BugsnagService.setup(Vue)

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import TiptapVuetify from './plugins/tiptap-vuetify'
import './utils/filters'
import i18n from './i18n'
import './assets/scss/index.scss'
import Services from '@/services'
import shared from './shared'

TiptapVuetify.setup(Vue, vuetify)

Vue.use(Services)
Vue.use(shared)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
