import LocaleService        from '@/services/locale'
import CustomizationService from '@/services/customization'
import IntegrationsService  from '@/services/integrations'
import Auth                 from '@/auth'
import $store               from '@/store'

export default async function () {     
    await LocaleService.setup(this)
    await CustomizationService.setup(this)
    await Auth.setup()
    await IntegrationsService.setup()

    // Carrega os níveis da empresa
    await $store.dispatch('level/fetchLevels')
}