export default {
    AlertModal: {
        confirm: 'Entendi',
        success: {
            title: 'Sucesso!',
            subtitle: 'Deu tudo certo com sua solicitação',
        },
        error: {
            title: 'Erro...',
            subtitle: 'Houve um erro ao processar sua solicitação',
        }
    },
    ConfirmationModal: {
        confirm: 'Sim',
        cancel: 'Não',
        subtitle: 'Confirme para concluir a ação',
    },
    SelectPrizeModal: {
        title: 'Selecione um prêmio',
    },
    ChooseImportType: {
        title: 'O que você quer importar?',
        types: {
            customer: {
                title: 'Clientes',
                subtitle: 'Importe clientes utilizando uma planilha',
            },
        },
    },
    LogDetailsModal: {
        title: 'Detalhes do log',
        request: { label: 'Requisição' },
        response: { label: 'Resposta' },
        misc: { label: 'Outros dados'  },
        rest_verb: { label: 'Verbo'  },
        message: { label: 'Mensagem'  },
    },
    CreateUserModal: {
        confirm: 'Criar usuário',
        cancel: 'Cancelar',
        title: 'Criar novo usuário',

        email_note: '*Um e-mail com as credenciais de acesso será enviado para esse endereço.',
    },
    CreateWebhookRegisterModal: {
        title: 'Registrar novo webhook',

        errors: {
            used_url: 'Já existe um webhook registrado para a URL',
        },
    },
    UpdateWebhookRegisterModal: {
        title: 'Atualizar registro de webhook',

        errors: {
            used_url: '@:modals.CreateWebhookRegisterModal.errors.used_url',
        },
    },
    DetailsWebhookRegisterModal: {
        title: 'Detalhes do registro de webhook',
    },
    LoadingModal: {
        wait: '@:forms.wait',
    }
}