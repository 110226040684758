// Initial state
const state = {
    isLoaded: false,
}

// Getters
const getters = {
    isLoaded: state => state.isLoaded,
}

// Mutations
const mutations = {
    SET_IS_LOADED(state, value = false) {
        state.isLoaded = value
    },
}

// Actions
const actions = {
    setIsLoaded({ commit }, value) {
        commit('SET_IS_LOADED', value)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
 