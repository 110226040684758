import moment from 'moment'
import $console from '@/services/console'
import { messages } from '@/utils/validation'
import { pt_br } from '@/utils/validation/locale'

export default new class LocaleService {
    constructor() {
        /**
         * Locale atual
         *
         * @type {string}
         */
        this.locale = null

        /**
         * Locale fallback
         *
         * @type {string}
         */
        this.fallbackLocale = 'pt-BR'
    }

    async setup() {
        // [TODO-L][FID-114] Enquanto não tem outros locale
        this.setLocale(this.fallbackLocale)

        moment.locale(this.getLocale())

        // Register the language bundle.
        messages.register(pt_br);

        // Set the locale.
        messages.locale('pt-br');
    }

    // Alias
    get() {
        return this.getLocale()
    }

    getLocale() {
        return this.locale || this.fallbackLocale
    }

    setLocale(value) {
        this.locale = value
    }

    changeLocale() {
        // [TODO-L][FID-114]
        $console.log('[TODO-L][FID-114] changeLocale')
    }
}