<template>
    <div>
        <BaseInput :disabled="attrs.disabled">
            <template #label>
                <div class="d-flex">
                    <label class="input-label secondary_font--text" 
                        for="input"
                    >{{ attrs.label }}</label>

                    <a class="ml-auto primary--text caption-font-size pointer"
                        v-if="search_link"
                        :href="search_link" 
                        target="_blank"
                        style="textDecoration: none"
                    >{{ link_title }}</a>
                </div>
            </template>

            <template #input>
                <v-text-field
                    ref="input"
                    class="postalcode-input"
                    solo flat
                    :value="localValue"
                    @input="onInput"
                    v-on="listeners"
                    v-bind="attrs"
                    v-mask="computedPostalCodeMask"
                    background-color="input_background_color"
                >
                </v-text-field>
            </template>
        </BaseInput>
    </div>
</template>

<script>
import BaseInput from './BaseInput'
import { mask }  from '@/utils/vue-the-mask'

export default {
    name: 'SharedPostalCodeInput',
    inheritAttrs: false,
    directives: { mask },
    components: { BaseInput },
    props: {
        value      : String,
        link_title : String,
        search_link: String,
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Guarda resultado com mask
            this.localValue = value

            // Remove a mask e
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            value = value.replace(/\D/g,'')

            // Só emite a alteração caso o valor sem mask seja de fato diferente
            if (this.value != value) {
                this.$emit('input', value ? value : null)
            }
        },

        focus() {
            this.$refs.input.focus()
        },
    },
    computed: {
        computedPostalCodeMask() {
            return this.$t('formats.cep_mask')
        },
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            // Só atualiza o localvalue se o conteúdo for diferente, ignorando a formatação
            if (typeof this.localValue == 'string' &&
                typeof value == 'string' &&
                this.localValue.replace(/\D/g,'') != value.replace(/\D/g,'')
            ) {
                this.localValue = value
            }
        },
    },
}
</script>

<style lang="scss">
.postalcode-input {
    .v-input__append-outer {
        margin: 0 !important;
        margin-top: .34rem !important;
    }
}
</style>