var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    staticClass: "cp-select-input",
    attrs: {
      label: _vm.attrs.label,
      disabled: _vm.attrs.disabled,
      width: _vm.width,
      "max-width": _vm.maxWidth
    },
    scopedSlots: _vm._u([
      {
        key: "input",
        fn: function() {
          return [
            _c(
              "v-select",
              _vm._g(
                _vm._b(
                  {
                    ref: "input",
                    attrs: {
                      solo: "",
                      flat: "",
                      value: _vm.computedValue,
                      items: _vm.items
                    },
                    on: { input: _vm.onInput },
                    scopedSlots: _vm._u(
                      [
                        _vm.hasSlot("append")
                          ? {
                              key: "append",
                              fn: function() {
                                return [_vm._t("append")]
                              },
                              proxy: true
                            }
                          : null,
                        _vm.hasSlot("prepend")
                          ? {
                              key: "prepend",
                              fn: function() {
                                return [_vm._t("prepend")]
                              },
                              proxy: true
                            }
                          : null,
                        _vm.hasSlot("append-outer")
                          ? {
                              key: "append-outer",
                              fn: function() {
                                return [_vm._t("append-outer")]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  "v-select",
                  _vm.attrs,
                  false
                ),
                _vm.listeners
              )
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }