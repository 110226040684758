export default {
    login     : 'Entrar',
    logout    : 'Sair',
    add       : 'Adicionar',
    new       : 'Novo',
    back      : 'Voltar',
    save      : 'Salvar',
    update    : 'Atualizar',
    cancel    : 'Cancelar',
    edit      : 'Editar',
    send      : 'Enviar',
    finish    : 'Finalizar',
    confirm   : 'Confirmar',
    delete    : 'Deletar',
    remove    : 'Remover',
    select    : 'Selecionar',
    close     : 'Fechar',
    import    : 'Importar',
    refresh   : 'Atualizar',
    click_here: 'clique aqui',
    filtrate  : 'Filtrar',
    enable    : 'Ativar',
    disable   : 'Desativar',
}