const mapping = {

    // Modelos

    DEFAULT_CLR_ID: 'default',
    DEFAULT_TYP_ID: 'default',

    // Integrações

    INTEGRATIONS_KEY_SELF_COMPANY_TOKEN: 'SELF_COMPANY_TOKEN',
    INTEGRATIONS_KEY_CPLUG_SUBSCRIPTION: 'CPLUG_SUBSCRIPTION',

    // Relatórios

    REPORT_DEFAULT_PER_PAGE: 25,

    // Paginação

    PAGINATION_MAX_PER_PAGE: 5000,

    DATETIME_TZ_FORMAT: 'YYYY-MM-DDTHH:mm:ssZ',

    // Sistemas de níveis disponíveis
    LOOKBACK_LEVELS_SYSTEM  : 'LOOKBACK',
    RENOVATION_LEVELS_SYSTEM: 'RENOVATION',

    // VOUCHER TYPES
    // Tipos de geração de voucher
    'VOUCHER_CREATED_BY_PURCHASE': 'PURCHASE', // Quando o próprio cliente gasta seus pontos para comprar um voucher
    'VOUCHER_CREATED_BY_LEVEL_UP': 'LEVEL_UP', // Quando o cliente ganha o voucher a partir da subida de um nível
    // futuramente...
    // 'VOUCHER_CREATED_BY_ADMIN': 'ADMIN', // Quando o cliente ganha um voucher a partir de uma operação do painel admin
    // 'VOUCHER_CREATED_BY_OFFER': 'OFFER', // Quando o cliente ganha um voucher a partir de uma promoção
    'VOUCHER_MIN_CODE_LENGTH': 6,

    // Tipos de promoções
    'PROMOTION_TYPE_BANNER_CLICK'            : 'BANNER_CLICK',
    'PROMOTION_TYPE_INTERNAL_LINK'           : 'INTERNAL_LINK',
    'PROMOTION_TYPE_PROFILE_UPDATE'          : 'PROFILE_UPDATE',
    'PROMOTION_FILTER_TYPE_NEW_REGISTER'     : 'NEW_REGISTER',
    'PROMOTION_FILTER_TYPE_ACTIVATIONS_LIMIT': 'ACTIVATIONS_LIMIT',
    'PROMOTION_FILTER_TYPE_COMPLETE_PROFILE' : 'COMPLETE_PROFILE',
    'PROMOTION_ACTION_TYPE_GENERATE_POINTS'  : 'GENERATE_POINTS',

    // Origens possíveis para gerações
    'API_POINTS_GENERATE_GENERATOR_ORIGIN': 'api:/points/generate',
    'PROMOTION_GENERATOR_ORIGIN'          : 'promotion',

    // Configs de expiração de cookies
    'COOKIE_EXPIRES_IN_YEARS_AUTHENTICATED': 3,                                                   // 3 anos
    'COOKIE_EXPIRES_IN_YEARS_GUEST'        : 1,                                                   // 1 ano
    'COOKIE_POLICY_URL'                    : 'https://institucional.locaweb.com.br/politicas/',   // no admin o link para política de cookies é estático

    // Tipos de login disponíveis para o customer
    'CUSTOMER_LOGIN_TYPE_PRE'     : 'PRE',
    'CUSTOMER_LOGIN_TYPE_EMAIL'   : 'EMAIL',
    'CUSTOMER_LOGIN_TYPE_GOOGLE'  : 'GOOGLE',
    'CUSTOMER_LOGIN_TYPE_PHONE'   : 'PHONE',
    'CUSTOMER_LOGIN_TYPE_FACEBOOK': 'FACEBOOK',

    // Configurações dos webhooks
    'WEBHOOK_REGISTERS_MAX_NUMBER'  : 10,
    'WEBHOOK_MAX_ADDITIONAL_HEADERS': 10,
}

export default new class ConstantsService {

    /**
     * Retorna o valor de uma constante
     *
     * @param {string} key chave da constante
     */
    getConstant(key) {
        let value = mapping[key]

        if (!value)
            throw new Error(`Constant ${key} is not defined.`)

        return value
    }

}