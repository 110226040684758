<template>
    <div>
        <v-subheader class="mb-4 mb-md-0 pl-0 section-subtitle">{{ $t(`integrations.${integration.key}.settings.subheader`) }}</v-subheader>

        <p v-html="$t(`integrations.${integration.key}.settings.description`)"></p>

        <v-alert type="error" v-if="hasError">
            {{ $t(`integrations.${integration.key}.settings.error`) }}
        </v-alert>

        <v-progress-linear v-else-if="loading"/>

        <template v-else>
            <v-simple-table v-if="hasTokens">
                <thead>
                    <tr>
                    <th class="text-left">{{ $t(`integrations.${integration.key}.settings.table.header.jti`) }}</th>
                    <th class="text-left">{{ $t(`integrations.${integration.key}.settings.table.header.iat`) }}</th>
                    <th class="text-left">{{ $t(`integrations.${integration.key}.settings.table.header.status`) }}</th>
                    <th class="text-left">{{ $t('tables.actions') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <TokenTableItem v-for="item in tokens" :key="item.name"
                        :item="item"
                        :integration="integration"
                        @update="fetchTokens"
                    />
                </tbody>
            </v-simple-table>

            <v-btn :color="isLightTheme ? 'success' : 'primary'"
                @click="generateToken"
                depressed block outlined
            >{{ $t(`integrations.${integration.key}.settings.generate_token`) }}</v-btn>
        </template>

    </div>
</template>

<script>
import BaseIntegrationModel from '../BaseIntegrationModel'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RateColor            from '@/shared/mixins/RateColor'
import IntegratorToken      from '@/models/IntegratorToken'
import TokenTableItem       from './TokenTableItem'

export default {
    name: 'SelfCompanyTokenSettingsComponent',
    props: {
        integration: {
            required: true,
            type: BaseIntegrationModel,
        },
    },
    components: { TokenTableItem },
    mixins: [ HasErrorHandlerMixin, RateColor ],
    data: vm => ({
        loading : false,
        hasError: false,
        tokens  : [],
    }),
    async created() {
        await this.fetchTokens()
    },
    methods: {
        async fetchTokens() {
            this.loading = true
            this.tokens  = await IntegratorToken.list()
                .catch(this.preErrorHandler)
            this.loading = false
        },
        async generateToken() {
            let allowed = await new Promise((resolve) => {
                this.$bus.$emit('confirm', this.$t(`integrations.${this.integration.key}.settings.generate_token_confirmation`), resolve, true)
            })

            if (!allowed)
                return

            this.loading = true
            let response = await IntegratorToken.create()
                .catch(this.preErrorHandler)
            this.loading = false

            if (!response)
                return

            let token     = response.data.token
            let token_jti = response.data.data.jti

            await new Promise((resolve) => {
                this.$bus.$emit('alert', this.$t(`integrations.${this.integration.key}.settings.generate_token_success`, { token, token_jti }), 'success', resolve, true)
            })

            await this.fetchTokens()
        },

        preErrorHandler(e) {
            this.loading  = false

            let status = this.$lodash.get(e, 'response.status')

            if (status == 422) {
                const definedErrorTypes = ['max-active-tokens-reached']
                let errorType = e.response.data.type

                if (definedErrorTypes.includes(errorType)) {
                    this.$bus.$emit('alert', this.$t(`integrations.${this.integration.key}.settings.defined_errors.${errorType}`), 'error')
                    return
                }
            }

            this.hasError = true
            this.errorHandler(e)
        },
    },
    computed: {
        hasTokens() {
            return !!this.tokens.length
        },
    },
}
</script>

<style scoped lang="scss">
.section-subtitle {
    font-weight: bold;
}
</style>