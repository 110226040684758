export default [
    {
        path: '/configuracoes',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/settings/Tabs.vue'),
        props: true,
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'settings',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Configurações',
                    disabled: true
                },
            ],
        },
    },
]