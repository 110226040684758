import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.js$/i)
    const messages = {}

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key).default
        }
    })

    return messages
}

export default new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'pt-br',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'pt-br',
    messages: loadLocaleMessages(),

    pluralizationRules: {
        'pt-br': (choice) => {
            if (choice >= 2)
                return 2
            else if (choice > 0)
                return 1

            return 0
        },
    },
})
