export default [
    {
        path: '/tipos',
        name: 'types',
        redirect: 'tipos/lista'
    },
    {
        path: '/tipos/lista',
        name: 'types.list',
        component: () => import(/* webpackChunkName: "types.list" */ '@/views/types/List.vue'),
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            title: 'types.list',
            can: 'list Type',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' }
                },
                {
                    text: 'Regras de cálculo',
                    disabled: true
                }
            ]
        },
    },
]