import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

export default new class TiptapBuilder {
    setup(Vue, vuetify) {
        Vue.use(TiptapVuetifyPlugin, {
            vuetify,
            iconsGroup: 'fa',
        })
    }
}