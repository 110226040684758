<template>
    <v-snackbar
      v-model="isOpen"
      :color="color"
      eager
    >
      {{ text }}
      <!-- <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn> -->
    </v-snackbar>
</template>

<script>
export default {
    name: 'Snackbar',
    data: vm => ({
        isOpen: false,
        text: '',
        color: '',
    }),
    methods: {
        new(message, color, timeToClose = 2000) {
            
            this.text = message
            this.color = color

            this.isOpen = true

            setTimeout(() => {
                this.isOpen = false
                this.text = ''
                this.color = ''
            }, timeToClose)
        },
    },
}
</script>