var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            transition: "scale-transition",
            "min-width": "290px",
            "offset-y": "",
            bottom: "",
            right: ""
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                return [
                  _c(
                    "cp-text-field",
                    _vm._g(
                      _vm._b(
                        {
                          directives: [
                            {
                              name: "mask",
                              rawName: "v-mask",
                              value: _vm.mask,
                              expression: "mask"
                            }
                          ],
                          attrs: {
                            "return-masked-value": "",
                            "error-messages": _vm.computedErrorMessages,
                            required: _vm.required,
                            rules: _vm.rules
                          },
                          on: { blur: _vm.onBlur, focus: _vm.onFocus },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "append",
                                fn: function() {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          staticClass: "clickable",
                                          attrs: { small: "" }
                                        },
                                        on
                                      ),
                                      [_vm._v("fas fa-fw fa-calendar")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.computedTextInput,
                            callback: function($$v) {
                              _vm.computedTextInput = $$v
                            },
                            expression: "computedTextInput"
                          }
                        },
                        "cp-text-field",
                        _vm.attrs,
                        false
                      ),
                      _vm.listeners
                    )
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.calendar.open,
            callback: function($$v) {
              _vm.$set(_vm.calendar, "open", $$v)
            },
            expression: "calendar.open"
          }
        },
        [
          _c("v-date-picker", {
            ref: "picker",
            attrs: {
              "no-title": "",
              locale: _vm.$locale.get(),
              rules: _vm.rules,
              reactive: _vm.reactive,
              required: _vm.required
            },
            model: {
              value: _vm.computedCalendarInput,
              callback: function($$v) {
                _vm.computedCalendarInput = $$v
              },
              expression: "computedCalendarInput"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }