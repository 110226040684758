import BasePolicy from './BasePolicy'
import $store from '@/store'

export default class CompanyPolicy extends BasePolicy {

    beforeAll(verb, authorizable, onCompany, __, company_id) {
        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (this.is_parent_company_selected && authorizable && authorizable.hasRole('admin'))
            return true
    }

    create(authorizable) {
        return false
    }

    edit(authorizable, onCompany) {
        return false
    }

    view(authorizable, onCompany) {
        return false
    }

    list(authorizable, onCompany) {
        return false
    }

    delete(authorizable, onCompany) {
        return false
    }

    history(authorizable, onCompany) {
        return false
    }
}