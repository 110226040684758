<template>
    <BaseInput
        :label="attrs.label"
        :disabled="attrs.disabled"
        :width="width"
        :max-width="maxWidth"
        class="cp-select-input"
    >
        <template #input>
            <v-select
                ref="input"
                solo flat
                :value="computedValue"
                :items="items"
                @input="onInput"
                v-on="listeners"
                v-bind="attrs"
            >
            
                <template v-if="hasSlot('append')" #append>
                    <slot name="append"/>
                </template>

                <template v-if="hasSlot('prepend')" #prepend>
                    <slot name="prepend"/>
                </template>

                <template v-if="hasSlot('append-outer')" #append-outer>
                    <slot name="append-outer"/>
                </template>

            </v-select>
        </template>
    </BaseInput>
</template>

<script>
import BaseInput from './BaseInput'
import HasSlot from '../../mixins/HasSlot'

export default {
    name: 'SelectInput',
    inheritAttrs: false,
    components: { BaseInput },
    mixins: [ HasSlot ],
    props: {
        value   : [ String, Number, Object, Array ],
        width   : String,
        maxWidth: String,
        items   : {
            type: Array,
        },
    },
    data: vm => ({
        localValue: vm.value,
    }),
    methods: {
        onInput(value) {
            // Retira as mensagens de erro caso o input não formatado tenha algum valor
            
            this.$emit('input', value ? value : null)
        },

        focus() {
            this.$refs.input && this.$refs.input.focus()
        },
    },
    computed: {
        computedValue() {
            return this.localValue
        },

        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { input, ...listeners } = this.$listeners
            return listeners
        },
        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { value, width, maxWidth, ...attrs } = this.$attrs
            return { ...attrs }
        },
    },
    watch: {
        value(value) {
            this.localValue = value
        },
    },
}
</script>

<style lang="scss">
.cp-select-input {
    .v-input__append-outer {
        margin-top: 6px !important;
    }
}
</style>