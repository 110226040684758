var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "feather feather-package",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    },
    [
      _c("line", { attrs: { x1: "16.5", y1: "9.4", x2: "7.5", y2: "4.21" } }),
      _c("path", {
        attrs: {
          d:
            "M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
        }
      }),
      _c("polyline", { attrs: { points: "3.27 6.96 12 12.01 20.73 6.96" } }),
      _c("line", { attrs: { x1: "12", y1: "22.08", x2: "12", y2: "12" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }