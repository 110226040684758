import axios from 'axios'

window.axios = axios

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// /**
//  * Next we will register the CSRF Token as a common header with Axios so that
//  * all outgoing HTTP requests automatically have it attached. This is just
//  * a simple convenience so we don't have to attach every token manually.
//  */

// let token = document.head.querySelector('meta[name="csrf-token"]')

// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
// } else if (process.env.NODE_ENV == 'staging') {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token')
// }

// // [DEV] Quando está em modo desenvolvimento, pega o token via api
// if (!token && process.env.NODE_ENV != 'production' && process.env.NODE_ENV != 'staging') {
//     token = axios.get('/dev/csrf_token').then(response => {
//         window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data
//     })
// }

if (process.env.VUE_APP_BACKEND_API_URL)
    window.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL

export default window.axios