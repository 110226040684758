<template>
    <ImportantNotifications class="bt"
        :title="title"
        :description="description"
        :buttonConfirm="agreeAndClose"
        @confirm="acceptTerms"
        :loading="loading"
    >
        <template #additionalContent>
            <a class="primary--text font-weight-bold mx-1"
               :href="cookies_policy_url"
               target="_blank"
            >{{ cookies_policy }}</a>
        </template>
    </ImportantNotifications>
</template>

<script>
import ImportantNotifications from '@/shared/components/banner/ImportantNotifications'

export default {
    components: { ImportantNotifications },
    props: {
        cookies_policy_url: {
            type: String,
            default: '',
        },
        loading: Boolean,
    },
    data: vm => ({
        title          : vm.$t('cookiersNotification.title'),
        description    : vm.$t('cookiersNotification.description'),
        cookies_policy : vm.$t('cookiersNotification.cookies_policy'),
        agreeAndClose  : vm.$t('cookiersNotification.agreeAndClose'),
    }),
    methods: {
        acceptTerms() {
            this.$emit('accept-terms')
        }
    },
}
</script>

<i18n>
{
    "pt-br": {
        "cookiersNotification": {
            "title"             : "Uso de cookies",
            "description"       : " - Guardamos estatísticas de visitas para melhorar sua experiência de navegação. Ao continuar, você concorda com nossa",
            "cookies_policy"    : "política de cookies.",
            "agreeAndClose"     : "Concordar e fechar"
        }
    }
}
</i18n>
