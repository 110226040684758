import SharedCookiesAgreement from '@/shared/models/CookiesAgreement'
import _                      from 'lodash'

export default class CookiesAgreement extends SharedCookiesAgreement {

    defaults() {
        return {
            ...super.defaults(),
        }
    }

    async create() {
        let response = await this.request({
            url: `/admin/${this.user_id}/cookies-agreement`,
            method: 'post',
        })

        if (!response || response.status != 201)
            return

        return response
    }

    async delete() {
        let response = await this.request({
            url: `/admin/${this.user_id}/cookies-agreement`,
            method: 'delete',
        })

        if (!response || response.status != 204)
            return

        return response
    }
}