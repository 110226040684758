<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="14.409" height="20.25" viewBox="0 0 14.409 20.25"><path class="a" d="M20.445,3.375H9.151A1.556,1.556,0,0,0,7.594,4.933V22.067a1.556,1.556,0,0,0,1.558,1.558H20.445A1.556,1.556,0,0,0,22,22.067V4.933A1.556,1.556,0,0,0,20.445,3.375Zm-.584,13.24V20.51a1.165,1.165,0,0,1-1.168,1.168h0a1.165,1.165,0,0,1-1.168-1.168V16.615a1.165,1.165,0,0,1,1.168-1.168h0A1.165,1.165,0,0,1,19.861,16.615Zm0-3.894h0a1.165,1.165,0,0,1-1.168,1.168h0a1.165,1.165,0,0,1-1.168-1.168h0a1.165,1.165,0,0,1,1.168-1.168h0A1.165,1.165,0,0,1,19.861,12.721ZM15.966,20.51h0A1.165,1.165,0,0,1,14.8,21.678h0A1.165,1.165,0,0,1,13.63,20.51h0A1.165,1.165,0,0,1,14.8,19.341h0A1.165,1.165,0,0,1,15.966,20.51Zm0-3.894h0A1.165,1.165,0,0,1,14.8,17.784h0a1.165,1.165,0,0,1-1.168-1.168h0A1.165,1.165,0,0,1,14.8,15.447h0A1.165,1.165,0,0,1,15.966,16.615Zm0-3.894h0A1.165,1.165,0,0,1,14.8,13.889h0a1.165,1.165,0,0,1-1.168-1.168h0A1.165,1.165,0,0,1,14.8,11.553h0A1.165,1.165,0,0,1,15.966,12.721ZM12.072,20.51h0A1.165,1.165,0,0,1,10.9,21.678h0A1.165,1.165,0,0,1,9.736,20.51h0A1.165,1.165,0,0,1,10.9,19.341h0A1.165,1.165,0,0,1,12.072,20.51Zm0-3.894h0A1.165,1.165,0,0,1,10.9,17.784h0a1.165,1.165,0,0,1-1.168-1.168h0A1.165,1.165,0,0,1,10.9,15.447h0A1.165,1.165,0,0,1,12.072,16.615Zm0-3.894h0A1.165,1.165,0,0,1,10.9,13.889h0a1.165,1.165,0,0,1-1.168-1.168h0A1.165,1.165,0,0,1,10.9,11.553h0A1.165,1.165,0,0,1,12.072,12.721ZM9.736,8.827V6.49a.781.781,0,0,1,.779-.779h8.567a.781.781,0,0,1,.779.779V8.827a.781.781,0,0,1-.779.779H10.514A.781.781,0,0,1,9.736,8.827Z" transform="translate(-7.594 -3.375)"/></svg>
</template>

<script>
export default {
    name: 'CalculatorIcon',
}
</script>

<style scoped lang="scss">
.a {
    fill: currentColor;
}
</style>

