import BaseModel from '@/models/BaseModel'

export default class Report extends BaseModel {

    defaults() {
        return {
            id        : '',
            created_at: '',
            updated_at: '',
        }
    }

    validation() {
        return {
        }
    }

}