export default {
    dataIterator: {
        rowsPerPageText: 'linhas por página:',
        rowsPerPageAll: 'Todas',
        pageText: '{0} - {1} de {2}',
        noResultsText: 'Nenhum registro correspondente encontrado',
        nextPage: 'Próxima página',
        prevPage: 'Página anterior',
        loadingText: 'Carregando...',
    },
    dataTable: {
        rowsPerPageText: 'linhas por página:'
    },
    dataFooter: {
        itemsPerPageText: 'itens por página:',
        itemsPerPageAll: 'mostrar tudo',
        pageText: '.',
    },
    noDataText: 'Sem dados para mostrar',
}