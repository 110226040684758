import BaseModel from '@/models/BaseModel'
import _ from 'lodash'

export default class CompanyJson extends BaseModel {

    defaults() {
        return {
            id                          : '',
            official_program_name       : '',
            program_name                : '',
            logo_url                    : '',
            blocked_at                  : '',
            favicon_url                 : '',
            landing_page_domain         : '',
            background_color            : '',
            font_color                  : '',
            primary_color               : '',
            background_url              : '',
            frontend_domain             : '',
            admin_domain                : '',
            display_mode                : '',
            hide_cashback               : '',
            disable_cashback            : '',
            hide_points                 : '',
            disable_action_notifications: '',

            frontend: {
                register: {
                    opt_in_marketing: {
                        label: '',
                    },
                },
            },
            home                 : {
                title: '',
                hero    : {
                    title: '',
                    subtitle: '',
                    description: '',
                    custom_class: '',
                    background_url: '',
                    customHeight: '',
                    height: ''
                },
                features: {
                    title: '',
                    subtitle: ''
                },
            },
            banner: {
                title: ''
            },
            how_it_works: {
                title: '',
                hero: {
                    title: '',
                    subtitle: '',
                    description: '',
                    custom_class: '',
                    background_url: '',
                    customHeight: '',
                    height: '',
                },
                features: {
                    description: ''
                }
            },
            landing_page_colors:{
                nav_font_color       : '',
                nav_background_color : '',
                title_color          : '',
                card_font_color      : '',
                card_background_color: '',
            },
            description: {
                title      : '',
                description: '',
            },
            faq: {
                title: '',
                items: [
                    {
                        question: '',
                        answer: ''
                    },
                    {
                        question: '',
                        answer: ''
                    },
                    {
                        question: '',
                        answer: ''
                    },
                    {
                        question: '',
                        answer: ''
                    },
                    {
                        question: '',
                        answer: ''
                    }
                ]
            },
            legal: {
                cookie_policy_url : '',
                privacy_policy_url: '',
                terms_url         : '',
                custom_terms_html : '',
            },
            footer: {
                mark: {
                    text: '',
                    url : ''
                },

                text : '',
                links: [],
            },
            custom_emails: {
                enabled: false,
                successful_transaction: {
                    title: '',
                    body: '',
                },
                customer_level_updated_period_update: {
                    title: '',
                    body: '',
                },
                customer_level_updated_renovation: {
                    title: '',
                    body: '',
                },
                customer_level_updated_setup: {
                    title: '',
                    body: '',
                },
                customer_level_updated_simple: {
                    title: '',
                    body: '',
                },
                customer_level_updated_system_update: {
                    title: '',
                    body: '',
                },
                promotion_triggered: {
                    title: '',
                    body: '',
                },
                vouchers_received: {
                    title: '',
                    body: '',
                },
            },
        }
    }

    // Retorna o json completo com todas as chaves com valores default
    completeJson(data, defaults = this.defaults()) {
        let complete = {}

        if (!data)
            return defaults

        for (let key in defaults) {
            complete[key] = _.isNil(data[key]) ? defaults[key] : data[key]

            if (typeof complete[key] == 'object' && !Array.isArray(complete[key]) && !_.isEmpty(complete[key]))
                complete[key] = this.completeJson(complete[key], defaults[key])
        } 

        return complete
    }

    static displayModes() {
        return [
            { value: 'POINTS', text: 'Pontos' }, // [TODO] Traduzir e utilizar constantes
            { value: 'PERCENTAGE', text: 'Porcentagem' },
            { value: 'CASH', text: 'Dinheiro' }
        ]
    }

    static mapDisplay(display) {
        const filtered = (this.displayModes()).find(v => v.value == display)

        return filtered ? filtered.value : 'POINTS'
    }

    // Não precisa de validação, pois não havera o cadastro nesta app
}