import BasePolicy from './BasePolicy'

export default class IntegratorTokenPolicy extends BasePolicy {
    beforeAll(verb, authorizable, onType, __, company_id) {
        
        if (authorizable && authorizable.hasRole(['admin']))
            return true
    }

    list(authorizable, onType) { return false }

    create(authorizable) { return false }

    revoke(authorizable, onType) { return false }

    activate(authorizable, onType) { return false }
}