import BaseModel from '@/models/BaseModel'
import Level from '@/models/Level'

export default class TransitionLevelData extends BaseModel {

    defaults() {
        return {
            id                : '',
            accumulated_amount: 0,
            calculated_at     : '',
        }
    }

    relationships() {
        return {
            level: { class: Level, defaultValue: undefined },
        }
    }
}