import Vue   from 'vue'
import _     from 'lodash'
import axios from '@/services/axios' // [TODO] Mover para shared

/**
 * Esta classe deve ser usada como pai para todos os modelos
 */
export default class BaseModel {
    constructor(data = {}) {
        // Preenche os atributos passados no construtor
        this._fill(data || {})
    }

    // Modelos

    defaults() {
        return {}
    }

    /**
     * Contém as definições de relações deste modelo com outros modelos.
     *
     * @returns {object}
     */
    relationships() {
        return {
            // Exemplos
            // colecao_1pN: { defaultValue: [],   class: [Classe], fields: ['campo1', 'campo2'] },
            // realcao_1p1: { defaultValue: null, class: Classe,   fields: ['campo1', 'campo2'] },
        }
    }

    validation() {
        return {}
    }

    // Métodos úteis

    _fill(data = {}) {
        let defaults = this.defaults()

        for (let field in defaults) {
            this[field] = _.defaultTo(data[field], defaults[field])
        }
        
        let relationships = this.relationships()
        for (let relationshipName in relationships) {
            let relationshipValue = relationships[relationshipName]

            if (_.isNil(data[relationshipName])) {
                this[relationshipName] = relationshipValue.defaultValue
            } else {
                let relationshipClass = relationshipValue.class

                const mapChild = (data2) => {
                    if (_.isEmpty(relationshipValue.mapChild))
                        return data2

                    for (let key in relationshipValue.mapChild) {
                        data2[relationshipValue.mapChild[key]] = this[key]
                    }

                    return data2
                }

                if (Array.isArray(relationshipClass)) {
                    this[relationshipName] = data[relationshipName].map(v => new relationshipClass[0](mapChild(v)))
                } else {
                    this[relationshipName] = new relationshipClass(mapChild(data[relationshipName]))
                }
            }
        }
    }

    _ignoredDataFields() {
        return []
    }

    /**
     * Habilita a validação e verifica se há erros
     *
     * @return {boolean}
     */
    async validate() {
        // Habilita a validação das regras neste modelo
        this.touch()

        // Verifica se há algum erro de validação
        return !_.size(_.filter(this.errors, (value) => value.length))
    }

    touch() {
        Vue.set(this, '_isTouched', true)
    }

    untouch() {
        Vue.set(this, '_isTouched', false)
    }

    // Retira todas as strings vazias de data, inclusive de níveis mais profundos
    static clearData(data, deep = true) {
        for (let key in data) {
            if (data[key] === '' || _.isNil(data[key]))
                delete data[key]
            else if (deep && data[key] && typeof(data[key]) == 'object') 
                this.clearData(data[key])
        } 
    }

    /**
     * Retorna um array instâncias do modelo construído com os dados passados em items, executanto,
     * para cada um dos modelos, o callback antes de adicionar ao array de retorno
     * 
     * @param {Object[]} items    Array com os objetos que devem ser hidratar os modelos
     * @param {Function} callback Função executada recebendo como modelo cada um dos argumentos
     *
     * @return {this[]}
     */
    static async hydrateWithCallback(items = [], callback = async () =>  null) {
        let hydratedArray = []
        for (let item of items) {
            let itemModel = new this(item)

            await callback(itemModel)
            hydratedArray.push(itemModel)
        }

        return hydratedArray
    }

    get rules() {
        if (!this._isTouched)
            return {}

        let validation = this.validation()

        for (let key in validation)
            validation[key] = _.castArray(validation[key])

        return validation
    }

    get errors() {
        if (!this._isTouched)
            return {}

        let errors = {}

        let validation = this.validation()
        for (let field in validation) {
            let value = this[field]
            let rules = _.castArray(validation[field])
            let fieldErrors = []

            for (let rule of rules) {
                fieldErrors.push(rule(value, field, this))
            }

            fieldErrors = fieldErrors.filter(v => typeof v == 'string')

            errors[field] = fieldErrors
        }

        return errors
    }

    /**
     * Avalia os campos passados como parâmetro e retorna se algum deles tem erros ou não.
     *
     * @param {array} fields array de campos a serem avaliados se contêm erros
     * @returns {boolean}
     */
    hasErrors(fields = []) {
        if (typeof fields == 'string')
            fields = [fields]
        else if (!Array.isArray(fields))
            throw new TypeError('Expected array, got: ' + typeof fields)

        let flattenedErrors = _.flatten(Object.values(_.pick(this.errors, fields)))

        return !!flattenedErrors.length
    }

    clone() {
        return _.cloneDeep(this)
    }

    /**
     * Retorna os atributos definidos no modelo e passados como parâmetros, além de suas relações.
     *
     * @param   {array}  attributes Atributos a serem retornados
     * @returns {object}
     */
    getData(attributes = []) {
        let data = {}

        let defaults = this.defaults()
        for (let field in defaults) {
            if (
                this[field] !== undefined && this[field] !== '' &&
                (!Array.isArray(this[field]) || this[field].length) &&
                (!attributes.length || attributes.includes(field))
            )
            data[field] = this[field]
        }

        let relationships = this.relationships()
        for (let relationshipName in relationships) {
            if (!attributes.length || attributes.includes(relationshipName)) {
                let relValue = this[relationshipName]

                if (!_.isNil(relValue) && (!Array.isArray(relValue) || relValue.length)) {
                    if (Array.isArray(relValue))
                        data[relationshipName] = relValue.map(v => v instanceof BaseModel ? v.getData() : v)
                    else
                        data[relationshipName] = relValue instanceof BaseModel ? relValue.getData() : relValue
                }
            }
        }

        return data
    }

    /**
     * Retorna os atributos definidos no modelo e passados como parâmetros como Numbers
     *
     * @param {object}  data       Objeto que deve ter os atributos passados para Number
     * @param {array}   attributes Atributos a serem passados para Number
     * @return {object}
     */
    toNumber(data, attributes = []) {
        attributes.forEach(attribute => {
            if (data[attribute])
                data[attribute] = Number(data[attribute])
        })

        return data
    }

    async request(axiosConfig) {
        return await axios(axiosConfig)
    }

    static async request(axiosConfig) {
        return await axios(axiosConfig)
    }

    /**
     * Converte um array de objetos em um array de modelos
     *
     * @param {object[]} array Vetor de objetos a serem hidratados
     */
    static hydrate(array) {
        // Obs. não pode dar require no arquivo pois gera referência circular
        const Collection = require('./Collection').default
        let ret = new Collection
        if (!Array.isArray(array))
            throw new TypeError('.hydrate() expects an array as argument')

        // Passa pelo array verificando o tipo e adicionando ao array de resposta como modelo
        for (let item of array) {
            if (typeof item != 'object')
                throw new TypeError('.hydrate() expects an array of objects only')
            ret.push(new this(item))
        }

        return ret
    }
}