export default {
    name: 'vouchers.withdraw',
    path: '/vouchers/retirar/:id?',
    component: () => import(/* webpackChunkName: "vouchers.withdraw" */ '@/views/vouchers/WithdrawView.vue'),
    props: true,
    meta: {
        requiresAuthentication: true,
        requiresSelectedCompany: true,
        title: 'vouchers.withdraw',
        can: 'withdraw Voucher',
        fail: '/painel',
        breadcrumbs: [
            {
                text: 'Dashboard',
                to: { name: 'home' }
            },
            {
                text: 'Retirar voucher',
                disabled: true
            }
        ]
    },
}