import constants from '@/services/constants'

export default {
    Generator: {
        title: {
            [`${constants.getConstant('API_POINTS_GENERATE_GENERATOR_ORIGIN')}_origin`]: 'Gerou {points_amount} pts ({points_value}) na compra de {value} em <b>{subsidiary_name}</b>',
            [`${constants.getConstant('PROMOTION_GENERATOR_ORIGIN')}_origin`]          : 'Gerou {points_amount} pts ({points_value}) ao participar de uma promoção',
            default_origin                                                             : 'Gerou {points_amount} pts ({points_value})',
        },
        title_zero: 'Esta compra não gerou pontos',
        subtitle: {
            reversal: 'Esta geração de pontos foi cancelada.',
        },
        action: 'Você recebeu',
    },
    Consumption: {
        title: 'Utilizou {points_amount} pts em <b>{subsidiary_name}</b> por {points_value}',
        subtitle: {
            reversal: 'Este consumo de pontos foi cancelado.',
        },
        action: 'Você trocou',
    },
    LogEntry: {
        severity: {
            'log'    : 'log',
            'debug'  : 'debug',
            'error'  : 'erro',
            'warning': 'alerta',
            UNKNOWN  : 'Desconhecido',
        },
    },
    ImportEntry: {
        status: {
            'CREATED'          : 'Criada',
            'INITIALIZED'      : 'Iniciando',
            'ERROR'            : 'Erro',
            'UNKNOWN'          : 'Desconhecido',
            'PROCESS_PROGRESS' : 'Processando',
            'VALIDATE_PROGRESS': 'Validando',
            'DONE'             : 'Finalizada',
        },
        types: {
            customer                : 'Clientes',
            'prizes/available_codes': 'Códigos de voucher',
            UNKNOWN                 : 'Desconhecido',
        },
        errors: {
            'ROW_VALIDATION'         : 'Há um erro validação no campo "{field}" (linha {currentRow} coluna {currentCol}).',
            'DUPLICATED_COLUMN_VALUE': 'Existem valores repetidos no campo "{field}" (coluna {columnIndex}).',
            'ROW_LIMIT'              : 'A planilha enviada contém mais linhas que o limite de {limit}. Por favor, divida a planilha em várias contendo no máximo {limit} linhas cada uma.',
            'HEADERS_LENGTH_MISMATCH': 'A planilha enviada contém {actualLength} colunas, mas a importação espera {expected} colunas. Corriga a formatação da planilha e tente novamente.',
            UNKNOWN                  : 'Erro desconhecido.',
        },
    },
    Reversal: {
        title: {
            GNR: 'Cancelamento da geração de {points_amount} pontos no valor de {points_value} em <b>{subsidiary_name}</b>.',
            CNS: 'Cancelamento do consumo de {points_amount} pontos no valor de {points_value} em <b>{subsidiary_name}</b>.',
            default: 'Cancelamento.',
        },

        action: {
            GNR: 'Cancelamento',
            CNS: 'Estorno',
        },
    },
    Voucher: {
        is_purchased    : 'compra com pontos',
        is_not_purchased: 'subida de nível',

        type: {
            [constants.getConstant('VOUCHER_CREATED_BY_PURCHASE')]: 'compra com pontos',
            [constants.getConstant('VOUCHER_CREATED_BY_LEVEL_UP')]: 'subida de nível',
            default: '',
        }
    },
    CustomerHistoryRecord: {
        chr_type_label: {
            GNR: 'geração',
            CNS: 'consumo',
            RVS: 'estorno',
            default: 'desconhecido',
        },
    },
    WebhookRegister: {
        labels: {
            types: {
                customer_created: 'Cliente criado',
                customer_updated: 'Cliente atualizado',
            },

            auth_types: {
                bearer_token: 'Bearer token',
            },
        },

        errors: {
            invalid_types: 'Tipos selecionados inválidos',
            too_many_headers: 'Número máximo de headers ultrapassado',
            headers_missing_key_value: 'Todos os headers devem ter chave e valor',
            invalid_auth_type: 'Tipo de autenticação inválido',
            missing_bearer_token: 'Obrigatório para o tipo de autenticação bearer token',
        },
    },
}