import TransitionLevelData from '@/models/TransitionLevelData'
import BaseModel from '@/models/BaseModel'

export default class ConsolidatedLevelData extends BaseModel {

    defaults() {
        return {
            id             : '',
            consolidated_at: '',
            reseted_at     : '',
        }
    }

    relationships() {
        return {
            level_data      : { class: TransitionLevelData, defaultValue: undefined },
            reset_level_data: { class: TransitionLevelData, defaultValue: undefined },
        }
    }
}