import BaseModel   from './BaseModel'
import Prize       from './Prize'
import Company     from './Company'
import VoucherCode from './VoucherCode'
// import Consumption from '@/models/Consumption' // [TODO] Alterar

export default class Voucher extends BaseModel {

    defaults() {
        return {
            id               : null,
            customer_id      : null,
            customer_document: null,
            created_at       : null,
            expires_at       : null,
            withdrawn_at     : null,
            created_by       : null,
            // [COMPAT][FID-1683] Espera code como string
            code             : null,
        }
    }

    relationships() {
        return {
            prize             : { class: Prize },
            consumption       : { class: require('@/models/Consumption').default },
            withdrawal_company: { class: Company },
            // [COMPAT][FID-1683] Espera code como string
            // code              : { class: VoucherCode },
        }
    }

    // Métodos úteis

    getCodeString() {
        // [COMPAT][FID-1683] Espera code como string
        return this.code
        // return this.code ? this.code.toString() : ''
    }

    /**
     * Envia uma requisição ao servidor para realizar a listagem de vouchers
     * de acordo com os argumentos (filtro, paginação, escopo etc.)
     *
     * @param {object} params           parâmetros a serem enviados como query string
     * @param {object} additionalConfig configurações adicionais no axios (headers etc.)
     * @returns {Collection}
     */
    static async list(params = {}, additionalConfig = {}) {
        let response = await this.request({
            url   : `/voucher`,
            method: 'get',
            params,
            ...additionalConfig,
        })

        if (!response)
            return

        return response.data.map(d => new this(d))
    }

    static async count(additionalParams = {}) {
        let response = await this.request({
            url   : `/voucher/count`,
            method: 'get',
            params: {
                ...additionalParams,
            },
        })

        if (!response)
            return

        return response.data
    }

}