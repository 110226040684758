var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "17.492",
        height: "23.702",
        viewBox: "0 0 17.492 23.702"
      }
    },
    [
      _c(
        "g",
        { staticClass: "a", attrs: { transform: "translate(0.252 0.25)" } },
        [
          _c("g", { attrs: { transform: "translate(0)" } }, [
            _c("path", {
              staticClass: "b",
              attrs: {
                d:
                  "M85.534,20.039,84.3,6.46a.727.727,0,0,0-.724-.66H81.4V4.35a4.35,4.35,0,0,0-8.7,0V5.8H70.525a.724.724,0,0,0-.722.66L68.569,20.039A2.9,2.9,0,0,0,71.457,23.2H82.645a2.9,2.9,0,0,0,2.889-3.163ZM74.15,4.35a2.9,2.9,0,1,1,5.8,0V5.8h-5.8Zm9.565,16.93a1.439,1.439,0,0,1-1.072.471H71.457a1.45,1.45,0,0,1-1.443-1.582L71.188,7.251H72.7V9.426a.725.725,0,0,0,1.45,0V7.251h5.8V9.426a.725.725,0,0,0,1.45,0V7.251h1.512l1.175,12.92A1.432,1.432,0,0,1,83.715,21.28Z",
                transform: "translate(-68.557)"
              }
            })
          ]),
          _c("g", { attrs: { transform: "translate(5.593 13.051)" } }, [
            _c("path", {
              staticClass: "b",
              attrs: {
                d:
                  "M197.063,290.175h-1.45v-1.45a.725.725,0,1,0-1.45,0v1.45h-1.45a.725.725,0,1,0,0,1.45h1.45v1.45a.725.725,0,0,0,1.45,0v-1.45h1.45a.725.725,0,1,0,0-1.45Z",
                transform: "translate(-191.988 -288)"
              }
            })
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }