import BaseModel from '@/models/BaseModel'
import { required, string, length } from '@/utils/validation'
import _ from 'lodash'
import moment from 'moment'

export default class IntegratorToken extends BaseModel {

    defaults() {
        return {
            name      : undefined,
            uid       : undefined,
            uid_type  : undefined,
            iat       : undefined,
            iby       : undefined,
            jti       : undefined,
            revoked_at: undefined,
            revoked_by: undefined,
            company_id: undefined,
            roles     : [],
        }
    }

    validation() {
        return {
            name: required.and(string).and(length(1, 191)),
        }
    }

    // Métodos

    isRevoked() {
        return !!this.revoked_at
    }

    // Getters & Setters

    get revoked_at_formatted() {
        return this.revoked_at ? moment(this.revoked_at).format('DD/MM/YY HH:mm') : null // [TODO] internacionalizar
    }

    // Métodos de API

    /**
     * Envia uma requisição para a API para revogar este token.
     *
     * @param {object} additionalConfig dados adicionais na requisição
     */
    async revoke(additionalConfig = {}) {
        let response = await this.request({
            url: `/integrator/self-company-token/${this.jti}/revoke`,
            method: 'post',
            ...additionalConfig,
        })

        if (!response)
            return

        return response
    }

    /**
     * Envia uma requisição para a API para reativar este token.
     *
     * @param {object} additionalConfig dados adicionais na requisição
     */
    async activate(additionalConfig = {}) {
        let response = await this.request({
            url: `/integrator/self-company-token/${this.jti}/activate`,
            method: 'post',
            ...additionalConfig,
        })

        if (!response)
            return

        return response
    }

    /**
     * Envia uma requisição para criar um novo token.
     *
     * @param {object} additionalConfig dados adicionais na requisição
     */
    static async create(additionalConfig = {}) {
        let response = await this.request({
            url: `/integrator/self-company-token`,
            method: 'post',
            ...additionalConfig,
        })

        if (!response)
            return

        return response
    }

    /**
     * Envia uma requisição para a API para listar os tokens cadastrados nesta empresa.
     *
     * @param {object} additionalConfig dados adicionais na requisição
     */
    static async list(additionalConfig = {}) {
        let response = await this.request({
            url: `/integrator/self-company-token`,
            method: 'get',
            ...additionalConfig,
        })

        if (!response)
            return

        return response.data ? response.data.map(d => new this(d)) : []
    }

}