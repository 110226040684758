import BaseModel from '@/models/BaseModel'
import { required, string, cnpj, length, positiveInteger } from '@/utils/validation'
import store from '@/store'
import axios from '@/services/axios'

export default class Company extends BaseModel {

    defaults() {
        return {
            id           : '',
            name         : '', 
            document     : '',
            levels       : '',
            parent_id    : '',
            json         : '',
            integrator_id: '',
            subsidiaries : [],
        }
    }

    validation() {
        return {
            name                  : required.and(string).and(length(1, 191)),
            document              : required.and(string).and(cnpj),
            // levels_interval       : required.and(positiveInteger(1, 3, 365)),
            // levels_lookback_period: required.and(positiveInteger(1, 4, 3650)),
        }
    }

    // Métodos da API

    static async listSubsidiaries(pagination) {
        let params = {}
        if (pagination) {
            params = {
                page    : pagination.page,
                per_page: pagination.per_page || pagination.perPage,
            }
        }

        let response = await axios({
            url: `/company/${store.getters['company/id']}/subsidiary`,
            method: 'get',
            params
        })

        if (!response)
            return

        return response
    }

    // async create() {
    //     this.integrator_id = store.getters['company/integrator_id']

    //     let data = this.getData([
    //         'name', 'document', 'json', 'parent_id'
    //     ])

    //     this.constructor.clearData(data, false)
        
    //     let response = await this.request({
    //         url: '/company',
    //         method: 'post',
    //         data,
    //     })

    //     if (!response)
    //         return

    //     // Atualiza a matriz na store
    //     await store.dispatch('company/fetchData')

    //     return response
    // }

    async update(fields = []) {
        let attributes = ['name', 'document', 'levels', 'json']

        if (fields.length)
            attributes = attributes.filter(field => fields.includes(field))

        let data = this.getData(attributes)

        this.constructor.clearData(data)

        if (data.levels)
            data.levels.update_lookback = Number(data.levels.update_lookback)

        let response = await this.request({
            url: `/company/${this.id}`,
            method: 'put',
            data
        })

        if (!response)
            return

        // Atualiza a matriz na store
        await store.dispatch('company/fetchData')

        return response
    }

    // async delete() {
    //     let data = this.getData()

    //     let url = data.parent_id ? `/company/${data.id}?parent_id=${data.parent_id}` : `/company/${data.id}` 
    //     let response = await this.request({
    //         url,
    //         method: 'delete'
    //     })

    //     if (!response)
    //         return

    //     // Atualiza a matriz na store
    //     await store.dispatch('company/fetchData')

    //     return response
    // }

    // Métodos úteis

    // Retira formatação antes de enviar para o servidor
    formatToBack() {
        // [TODO-L][FID-114] internacionalizar
        if (this.document)
            this.document = this.document.replace(/\D/g, '')
    }

    // Adiciona formatação necessária para exibição
    formatToFront() {
        // [TODO-L][FID-114] internacionalizar
        if (this.document)
            this.document = this.document.replace(/\D/g, '')
    }
}