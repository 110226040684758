import BasePolicy from './BasePolicy'

export default class CompanySettingsPolicy extends BasePolicy {
    beforeAll(verb, authorizable, onType, __, company_id) {
        
        if (authorizable && authorizable.hasRole(['admin']))
            return true
    }

    update(authorizable, onType) { return false }

    view(authorizable, onType) { return false }
}