export default {
    name: 'import',
    path: '/import',
    redirect: '/import/list',
    component: () => import(/* webpackChunkName: "import" */ '@/views/import/Base.vue'),
    children: [
        {
            name: 'import.list',
            path: 'list',
            component: () => import(/* webpackChunkName: "import" */ '@/views/import/List.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' }
                    },
                    {
                        text: 'Importações',
                        disabled: true
                    },
                ]
            }
        },
        {
            name: 'import.view',
            path: 'view/:id',
            component: () => import(/* webpackChunkName: "import" */ '@/views/import/ViewImportEntry.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Importações',
                        to: { name: 'import' },
                    },
                    {
                        text: 'Lista',
                        to: { name: 'import.list' },
                    },
                    {
                        text: 'Ver detalhes da importação',
                        disabled: true
                    },
                ]
            }
        },
        {
            name: 'import.customer',
            path: 'customer',
            component: () => import(/* webpackChunkName: "import.customer" */ '@/views/import/Customer.vue'),
            meta: {
                requiresAuthentication: true,
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: { name: 'home' },
                    },
                    {
                        text: 'Importações',
                        to: { name: 'import.list' },
                    },
                    {
                        text: 'Importar clientes',
                        disabled: true,
                    },
                ]
            }
        },
    ],
}