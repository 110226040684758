import axios from '@/services/axios'
import BaseModel from '@/models/BaseModel'
import Prize     from '@/models/Prize'
import { required, string, length, positiveInteger } from '@/utils/validation'
import _ from 'lodash'

export default class Level extends BaseModel {

    defaults() {
        return {
            id            : '',
            name          : '',
            trigger_amount: '',
            description   : '',
            tos_url       : '',
            color         : '',
            icon_url      : '',
            next          : {},
            prev          : {},
        }
    }

    validation() {
        return {
            name          : required.and(string).and(length(1, 191)),
            trigger_amount: required.and(positiveInteger(1, 9, 999999999)),
            description   : required.and(string).and(length(1, 191)),
            color         : string.and(length(1, 191)),
        }
    }

    relationships() {
        return {
            prize: { class: Prize, defaultValue: null },
        }
    }

    getProgress(currentPoints) {
        let    r   = currentPoints - this.trigger_amount
        let    rr  = this.next && this.next.amount ? this.next.amount - this.trigger_amount : -1
        return rr == -1 ? 100 : _.round(r / rr, 2) * 100
    }

    // Métodos da API

    static async list(pagination) {
        let params = {}
        if (pagination) {
            params = {
                page    : pagination.page,
                per_page: pagination.perPage,
            }
        }

        let response = await axios({
            url: '/level',
            method: 'get',
            params,
        })

        if (!response)
            return

        return response
    }

    static async createLevelsSet(levels) {
        /**
         * getData não considera valores iguais a 0, portanto o trigger amount é pego separadamente.
         * Preferiu-se não alterar o funcionmento de getData uma vez que esse comportamento específico pode estar sendo utilizado
         * em outra parte do código
         */
        let localLevels = []
        for (let level of levels) {
            localLevels.push({ 
                ...level.getData([
                    'name', 'description', 'tos_url', 'color', 'icon_url'
                ]),
                trigger_amount: Number(level.trigger_amount) || 0,
            })
        }

        if (!localLevels.length)
            return

        let data = { levels: localLevels }
        let response = await axios({
            url: '/level/batch-create',
            method: 'post',
            data,
        })

        return response
    }

    async update() {
        let data = this.getData([
            'name', 'description', 'tos_url', 'color', 'icon_url', 'prize'
        ])

        // No update, só precisa enviar o id
        if (data.prize)
            data.prize = _.pick(data.prize, 'id')

        let response = await this.request({
            url: `/level/${this.id}`,
            method: 'put',
            data,
        })

        if (!response)
            return

        // Atualiza este modelo com as informações que vieram do backend
        this._fill(response.data)

        return response
    }

    async get() {
        let data     = this.getData()
        let response = await this.request({
            url: `/level/${data.id}`,
            method: 'get'
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }
}