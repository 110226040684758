
import axios from '@/services/axios' 
import BaseModel from '@/models/BaseModel'
import { required, string, positiveNumber, positiveInteger, numeric, gte, lte } from '@/utils/validation'

export default class CalculationRule extends BaseModel {

    defaults() {
        return {
            id          : '',
            in_ratio    : 1,
            out_ratio   : 1,
            usage_limit : null,
            usage_period: null,
            expires_in  : 365,
            active      : false,

            type_id     : '',
            type_name   : '',

            useLimits   : false,

            allow_update: null,
            allow_delete: null,
        }
    }

    validation() {
        return {
            in_ratio    : required.and(numeric).and(gte(0)).and(lte(1000000)),
            out_ratio   : required.and(numeric).and(gte(0)).and(lte(1000000)),
            usage_limit : positiveNumber(0, 7, 1000000),
            usage_period: positiveInteger(0, 7, 1000000),
            expires_in  : required.and(positiveInteger(1, 4, 3650)),
            type_id     : required.and(string),
        }
    }

    // Métodos da API

    static async list(pagination) {
        let params = {}
        if (pagination)
            params = {
                page: pagination.page,
                per_page: pagination.perPage
            }

        let response = await axios({
            url: '/calculation-rule',
            method: 'get',
            params
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async create() {
        let data = this.getData([
            'type_id', 'type_key', 'in_ratio', 'out_ratio', 'usage_limit', 'usage_period', 'expires_in', 'company_id'
        ])
        data = this.toNumber(data, [
            'in_ratio', 'out_ratio', 'usage_limit', 'usage_period', 'expires_in'
        ])

        let response = await this.request({
            url: '/calculation-rule',
            method: 'post',
            data,
        })

        if (!response || !response.data || response.status != 201)
            return

        return response
    }

    async update() {
        let data = this.getData([
            'in_ratio', 'out_ratio', 'usage_limit', 'usage_period', 'expires_in', 'company_id'
        ])
        data = this.toNumber(data, [
            'in_ratio', 'out_ratio', 'usage_limit', 'usage_period', 'expires_in'
        ])

        let response = await this.request({
            url: `/calculation-rule/${this.id}`,
            method: 'put',
            data
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }

    async delete() {
        let data = this.getData()

        let response = await this.request({
            url: `/calculation-rule/${data.id}`,
            method: 'delete'
        })

        if (!response || response.status != 204)
            return

        return response
    }

    async get() {
        let data = this.getData()

        let response = await this.request({
            url: `/calculation-rule/${data.id}`,
            method: 'get'
        })

        if (!response || !response.data || response.status != 200)
            return

        return response
    }
}