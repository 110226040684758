<template>
    <v-dialog
        v-if="isModalOpen"
        v-model="isModalOpen"
        v-bind="attrs"
        v-on="listeners"
    >
        <v-card class="pa-2">
            <v-card-title v-if="title">
               <span class="primary--text font-weight-bold modal-title">{{ title }}</span>
            </v-card-title>

            <v-card-subtitle v-if="subtitle" class="pt-2">
                {{ subtitle }}
            </v-card-subtitle>

            <v-card-text class="py-3" :class="cardTextClass" v-if="hasSlot()">
                <slot></slot>
            </v-card-text>

            <slot name="actions">
                <div class="d-flex justify-end mx-6 mb-4">
                    <v-btn v-if="!computedCancelButton.hide" v-bind="computedCancelButton"  @click="handleCancel">
                        {{ computedCancelButton.label }}
                    </v-btn>

                    <v-btn v-if="!computedConfirmButton.hide" v-bind="computedConfirmButton" depressed @click="handleConfirm">
                        {{ computedConfirmButton.label }}
                    </v-btn>
                </div>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import HasSlot from '@/mixins/HasSlot'

export default {
    name: 'BaseModal',
    inheritAttrs: false,
    mixins: [ HasSlot ],
    props: {
        title        : String,
        subtitle     : String,
        cardTextClass: String,
        cancelButton : Object,
        confirmButton: Object,
        value        : Boolean,
    },
    data: vm => ({
        defaultCancelButton: {
            label: vm.$t('actions.cancel'),
            text : true,
            color: 'gray',
            hide : false,
            width: '86px',
            class: 'mr-1 background action-button',
        },
        defaultConfirmButton: {
            label: vm.$t('actions.confirm'),
            color: 'primary',
            hide : false,
            width: '86px',
            class: 'ml-1 action-button',
        },
    }),
    methods: {
        handleCancel() {
            this.$emit('cancel')
        },
        handleConfirm() {
            this.$emit('confirm')
        },
        close() {
            this.isModalOpen = false
        }
    },
    computed: {
        /**
         * Extrai todos os listeners deste componente, exceto os que estamos sobrescrevendo
         */
        listeners() {
            const { ...listeners } = this.$listeners
            return listeners
        },

        /**
         * Extrai todas as props deste componente, exceto as que estamos sobrescrevendo
         */
        attrs() {
            const { ...attrs } = this.$attrs
            return { ...attrs }
        },

        isModalOpen: {
            get() {
                return this.value
            },
            set(value) {
                // Há um BUG muito estranho que envia InputEvent para a modal
                if (value instanceof InputEvent)
                    return

                this.$emit('input', value)
            },
        },

        computedCancelButton() {
            return { ...this.defaultCancelButton, ...this.cancelButton }
        },

        computedConfirmButton() {
            return { ...this.defaultConfirmButton, ...this.confirmButton }
        },

        hasActions() {
            return !this.computedCancelButton.hide || !this.computedConfirmButton.hide
        },
    },
}
</script>

<style lang="scss">
.modal-title {
    font-size: 16px;
}

.action-button {
    width: 86px;
}

.v-dialog {
    border-radius: 8px !important;
}

.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0 !important;
}
</style>