import BaseIntegrationModel from '../BaseIntegrationModel'
import ConstantsService     from '../../constants'
import SelfCompanyTokenSettingsComponent from './SelfCompanyTokenSettingsComponent'

export default class SelfCompanyTokenIntegration extends BaseIntegrationModel {
    constructor() {
        super(ConstantsService.getConstant('INTEGRATIONS_KEY_SELF_COMPANY_TOKEN'))

        // Por padrão, esta integração está sempre ativa
        this.activate()
    }

    // Métodos

    /**
     * Retorna o componente de configuração desta integração.
     *
     * @return {component}
     */
    get settingsComponent() {
        return SelfCompanyTokenSettingsComponent
    }

    // Getters & Setters

}