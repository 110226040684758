var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "18.752",
        height: "19.765",
        viewBox: "0 0 18.752 19.765"
      }
    },
    [
      _c("path", {
        staticClass: "a",
        attrs: {
          d:
            "M14.2,12.967a5.416,5.416,0,0,1,1.651.281L9.179,3.24a.347.347,0,0,0-.289-.155h-3.9a.161.161,0,0,0-.134.251L11.693,13.6a5.425,5.425,0,0,1,2.507-.632Zm9.215-9.882h-3.9a.347.347,0,0,0-.289.155l-4.2,6.3,2.2,3.294,6.329-9.494a.162.162,0,0,0-.134-.251ZM14.2,14.065a4.392,4.392,0,1,0,4.392,4.392A4.392,4.392,0,0,0,14.2,14.065Zm2.319,4.521a.145.145,0,0,1-.024.228.189.189,0,0,0-.054.251.146.146,0,0,1-.071.219.188.188,0,0,0-.1.235.146.146,0,0,1-.114.2.188.188,0,0,0-.15.208.145.145,0,0,1-.154.17.188.188,0,0,0-.191.171.147.147,0,0,1-.186.135.189.189,0,0,0-.223.128.145.145,0,0,1-.21.093.187.187,0,0,0-.244.079.146.146,0,0,1-.225.047.186.186,0,0,0-.255.026.146.146,0,0,1-.229,0,.187.187,0,0,0-.255-.026.146.146,0,0,1-.225-.047.188.188,0,0,0-.244-.079.146.146,0,0,1-.21-.093.189.189,0,0,0-.223-.128.145.145,0,0,1-.186-.135.188.188,0,0,0-.19-.172.145.145,0,0,1-.154-.17.188.188,0,0,0-.15-.208.145.145,0,0,1-.114-.2.188.188,0,0,0-.1-.235.147.147,0,0,1-.071-.219.188.188,0,0,0-.054-.251.146.146,0,0,1-.024-.228.187.187,0,0,0,0-.257.145.145,0,0,1,.024-.228.189.189,0,0,0,.054-.251.146.146,0,0,1,.071-.219.188.188,0,0,0,.1-.235.146.146,0,0,1,.114-.2.188.188,0,0,0,.15-.208.145.145,0,0,1,.154-.17.188.188,0,0,0,.191-.171.147.147,0,0,1,.186-.135.189.189,0,0,0,.223-.128.145.145,0,0,1,.21-.093.187.187,0,0,0,.244-.079.146.146,0,0,1,.225-.047.186.186,0,0,0,.255-.026.146.146,0,0,1,.229,0,.187.187,0,0,0,.255.026.146.146,0,0,1,.225.047.188.188,0,0,0,.244.079.146.146,0,0,1,.21.093.189.189,0,0,0,.223.128.145.145,0,0,1,.186.135.187.187,0,0,0,.191.171.145.145,0,0,1,.154.17.188.188,0,0,0,.15.208.145.145,0,0,1,.114.2.188.188,0,0,0,.1.235.147.147,0,0,1,.071.219.188.188,0,0,0,.054.251.146.146,0,0,1,.024.228.19.19,0,0,0,0,.258Z",
          transform: "translate(-4.824 -3.085)"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }