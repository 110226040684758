import $store from '@/store'
import BasePolicy from './BasePolicy'

export default class AdminUserPolicy extends BasePolicy {
    beforeAll(verb, authorizable, onAdminUser, __, company_id) {
        this.is_parent_company_selected = $store.getters['company/is_parent_company_selected']

        if (this.is_parent_company_selected && authorizable && authorizable.hasRole('admin'))
            return true
    }

    create(authorizable) {
        return authorizable && authorizable.hasRole('admin')
    }

    edit(authorizable, onAdminUser) {
        return this._isSelfAdmin(...arguments) || this._isCompanyAdmin(...arguments)
    }

    view(authorizable, onAdminUser) {
        return true
    }

    list(authorizable) {
        return true
    }

    delete(authorizable, onAdminUser) {
        return this._isCompanyAdmin(...arguments)
    }

    _isSelfAdmin(authorizable, onAdminUser) {
        return authorizable && authorizable.id == onAdminUser.id
    }

    _isCompanyAdmin(authorizable, onAdminUser) {
        return true
    }
}