import axios from '@/services/axios'
import CustomerHistoryRecord from '@/models/CustomerHistoryRecord'
import Voucher from '@/models/Voucher'
import i18n from '@/i18n'
import $store from '@/store'
import CurrencyFilter from '@/utils/filters/currency'
import { roundNumber } from '@/utils/mathUtils'
import { required, string, numeric, array, isnil, cpf, cnpj, gt } from '@/utils/validation'

export default class Consumption extends CustomerHistoryRecord {

    defaults() {
        return {
            ...super.defaults(),
            customer_document: '',
            external_id      : '',
            source_key       : '',
            points           : '',
            products         : [],
            company          : null,
            reversal_id      : undefined,

            // Misc
            useCNPJ: false,
        }
    }

    validation() {
        return {
            id               : isnil.or(string),
            customer_id      : isnil.or(string),
            customer_document: required.and(string).and(this.useCNPJ ? cnpj : cpf),
            amount           : required.and(numeric).and(gt(0)),
            value            : required.and(numeric).and(gt(0)),
            external_id      : isnil.or(string),
            source_key       : isnil.or(string),
            products         : required.and(array)
        }
    }

    relationships() {
        return {
            ...super.relationships(),
            vouchers : { class: [Voucher],  defaultValue: [], fields: ['id', 'prize_name'] },
        }
    }

    _ignoredDataFields() {
        return ['useCNPJ']
    }

    // Utils

    isReversed() {
        return !!this.reversal_id
    }

    // Getters

    /**
     * Retorna a string identificadora de um ícone que representa o consumo
     *
     * @returns {string}
     */
    get icon() {
        return '$cp_gift'
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get title() {
        return i18n.t('models.Consumption.title', {
            points_amount  : this.points_amount,
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        })
    }

    /**
     * Retorna um título completo que indica quantidade e valor
     *
     * @returns {string}
     */
    get subtitle() {
        return this.reversal_id ? i18n.t('models.Consumption.subtitle.reversal', {
            points_amount  : this.points_amount,
            value          : CurrencyFilter(this.value),
            points_value   : CurrencyFilter(this.points_value),
            subsidiary_name: this.subsidiary_name,
        }) : ''
    }

    /**
     * Retorna a ação realizada pelo modelo
     *
     * @returns {string}
     */
    get action() {
        return i18n.t('models.Consumption.action')
    }

    // get totalAmount() {
    //     return this.points.reduce((ac, cv) => { ac + cv.amount })
    // }

    get subsidiary_name() {
        return this.company ? this.company.name : ''
    }

    get subsidiary_document() {
        return this.company ? this.company.document : ''
    }

    get formated_subsidiary_document() {
        return this.company && this.company.document ? this.company.document.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') : ''
    }

    get points_value() {
        return this.value
    }

    get points_amount() {
        return this.points ? this.points.reduce((carry, current) => Number(roundNumber(carry + current.amount)), 0) : 0
    }

    // Métodos da API

    static async getIntentionForCustomer(customer_document, customer_id) {
        return await axios({
            url: '/points/intention',
            method: 'get',
            params: {
                customer_id,
            },
            headers: {
                'customer-document': customer_document,
            },
        })
    }

    async consumption() {
        let data = this.getData([
            'value', 'source_key', 'external_id', 'customer_document', 'customer_id', 'company_id', 'products', 'parent_id' 
        ])
        let res  = null

        if (data && data.products) {
            for (let product of data.products) {
                delete product.amount
                delete product.calculation_rule
            }
        }

        // adiciona informações da company
        data.company_id = $store.getters['company/selected_company_id']
        data.parent_id  = $store.getters['company/id']

        // O consumo completo é feito em 4 etapas

        // 1. Deleta a intenção existente (se houver)
        res = await axios({
            url: '/points/intention',
            method: 'delete',
            params: {
                customer_id      : data.customer_id,
            },
            headers: {
                'customer-document': data.customer_document,
            },
        })

        if (!res)
            return

        // 2. Cria intenção
        res = await axios({
            url: '/points/intention',
            method: 'post',
            data,
        })

        if (!res)
            return

        // 3. Pega intenção pelo customer
        res = await axios({
            url: '/points/intention',
            method: 'get',
            params: {
                customer_id      : data.customer_id,
            },
            headers: {
                'customer-document': data.customer_document,
            },
        })

        if (!res)
            return

        // 4. Cria consumo utilizando o código do customer
        res = await axios({
            url: '/points/consumption',
            method: 'post',
            data: {
                intention_id     : res.data.id,
                code             : res.data.code,
                customer_id      : data.customer_id,
                customer_document: data.customer_document,
            },
        })

        if (!res)
            return

        return true
    }

}