var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _vm.isAppLoaded || _vm.inMaintenance
        ? [
            !_vm.inMaintenance && _vm.exist && _vm.isAvailable
              ? [
                  _vm.isAuthenticated
                    ? _c("Drawer", {
                        model: {
                          value: _vm.isDrawerOpen,
                          callback: function($$v) {
                            _vm.isDrawerOpen = $$v
                          },
                          expression: "isDrawerOpen"
                        }
                      })
                    : _vm._e(),
                  _vm.shouldShowNavbar
                    ? _c("Navbar", { on: { toggleDrawer: _vm.toggleDrawer } })
                    : _vm._e(),
                  _c(
                    "v-content",
                    { staticClass: "content" },
                    [
                      _vm.breadcrumbs && _vm.breadcrumbs.length
                        ? _c("v-breadcrumbs", {
                            staticClass: "mt-4 ml-2 ml-md-9 pb-0",
                            class: _vm.isLightTheme ? "breadcrumb-item" : "",
                            attrs: { divider: ">", items: _vm.breadcrumbs }
                          })
                        : _vm._e(),
                      _c("router-view")
                    ],
                    1
                  )
                ]
              : [
                  !_vm.inMaintenance && !_vm.exist
                    ? _c(
                        "v-row",
                        { staticStyle: { "background-color": "#FFFFFF" } },
                        [
                          _c("img", {
                            attrs: {
                              height: "100%",
                              width: "100%",
                              src: require("@/assets/errors/404.png")
                            }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm.inMaintenance || !_vm.isAvailable
                    ? _c(
                        "v-row",
                        { staticStyle: { "background-color": "#FFFFFF" } },
                        [
                          _c("img", {
                            attrs: {
                              height: "100%",
                              width: "100%",
                              src: require("@/assets/errors/maintenance.png")
                            }
                          })
                        ]
                      )
                    : _vm._e()
                ]
          ]
        : [
            _c("div", { staticClass: "text-center mt-5" }, [
              _vm._v(" " + _vm._s(_vm.$t("globals.loading")) + " ")
            ])
          ],
      _c("NotificationFooter"),
      _c("AppSnackbar", { ref: "snackbar" }),
      _c("AlertModal", { ref: "alert" }),
      _c("ConfirmationModal", { ref: "confirmation" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }