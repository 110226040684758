import $console from '@/services/console'
import $store from '@/store'
import $router from '@/router'
import _ from 'lodash'

export default new class BugsnagService {

    constructor() {
        /**
         * Variável interna que indica se o serviço do bugsnag
         * foi configurado para o ambiente atual.
         * Obs. Não deve ser alterada ou acessada diretamente.
         *
         * @type {boolean}
         * @private
         */
        this._is_enabled = false
    }

    setup(Vue) {
        if (!process.env.VUE_APP_BUGSNAG_API_KEY) {
            $console.warn('Bugsnag is not enabled.')
            return
        }

        // Só importa os módulos se está habilitado
        this.Bugsnag = require('@bugsnag/js')

        const BugsnagPluginVue = require('@bugsnag/plugin-vue')

        this.Bugsnag.start({
            apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
            plugins: [new BugsnagPluginVue()],
            onError: this.onError,
            appVersion: process.env.VUE_APP_VERSION,
            releaseStage: process.env.NODE_ENV,
        })

        const bugsnagVue = this.Bugsnag.getPlugin('vue')
        bugsnagVue.installVueErrorHandler(Vue)

        this._is_enabled = true
    }

    isEnabled() {
        return this._is_enabled
    }

    getInstance() {
        if (!this.isEnabled())
            $console.error('Bugsnag is not enabled')

        return this.Bugsnag
    }

    /**
     * Executado antes de enviar um erro ao Bugsnag
     *
     * @param {Error} event Evento de erro
     */
    onError(event) {
        if ($store.getters['company/id']) {
            event.addMetadata('company', {
                name: $store.getters['company/name'],
                id  : $store.getters['company/id'],
            })
        }

        // Ignora erro do tipo NavigationDuplicated
        if (_.get(event, 'originalError.name') == 'NavigationDuplicated') {
            return false
        }

        let user = $store.getters['auth/user']
        if (user)
            event.setUser(user.id, user.email, user.name)

        // se precisar mexer na prioridade
        // event.severity = 'info'

        // Informações adicionais de contexto
        event.context = `${$router.history.current.path} (${$router.history.current.name})`

        // se não precisa enviar para o bugsnag
        // return false
    }
}