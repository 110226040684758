<template>
    <v-app>
        <template v-if="isAppLoaded || inMaintenance">
            <template v-if="!inMaintenance && exist && isAvailable">
                <Drawer v-if="isAuthenticated" v-model="isDrawerOpen"/>
                <Navbar v-if="shouldShowNavbar" @toggleDrawer="toggleDrawer"/>

                <v-content class="content">
                    <v-breadcrumbs :class="isLightTheme ? 'breadcrumb-item': ''" v-if="breadcrumbs && breadcrumbs.length" class="mt-4 ml-2 ml-md-9 pb-0" divider=">" :items="breadcrumbs" />
                    <router-view/>
                </v-content>

            </template>
            <template v-else>
                <v-row v-if="!inMaintenance && !exist" style="background-color: #FFFFFF">
                    <img height="100%" width="100%" :src="require('@/assets/errors/404.png')" />
                </v-row>

                <v-row v-if="inMaintenance || !isAvailable" style="background-color: #FFFFFF">
                    <img height="100%" width="100%" :src="require('@/assets/errors/maintenance.png')" />
                </v-row>    
            </template>
        </template>
        <template v-else>
            <div class="text-center mt-5">
                {{ $t('globals.loading') }}
            </div>
        </template>

        <!-- Agrega todas as notificações importantes -->
        <NotificationFooter/>

        <!-- Modais -->
        <AppSnackbar       ref="snackbar"/>
        <AlertModal        ref="alert"/>
        <ConfirmationModal ref="confirmation"/>
    </v-app>
</template>

<script>
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'
import RateColor            from '@/shared/mixins/RateColor'
import InitServices         from '@/services/setup'
import Navbar               from '@/components/structure/Navbar'
import Drawer               from '@/components/structure/Drawer'
import AppSnackbar          from '@/components/structure/AppSnackbar'
import NotificationFooter   from '@/components/structure/NotificationFooter'
import AlertModal           from '@/components/modals/AlertModal'
import ConfirmationModal    from '@/components/modals/ConfirmationModal'
import { mapGetters }       from 'vuex'

export default {
    name: 'App',
    mixins: [ HasErrorHandlerMixin, RateColor ],
    components: {
        Navbar, Drawer, AppSnackbar, AlertModal, ConfirmationModal, NotificationFooter,
    },
    data: vm => ({
        inMaintenance         : process.env.VUE_APP_MAINTENANCE != '0',
        isDrawerOpen          : false,
        exist                 : true,
        isAvailable           : true,
        breadcrumbs           : [],
    }),
    async beforeCreate() {
        this.$store.dispatch('app/setIsLoaded', false)

        // Executa em sequência e para no primeiro erro, se houver
        try {
            await this.$store.dispatch('company/fetchData')
            await this.$store.dispatch('company/fetchSettings')
        } catch (e) {
            this.errorHandler(e)
        }

        if (!this.$store.getters['company/isLoaded']) {
            this.exist = false
            this.$store.dispatch('app/setIsLoaded', true)
            return
        }

        if (this.$store.getters['company/blocked_at']) {
            this.isAvailable = false
            this.$store.dispatch('app/setIsLoaded', true)
            return
        }

        if (this.$store.getters['auth/check'])
            await this.$store.dispatch('auth/fetchCurrentAdminUser')
                // Obs. Não pode ser catch(this.errorHandler) pois não funciona no beforeCreate
                .catch(e => {
                    this.errorHandler(e)
                })

        await InitServices.apply(this)
            // Obs. Não pode ser catch(this.errorHandler) pois não funciona no beforeCreate
            .catch(e => {
                this.errorHandler(e)
            })

        this.$store.dispatch('app/setIsLoaded', true)
    },
    created() {            
        // Inicializa os eventos do bus global
        let self = this

        /**
         * Mostra uma mensagem não bloqueante (snackbar)
         *
         * @param {string} message mensagem a ser mostrada para o usuário
         * @param {string} color   cor da mensagem
         */
        this.$bus.$on('message', (message, color) => {
            self.$refs.snackbar && self.$refs.snackbar.new(message, color)
        })

        /**
         * Mostra um alerta que espera uma interação com o usuário (bloqueante)
         *
         * @param {string}     message  mensagem a ser mostrada para o usuário
         * @param {(string)}   type     success para alerta de sucesso e error para alerta de erro
         * @param {(function)} callback função a ser executada após a interação com o usuário
         * @param {(boolean)}  usehtml  indica se deve permitir a renderização de HTML na mensagem do alerta (cuidado!)
         */
        this.$bus.$on('alert', async (message, type = 'success', callback = e => null, useHtml = false) => {
            let functionMapping = {
                success: self.$refs.alert.showSuccess,
                error  : self.$refs.alert.showError,
            }
            await functionMapping[type](message, useHtml)
                .catch(callback)
            if (typeof callback == 'function')
                callback()
        })

        /**
         * Mostra um alerta de confirmação que espera uma interação com o usuário (bloqueante)
         * e retorna um valor para confirmado ou não
         *
         * @param {string}     message  mensagem a ser mostrada para o usuário
         * @param {(function)} callback função a ser executada após a interação com o usuário
         * @param {(boolean)}  usehtml  indica se deve permitir a renderização de HTML na mensagem do alerta (cuidado!)
         */
        this.$bus.$on('confirm', async (message, callback = e => null, useHtml = false) => {
            let allowed = await self.$refs.confirmation.showConfirmation(message, useHtml)
            if (typeof callback == 'function')
                callback(allowed)
        })
    },
    beforeDestroy() {
        // Para de escutar todos os eventos
        this.$bus.$off()
    },
    methods: {
        toggleDrawer() {
            this.isDrawerOpen = !this.isDrawerOpen
        },
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/check',
            isAppLoaded    : 'app/isLoaded',
        }),
        shouldShowNavbar() {
            return !this.$route.meta.noNavbar
        },
    },
    watch: {
        // Toda vez que troca de rota fecha a drawer e atualiza os breadcrumbs
        '$route.name'() {
            this.isDrawerOpen = false
            this.breadcrumbs = this.$route.meta.breadcrumbs
        },
    },
}
</script>

<style lang="scss">
.content {
    background: $background;
}
</style>
