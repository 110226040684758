import BaseModel from './BaseModel'
import $store from '@/store'
import moment from 'moment'
import _ from 'lodash'
import { required, boolean, string, integer, gte, lt, length } from '../utils/validation'

export default class CompanySettings extends BaseModel {

    // Constantes da classe para uso em validações
    static AGE_RESTRICTION_FROM = 'FROM'
    static AGE_RESTRICTION_TO   = 'TO'

    defaults() {
        return {
            is_age_restriction_enabled: false,
            allow_from_age            : null,
            allow_to_age              : null,
            is_age_gate_enabled       : false,
            age_gate_url              : undefined,
            levels_system             : undefined,

            analytics: {
                gtm_id: undefined,
            },
            customFields  : null,
            integrations  : {
                subscription: {
                    frontend_url:  null,
                }
            }
        }
    }

    validation() {
        return {
            is_age_restriction_enabled: required.and(boolean),
            allow_from_age            : this.is_age_restriction_enabled && (!_.isNil(this.allow_from_age) || _.isNil(this.allow_to_age))   ? integer.and(gte(0)).and(lt(121)) : [],
            allow_to_age              : this.is_age_restriction_enabled && (!_.isNil(this.allow_to_age)   || _.isNil(this.allow_from_age)) ? integer.and(gte(0)).and(lt(121)) : [],
            is_age_gate_enabled       : required.and(boolean),
            age_gate_url              : this.is_age_gate_enabled ? required.and(string).and(length(1, 191)) : [],
            levels_system             : required.and(string)
        }
    }

    static async get() {
        let company_id = $store.getters['company/id']
        let res = await this.request({
            url: `/company/${company_id}/settings`,
            method: 'get',
        })

        if (!res)
            return

        return new this(res.data)
    }

    /**
     * Aplica as regras de restrição de idade à data passada como parâmetro
     *
     * @param   {*} date qualquer formato de data que o moment entenda (não nulo)
     * @returns {(boolean|string)} true, se for adequado à regra, ou constante indicando a inadequação
     */
    applyAgeRestrictionRuleTo(date) {
        if (!this.is_age_restriction_enabled)
            return true

        if (!date)
            throw new TypeError('Expected defined date argument')

        let momentDate = moment(date)
        if (!momentDate.isValid())
            throw new TypeError('Moment date argument is invalid')

        let age = moment().diff(momentDate, 'years')
        if (!_.isNil(this.allow_from_age) && age < this.allow_from_age)
            return this.constructor.AGE_RESTRICTION_FROM
        if (!_.isNil(this.allow_to_age) && age > this.allow_to_age)
            return this.constructor.AGE_RESTRICTION_TO
        return true
    }
}