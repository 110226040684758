var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseInput", {
    attrs: { label: _vm.attrs.label, disabled: _vm.attrs.disabled },
    scopedSlots: _vm._u(
      [
        {
          key: "input",
          fn: function() {
            return [
              _c(
                "v-text-field",
                _vm._g(
                  _vm._b(
                    {
                      ref: "input",
                      attrs: {
                        solo: "",
                        flat: "",
                        value: _vm.computedValue,
                        "background-color": "input_background_color"
                      },
                      on: {
                        input: _vm.onInput,
                        wheel: function($event) {
                          $event.preventDefault()
                          return _vm.onWheel($event)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm.hasSlot("append")
                            ? {
                                key: "append",
                                fn: function() {
                                  return [_vm._t("append")]
                                },
                                proxy: true
                              }
                            : null,
                          _vm.hasSlot("prepend")
                            ? {
                                key: "prepend",
                                fn: function() {
                                  return [_vm._t("prepend")]
                                },
                                proxy: true
                              }
                            : null,
                          _vm.hasSlot("label")
                            ? {
                                key: "label",
                                fn: function() {
                                  return [_vm._t("label")]
                                },
                                proxy: true
                              }
                            : null,
                          _vm.hasSlot("message")
                            ? {
                                key: "message",
                                fn: function() {
                                  return [_vm._t("message")]
                                },
                                proxy: true
                              }
                            : null
                        ],
                        null,
                        true
                      )
                    },
                    "v-text-field",
                    _vm.attrs,
                    false
                  ),
                  _vm.listeners
                ),
                [_vm._t("default")],
                2
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }