export default {
    program_name: 'CPlug Fidelidade',

    // page_separator: 'CPlug Fidelidade – Painel Administrador',
    // page_title: 'CPlug Fidelidade – Painel Administrador',
    // page_description: 'Painel para administração das configurações do programa de fidelidade da ConnectPlug',

    generic: {
        title: '{program_name} – Painel de administração',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    loading: {
        title: 'Carregando...',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    generate: {
        title: '{program_name} – Gerar pontos para um cliente',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    consumption: {
        title: '{program_name} – Gerar um consumo para um cliente',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    welcome: {
        title: '{program_name} – Seja bem-vindo',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    select_company: {
        title: '{program_name} – Selecione a sua empresa',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    customers: {
        details: {
            title: '{program_name} – Detalhes do cliente',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de clientes',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar um novo cliente',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar um cliente',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        history: {
            title: '{program_name} – Histórico do cliente',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        register: {
            title: '{program_name} – Registro',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        find: {
            title: '{program_name} – Buscar',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    calculationRules: {
        details: {
            title: '{program_name} – Detalhes da regra de cálculo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de regras de cálculo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar uma nova regra de cálculo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar uma regra de cálculo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    types: {
        details: {
            title: '{program_name} – Detalhes do tipo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de tipos',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar um novo tipo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar um tipo',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    levels: {
        details: {
            title: '{program_name} – Detalhes do nível',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar um nível',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de níveis',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar um novo nível',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },

        benefits: {
            title: '{program_name} – Detalhes do nível | Benefícios',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        prize: {
            title: '{program_name} – Detalhes do nível | Prêmio',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    prizes: {
        details: {
            title: '{program_name} – Detalhes do prêmio',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar um prêmio',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de prêmios',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar um novo prêmio',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    prizeCategory: {
        edit: {
            title: '{program_name} – Editar uma categoria',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de categorias',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar uma nova categoria',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    vouchers: {
        withdraw: {
            title: '{program_name} – Retirar voucher',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
    settings: {
        title: '{program_name} – Configurações',
        meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
    },
    promotions: {
        details: {
            title: '{program_name} – Detalhes da promoção',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        edit: {
            title: '{program_name} – Editar uma promoção',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        list: {
            title: '{program_name} – Lista de promoções',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
        create: {
            title: '{program_name} – Cadastrar uma nova promoção',
            meta: 'Seja bem vindo ao painel de administração do sistema CPlug Fidelidade.',
        },
    },
}