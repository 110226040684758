export default [
    {
        path: '/empresas/matriz',
        name: 'companies.parent',
        redirect: '/empresas/matriz/personalizar',
    },{
        path: '/empresas/matriz/personalizar',
        name: 'companies.parent.personalize',
        component: () => import(/* webpackChunkName: "companies.parent.personalize" */ '@/views/companies/parent/Personalize.vue'),
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            can: 'edit Company',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' },
                },
                {
                    text: 'Matriz',
                    disabled: true,
                },
            ],
        },
    },{
        path: '/empresas/filiais',
        name: 'companies.subsidiaries',
        redirect: 'empresas/filiais/lista',
    },{
        path: '/empresas/filiais/lista',
        name: 'companies.subsidiaries.list',
        component: () => import(/* webpackChunkName: "companies.subsidiaries.list" */ '@/views/companies/subsidiaries/List.vue'),
        meta: {
            requiresAuthentication: true,
            requiresSelectedCompany: true,
            can: 'list Subsidiary',
            fail: '/painel',
            breadcrumbs: [
                {
                    text: 'Dashboard',
                    to: { name: 'home' },
                },
                {
                    text: 'Filiais',
                    disabled: true,
                },
            ],
        },
    },
]