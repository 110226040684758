export default [
    {
        path: '/loading',
        name: 'loading',
        component: () => import(/* webpackChunkName: "loading" */ '@/views/Loading.vue'),
        props: true,
        meta: {
            title: 'loading',
        },
    },
]