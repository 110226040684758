<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20.45" height="22.5" viewBox="0 0 20.45 22.5"><g class="a" transform="translate(-3.5 -2)"><path class="b" d="M22.95,6.075c0,1.7-4.13,3.075-9.225,3.075S4.5,7.773,4.5,6.075,8.63,3,13.725,3,22.95,4.377,22.95,6.075Z" transform="translate(0)"/><path class="b" d="M22.95,18c0,1.7-4.1,3.075-9.225,3.075S4.5,19.7,4.5,18" transform="translate(0 -4.75)"/><path class="b" d="M4.5,7.5V21.85c0,1.7,4.1,3.075,9.225,3.075s9.225-1.373,9.225-3.075V7.5" transform="translate(0 -1.425)"/></g></svg>
</template>

<script>
export default {
    name: 'DatabaseIcon',
}
</script>

<style scoped lang="scss">
.b {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}
</style>